import AdminLayout from '../Layout/Admin/AdminLayout';
import PhotographerList from '../Screen/Admin/photographer/potographerList';
import CreatePackage from '../Screen/Admin/createPackage';
import CreatePackageUser from '../Screen/Admin/createPackageUesr';
import PhotographerDetailes from '../Screen/Admin/photographer/photographerDetailes';
import AllAlbums from '../Screen/Admin/photographer/allAlbums';
import UserList from '../Screen/Admin/User/userList';
import DailyReport from '../Screen/Admin/dailyReport';
import UserDetailes from '../Screen/Admin/User/userDetailes';


const AdminRoute = [{
    path: '/',
    element: (
       <AdminLayout/>
    ),
    children: [
      { path: '/', element:<DailyReport/>},
      { path: '/dailyReport', element:<DailyReport/>  },
      
     {
      path:'/photographerList', element:<PhotographerList/>
     },
     { path: '/createPackage', element:<CreatePackage/> },
     { path: '/createPackageUser', element:<CreatePackageUser/>},
     { path: '/photographerDetailes',element: <PhotographerDetailes/>  },
     { path: '/allAlbums', element:<AllAlbums/> },
     { path: '/userList', element:<UserList/>  },
     { path: '/userDetailes', element:<UserDetailes/>}


    ],
  }
];
  
export default AdminRoute;

// export { PrivateRoute };




// import React, { useMemo, useState } from 'react';
// import { Navigate, Outlet } from 'react-router-dom';



// import AdminLayout from '../Layout/Admin/AdminLayout';
// import PhotographerList from '../Screen/Admin/photographer/potographerList';
// import CreatePackage from '../Screen/Admin/createPackage';
// import CreatePackageUser from '../Screen/Admin/createPackageUesr';
// import PhotographerDetailes from '../Screen/Admin/photographer/photographerDetailes';
// import AllAlbums from '../Screen/Admin/photographer/allAlbums';
// import UserList from '../Screen/Admin/User/userList';
// import DailyReport from '../Screen/Admin/dailyReport';
// import UserDetailes from '../Screen/Admin/User/userDetailes';


// import UploadImages from "../Screen/uploadImages";
// import Home from "../Screen/home";
// import PublicGroup from '../Screen/Groups/PublicGroup';
// import SubscriptionPay from '../Screen/payment';
// import PhotoGrapherLayout from '../Layout/photographer/PhotographerLayout';
// import JoinedAlbum from '../Screen/Groups/joinedAlbum';
// import ViewImages from '../Screen/viewImages';
// import Profile from '../Screen/Header/Profile/profile';
// import DeleteImages from '../Screen/deleteImage';
// import UsersMultipers from '../Screen/Header/Profile/UsersMultipers';


// const AdminRoute = [{
//     path: '/',
//     element: (
//        <AdminLayout/>
//     ),
//     children: [
//       { path: '/', element:<DailyReport/>},
//       { path: '/dailyReport', element:<DailyReport/>,index:true },
//      {
//       path:'/photographerList', element:<PhotographerList/>
//      },
//      { path: '/createPackage', element:<CreatePackage/> },
//      { path: '/createPackageUser', element:<CreatePackageUser/>},
//      { path: '/photographerDetailes',element: <PhotographerDetailes/>  },
//      { path: '/allAlbums', element:<AllAlbums/> },
//      { path: '/userList', element:<UserList/>  },
//      { path: '/userDetailes', element:<UserDetailes/>},
//      { path: '/home',  element:<Home />},
//      { path: '/upload', element: <UploadImages />},
//      { path:'/joinedAlbum', element:<JoinedAlbum/>},
//      { path: '/publicGroup', element:<PublicGroup/> },
//      { path: '/subscription', element:<SubscriptionPay/> },
//      { path:'/ViewImages',  element:<ViewImages/>},
//      { path:'/DeleteImages',  element:<DeleteImages/>},
//      { path: '/subscription', element:<SubscriptionPay/> },
//      { path: '/profile', element:<Profile/> },
//      { path: '/Adduser', element:<UsersMultipers/>  }
//     ],
//   }
// ];
  
// export default AdminRoute;