import React, { useState } from "react";
import {
  Typography,
  Select,
  MenuItem,
  Stack,
  TextField,
  Button,
  FormControlLabel,
  Grid,
  Card,
  Box,
  IconButton,
  Switch,
  Alert,
  styled,
  useMediaQuery,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom"; // Import useNavigate and useLocation

import { CreateEvent } from "../../API/Photographer";
import {  UploadFileRounded } from "@mui/icons-material";
import deleteicon from "../../images/edit-icon.svg";
import { useTheme } from "@mui/material/styles";

const EventForm = () => {
  const [venue, setVenue] = useState("");
  const [albumName, setAlbumName] = useState("");
  const [slot, setSlot] = useState("Night");
  const [cityName, setCityName] = useState("Wedding Event");
  const [eventDate, setEventDate] = useState("");
  const [isBanner, setIsBanner] = useState(false);
  const [bannerTitle, setBannerTitle] = useState("");
  const [bannerImage, setBannerImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [status, setStatus] = useState("");
  const email = localStorage.getItem("email");

  const [file, setFile] = useState(null);

  const handleImageChange = (event) => {
    const files = event.target.files[0];
    setBannerImage(files);
    setErrorMessage("");
    // if (files) {
    //     setBannerImage(URL.createObjectURL(file));
    // }
  };
  const handleFileRemove = () => {
    setFile(null);
    // This should clear the file and remove the image
  };
  const handleBannerRemove = () => {
    // setFile(null);
    setBannerImage(null); // This should clear the file and remove the image
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    // if (selectedFile) {
    //     setFile(URL.createObjectURL(selectedFile));
    // }
  };



  const navigate = useNavigate(); // Initialize navigate
  const location = useLocation(); // Initialize location

  const eventType = location.state?.eventType;
  console.log(eventType, "eventType"); // Add this to debug


  const today = new Date().toISOString().split("T")[0];

  const handlebackbutton = () => {
    navigate(-1);
  };

  const MessageBoxRight = styled(Box)(({ theme }) => ({
    display: isSmallScreen ? "none" : is768px ? "none" : "",
    position: "sticky",
    bottom: theme.spacing(35),
    left: theme.spacing(40),
    marginLeft: "5%",
    backgroundColor: "#E3F2FD",
    maxWidth: "30%",
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
    textAlign: "left",
  }));
  const MessageBoxBottom = styled(Box)(({ theme }) => ({
    display: isSmallScreen ? "" : is768px ? "" : "none",
    // position: "sticky",
    bottom: theme.spacing(0),
    left: theme.spacing(0),
    marginBottom: "5%",
    backgroundColor: "#E3F2FD",
    // minWidth: "50%",
    width: "60%",
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
    textAlign: "left",
  }));

  const handleEventSubmit = async () => {
    
    setErrorMessage("");

    // Validation checks
    if (!file) {
      setErrorMessage("Album Image is required");
      return;
    }
    if (albumName.trim() === "") {
      setErrorMessage("Album Name is required");
      return;
    }

    if (venue.trim() === "") {
      setErrorMessage("Event Place is required");
      return;
    }

    if (eventDate === "") {
      setErrorMessage("Event Date is required");
      return;
    }

    if (eventType === "Public" && isBanner) {
      if (!bannerImage) {
        setErrorMessage(
          "Banner Image is required when the banner option is selected"
        )
        return;
      }
      if (bannerTitle.trim() === "") {
        setErrorMessage("Banner TagLine is required when ");
        return;
      }
    }

    // Prepare form data for API submission
    const formData = new FormData();
    const userData = {
      name: venue,
      photographerEmail: email,
      imageUrl: file,
      eventType: eventType,
      albumName: albumName,
      eventDate: eventDate,
      slot: slot,
      bannerImage: bannerImage,
      tagLine: bannerTitle,
      venueAlias: cityName,
    };

    if (bannerImage) {
      formData.append("bannerImage", bannerImage);
    }
    formData.append("file", file);
    formData.append("data", JSON.stringify(userData));
    try {
      const response = await CreateEvent(formData); // Ensure this matches your API call structure

      // Handle response and status
      // setStatus(response?.status || 200);
      // setErrorMessage(response?.data?.Message || "Album created Successfully");
      if (response?.status === 200) {
        setStatus(200); // Set status to 200 for success
        setErrorMessage("Album created successfully");

        // Reset form fields
        setAlbumName("");
        setVenue("");
        setCityName("Select");
        setSlot("");
        setEventDate("");
        setBannerTitle("");
        setBannerImage(null);
        setIsBanner(false);
        setErrorMessage("");

 setTimeout(() => {
          navigate("/home");
        }, 1500); // Wait for 1.5 seconds to show the message
      } else {
        // Handle non-success responses
        setStatus(response?.status || 500);
        setErrorMessage(response?.data?.Message || "Failed to create album");
      }
    } catch (err) {
      console.error(err);
      setErrorMessage("An error occurred while creating the event.");
    }
  };

  // const locations = [
  //   { display: "Select", value: "Select" },
  //   { display: "Bengaluru, India", value: "Bengaluru, India" },
  //   //... other locations
  // ];

  console.log(eventType, "eventTypeeventTypePrivated");

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const is768px = useMediaQuery("(max-width:910px)");
  return (
    <Grid
      container
      direction={isSmallScreen ? "column" : "row"}
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <Grid
        marginTop={"100px"}
        marginBottom={is768px ? "10px" : "50px"}
        marginLeft={is768px ? "20px" : "50px"}
        marginRight={is768px ? "20px" : ""}
        direction={"row"}
        item
        xs={11}
        sm={8}
        md={6}
        lg={4}
      >
        {eventType === "Private" ? (
          <Typography
            style={
              {
                // backgroundColor: "#E3F2FD"
              }
            }
            variant="h4"
            fontWeight={300}
            textAlign={"center"}
            textTransform={"capitalize"}
          >
            Create your <span style={{ color: "#297EFF" }}>Private </span>album
          </Typography>
        ) : (
          <Typography
            style={
              {
                // backgroundColor: "#E3F2FD"
              }
            }
            variant="h4"
            fontWeight={300}
            textAlign={"center"}
            textTransform={"capitalize"}
          >
            Create your <span style={{ color: "#297EFF" }}>Public </span>album
          </Typography>
        )}

        <Card
          // elevation={0}
          style={{
            // backgroundColor: "#E3F2FD",
            border: "1px solid #DBDBDE",
            padding: "20px",
            marginTop: "10px",
            position: "relative",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {!file && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              border="2px dashed grey"
              borderRadius="10px"
              minWidth={isSmallScreen ? "50%" : "75%"}
              height={"20px"}
              sx={{
                mx: "auto",
                mb: 2,
                backgroundColor: "#ECF8FB",
                p: 6,
                mt: 1,
              }}
            >
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="file-upload-albumImage"
                type="file"
                name="uploadImage"
                onChange={handleFileChange}
              />
              <label htmlFor="file-upload-albumImage">
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ cursor: "pointer", textAlign: "center" }}
                >
                  <UploadFileRounded sx={{ fontSize: 48, mb: 2 }} />
                  <Typography variant="body1">Choose Album Image </Typography>
                </Box>
              </label>
            </Box>
          )}

          {file && (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              sx={{ position: "relative", width: "80%", mx: "auto" }}
            >
              <img
                src={URL.createObjectURL(file)}
                alt="Uploaded File"
                style={{
                  maxWidth: "100%",
                  maxHeight: "200px",
                  borderRadius: "10px",
                  marginBottom: "16px",
                }}
              />
              <IconButton onClick={handleFileRemove} sx={{}}>
                <img
                  src={deleteicon}
                  alt="Delete"
                  style={{ position: "relative", top: "-90px", left: "10px" }}
                />
              </IconButton>
            </Box>
          )}
          {errorMessage && (
            <Stack
              style={{
                display: "flex",
                zIndex: 1000,
                position: "fixed",
                top: 130,
                left: 350,
                padding: "8px 0",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Alert severity={status === 200 ? "success" : "error"}>
                {errorMessage}
              </Alert>
            </Stack>
          )}

          <form style={{ marginTop: "0px", width: "100%" }}>
            <Stack spacing={0}>
              <Typography
                style={{
                  fontSize: "18px",
                  color: "black",
                  textAlign: "left",
                  marginTop: "5px",
                }}
              >
                Album Name
              </Typography>
              <TextField
                placeholder="Enter Your Album Name"
                value={albumName}
                onChange={(e) => {
                  setAlbumName(e.target.value);
                  setErrorMessage("");
                }}
                fullWidth
              />

              <Typography
                style={{
                  fontSize: "18px",
                  color: "black",
                  textAlign: "left",
                  marginTop: "1.3rem",
                }}
              >
                Event Place
              </Typography>
              <TextField
                placeholder="Enter your event place eg(church street)"
                value={venue}
                onChange={(e) => {
                  setVenue(e.target.value);
                  setErrorMessage("");
                }}
                fullWidth
              />

              <Grid container spacing={2} mt={2}>
                <Grid item xs={12} sm={6}>
                  <Typography
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "left",
                    }}
                  >
                    Event Timing
                  </Typography>
                  <Select
                    value={slot}
                    onChange={(e) => {
                      setSlot(e.target.value);
                      setErrorMessage("");
                    }}
                    fullWidth
                  >
                    {/* <MenuItem value={"Select"}>Select</MenuItem> */}
                    <MenuItem value={"Morning"}>Morning</MenuItem>
                    <MenuItem value={"Afternoon"}>Afternoon</MenuItem>
                    <MenuItem value={"Evening"}>Evening</MenuItem>
                    <MenuItem value={"Night"}>Night</MenuItem>
                  </Select>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "left",
                    }}
                  >
                    Event Category
                  </Typography>
                  <Select
                    value={cityName}
                    onChange={(e) => setCityName(e.target.value)}
                    fullWidth
                    label="select event type"
                  >
                    <MenuItem value={"Wedding Event"}>Wedding Event</MenuItem>
                    <MenuItem value={"Hiking - Trecking"}>
                      Hiking - Trecking
                    </MenuItem>
                    <MenuItem value={"Birthday Event"}>
                      Birthday Events
                    </MenuItem>
                    <MenuItem value={"Party"}>Party</MenuItem>

                    <MenuItem value={"Conferences"}>
                      Conferences and Seminars
                    </MenuItem>
                    <MenuItem value={"Festivals"}>Festivals and Fairs</MenuItem>
                    <MenuItem value={"Public Holidays"}>
                      Public Holidays
                    </MenuItem>
                    <MenuItem value={"Community Meetings"}>
                      Community Meetings
                    </MenuItem>
                    <MenuItem value={"Concerts and Performances"}>
                      Concerts and Performances
                    </MenuItem>
                    <MenuItem value={"Sporting Events"}>
                      Sporting Events
                    </MenuItem>
                    <MenuItem value={"Parades and Public Celebrations"}>
                      Parades and Public Celebrations
                    </MenuItem>
                    <MenuItem value={"Exhibitions and Trade Shows"}>
                      Exhibitions and Trade Shows
                    </MenuItem>
                    <MenuItem value={"Protests and Demonstrations"}>
                      Protests and Demonstrations
                    </MenuItem>
                    <MenuItem value={"CorporateMeetings"}>
                      Corporate Meetings and Retreats
                    </MenuItem>
                    <MenuItem value={"BirthdayParties"}>
                      Birthday Parties
                    </MenuItem>
                    <MenuItem value={"PrivateDinners"}>
                      Private Dinners or Parties
                    </MenuItem>
                    <MenuItem value={"BoardMeetings"}>Board Meetings</MenuItem>
                    <MenuItem value={"FamilyReunions"}>
                      Family Reunions
                    </MenuItem>
                    <MenuItem value={"PrivateCeremonies"}>
                      Private Ceremonies
                    </MenuItem>
                    <MenuItem value={"Workshops"}>
                      Exclusive Workshops or Training
                    </MenuItem>
                    <MenuItem value={"Funerals"}>
                      Funerals and Memorial Services
                    </MenuItem>
                    <MenuItem value={"Marathon"}>Marathon</MenuItem>
                    <MenuItem value={"CorporateEvents"}>
                      Corporate events
                    </MenuItem>
                    <MenuItem value={"Showers"}>Bridal/baby shower</MenuItem>
                    <MenuItem value={"Engagements"}>
                      Engagement parties
                    </MenuItem>
                    <MenuItem value={"Anniversaries"}>Anniversary</MenuItem>
                  </Select>
                </Grid>
              </Grid>

              <Typography
                style={{
                  fontSize: "18px",
                  color: "black",
                  textAlign: "left",
                  marginTop: "1.3rem",
                }}
              >
                Event Date
              </Typography>
              <TextField
                type="date"
                value={eventDate}
                onChange={(e) => {
                  setEventDate(e.target.value);
                  setErrorMessage("");
                }}
                inputProps={{ max: today }}
                fullWidth
              />

              <Stack
                direction={"column"}
                alignItems={"center"}
                pt={4}
                position={"relative"}
              ></Stack>
              {eventType === "Public" && (
                <Stack
                  direction={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  marginLeft={"5px"}
                >
                  <Typography variant="h4" fontWeight={400}>
                    Before you finish!!
                  </Typography>
                  <Stack direction={"row"} spacing={0} mt={2}>
                    <Typography
                      variant="h7"
                      fontWeight={400}
                      ml={4}
                      paddingRight={0}
                      textAlign={"flex-start"}
                      // mb={4}
                    >
                      Do you want your album showcased with everyone ?
                    </Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isBanner}
                          onChange={() => setIsBanner(!isBanner)}
                          color="primary"
                        />
                      }
                      sx={
                        {
                          // padding: "3px",
                        }
                      }
                      label=""
                    />
                  </Stack>
                </Stack>
              )}

              {eventType === "Public" && isBanner && (
                <>
                  <Stack>
                    {!bannerImage && (
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        border="2px dashed grey"
                        borderRadius="10px"
                        width={"80%"}
                        height={"20px"}
                        sx={{
                          mx: "auto",
                          mb: 1,
                          backgroundColor: "#ECF8FB",
                          p: 6,
                          mt: 5,
                        }}
                      >
                        <input
                          accept="image/*"
                          style={{ display: "none" }}
                          id="file-upload-input"
                          type="file"
                          name="uploadImage"
                          onChange={handleImageChange}
                        />

                        <label htmlFor="file-upload-input">
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            sx={{ cursor: "pointer", textAlign: "center" }}
                          >
                            <UploadFileRounded sx={{ fontSize: 48, mb: 2 }} />
                            <Typography variant="body1">
                              Choose Banner Image
                            </Typography>
                          </Box>
                        </label>
                      </Box>
                    )}
                    <Typography
                      color={"grey"}
                      fontSize={"12px"}
                      fontStyle={"italic"}
                    >
                      <li>
                        <strong>
                          {" "}
                          The image you choose that will be displaying as your a
                          banner
                        </strong>
                      </li>
                    </Typography>

                    {bannerImage && (
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="center"
                        textAlign="center"
                        sx={{
                          marginTop: "20px",
                          position: "relative",
                          width: "80%",
                          mx: "auto",
                        }}
                      >
                        <img
                          src={URL.createObjectURL(bannerImage)}
                          alt="Uploaded File"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "200px",
                            borderRadius: "10px",
                            marginBottom: "16px",
                          }}
                        />
                        <IconButton onClick={handleBannerRemove} sx={{}}>
                          <img
                            src={deleteicon}
                            alt="Delete"
                            style={{
                              position: "relative",
                              top: "-90px",
                              left: "10px",
                            }}
                          />
                        </IconButton>
                      </Box>
                    )}
                  </Stack>
                  <TextField
                    style={{
                      marginTop: "5%",
                    }}
                    placeholder="Add a tag line for your album"
                    value={bannerTitle}
                    onChange={(e) => setBannerTitle(e.target.value)}
                    required
                  />
                  <Typography
                    color={"grey"}
                    fontSize={"12px"}
                    fontStyle={"italic"}
                  >
                    <li>
                      <strong>
                        The tag line you using use for as .............
                      </strong>
                      {""}
                    </li>
                  </Typography>
                </>
              )}
              <Stack direction={"row"} spacing={5}>
                <Button
                  variant="Text"
                  // fullWidth
                  style={{
                    width: "40%",
                    fontWeight: "400",
                    fontSize: "15px",
                    marginTop: 20,
                    color: "black",
                    backgroundColor: "#DBDBDE",
                    textTransform: "none",
                    // textDecoration: "underline",
                  }}
                  onClick={handlebackbutton}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  style={{ marginTop: 20, textTransform: "none" }}
                  onClick={handleEventSubmit}
                >
                  Finish
                </Button>
              </Stack>
            </Stack>
          </form>
        </Card>
      </Grid>
      {eventType === "Public" ? (
        <MessageBoxRight
          style={
            {
              // display: "none"
            }
          }
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: 400,
              color: "#000000",
              lineHeight: "20px",
              fontFamily: "Poppins, sans-serif",
            }}
          >
            <ul style={{ paddingLeft: "20px", fontSize: "12px" }}>
              <li>
                <strong>Wide Audience Appeal: </strong> Designed to attract a
                broad audience, making it accessible to everyone.
              </li>
              <li>
                <strong>Accessibility: </strong> Typically held in public spaces
                or easily accessible venues, encouraging wider participation
              </li>
              <li>
                <strong> Scalable Venues: </strong> Held in large venues or
                outdoor spaces, accommodating a significant number of attendees.{" "}
              </li>
              <li>
                <strong> Increased Visibility: </strong> Offers greater exposure
                for sponsors, businesses, and organizers.
              </li>
              <li>
                <strong> Shared Experiences: </strong> Offers a collective
                experience that can be both memorable and impactful for everyone
                involved.
              </li>
            </ul>
          </Typography>
        </MessageBoxRight>
      ) : (
        <MessageBoxRight>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: 400,
              color: "#000000",
              lineHeight: "20px",
              fontFamily: "Poppins, sans-serif",
            }}
          >
            <ul style={{ paddingLeft: "20px", fontSize: "12px" }}>
              <li>
                {" "}
                <strong>Authorized Access Only:</strong> Utilizes advanced
                security measures such as encrypted invitations and secure entry
                methods..
              </li>
              <li>
                {" "}
                <strong> Peace of Mind: </strong>Hosts and attendees can enjoy
                the event without concerns about unauthorized access or
                disturbances
              </li>
              <li>
                <strong> Data Protection: </strong> Ensures all personal data of
                attendees is handled with utmost security and confidentiality{" "}
              </li>
              <li>
                <strong> Enhanced Networking:</strong> Facilitates better
                networking opportunities, making it ideal for professional and
                social engagements
              </li>
            </ul>
          </Typography>
        </MessageBoxRight>
      )}
      {eventType === "Public" ? (
        <MessageBoxBottom
          style={
            {
              // display: "none"
            }
          }
        >
          <Typography
            // variant="body1"
            sx={{
              fontSize: "1rem",
              fontWeight: 400,
              color: "#000000",
              lineHeight: "20px",
              fontFamily: "Poppins, sans-serif",
            }}
          >
            <ul style={{ paddingLeft: "20px", fontSize: "12px" }}>
              <li>
                <strong>Wide Audienceas Appeal: </strong> Designed to attract a
                broad audience, making it accessible to everyone.
              </li>
              <li>
                <strong>Accessibility: </strong> Typically held in public spaces
                or easily accessible venues, encouraging wider participation
              </li>
              <li>
                <strong> Scalable Venues: </strong> Held in large venues or
                outdoor spaces, accommodating a significant number of attendees.{" "}
              </li>
              <li>
                <strong> Increased Visibility: </strong> Offers greater exposure
                for sponsors, businesses, and organizers.
              </li>
              <li>
                <strong> Shared Experiences: </strong> Offers a collective
                experience that can be both memorable and impactful for everyone
                involved.
              </li>
            </ul>
          </Typography>
        </MessageBoxBottom>
      ) : (
        <MessageBoxBottom>
          <Typography
            variant="body1"
            sx={{
              fontSize: "1rem",
              fontWeight: 400,
              color: "#000000",
              lineHeight: "20px",
              fontFamily: "Poppins, sans-serif",
            }}
          >
            <ul style={{ paddingLeft: "20px", fontSize: "12px" }}>
              <li>
                {" "}
                <strong>Authorized Access Only:</strong> Utilizes advanced
                security measures such as encrypted invitations and secure entry
                methods..
              </li>
              <li>
                {" "}
                <strong> Peace of Mind: </strong>Hosts and attendees can enjoy
                the event without concerns about unauthorized access or
                disturbances
              </li>
              <li>
                <strong> Data Protection: </strong> Ensures all personal data of
                attendees is handled with utmost security and confidentiality{" "}
              </li>
              <li>
                <strong> Enhanced Networking:</strong> Facilitates better
                networking opportunities, making it ideal for professional and
                social engagements
              </li>
            </ul>
          </Typography>
        </MessageBoxBottom>
      )}
    </Grid>
  );
};

export default EventForm;
