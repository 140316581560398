import React, { useEffect, useState, useCallback } from "react";
import {
  Typography,
  TableCell,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Stack,
  Modal,
  Box,
  Button
} from "@mui/material";
import { Group } from "@mui/icons-material";

import myImage from "../../../images/nodata.png";
import { SearchField } from "../../Component/SerachField";
import {
  TableCom,
  THead,
  TCell,
  TBody,
  Trow
} from "../../Component/table";
import { DeletePhotographerApi, getphotographerList } from "../../../API/Admin";
import Menus from "../../Component/menu";
import { useNavigate } from "react-router";

const UserList = () => {
  const [photographerData, setPhotographerData] = useState([]);
  const [searchData, setSearchData] = useState('');
  const [metaPage, setmetaPage] = useState(1);
  // const [profile, setProfile] = useState('');
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('sm'));
  const [page, setPage] = useState(1);
  const [skeliton, setskeliton] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [photographerId, setphotographerId] = useState('');
  const navigate = useNavigate();

  const getPotgrapherData = useCallback(async () => {
    const userType = "user";
    const data = {
      userType,
      searchData,
      page
    };
    setskeliton(true);
    try {
      const response = await getphotographerList(data);
      console.log(response, 'responnnnseeeeeeeeee');
      setPhotographerData(response?.data?.data);
      setmetaPage(response?.data?.pagination?.totalPages);
      console.log("response", response);
      setskeliton(false);
    } catch (error) {
      console.log("error", error);
    }
  }, [searchData, page]);

  const useDebounce = (inputValue, delay) => {
    const [debounceValue, setDebounceValue] = useState(inputValue);
    useEffect(() => {
      const storvalue = setTimeout(() => {
        setDebounceValue(inputValue);
      }, delay);
      return () => {
        clearTimeout(storvalue);
      };
    }, [inputValue, delay]);
    return debounceValue;
  };

  const finalSearchValue = useDebounce(searchData, 1500);

  useEffect(() => {
    getPotgrapherData();
  }, [page, finalSearchValue, getPotgrapherData]);

  const goToPreviousPage = () => {
    setPage(page - 1);
  };

  const goToNextPage = async () => {
    try {
      const nextPage = page + 1;
      setPage(nextPage);
    } catch (error) {
      // console.log("error", error);
    }
  };

  const headers = [
    "Action",
    "Name",
    "Email",
    "Added User",
    "Registered",

  ];

  const deletePhotographerApi = async () => {
    try {
      const response = await DeletePhotographerApi(photographerId);
      console.log("response", response);
      if (response?.status === 200) {
        handleCloseDelete();
        getPotgrapherData();
      }
      console.log("response", response);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const deletePhotographer = (data) => {
    setphotographerId(data?._id);
    setOpenDelete(true);
  };

  // const albumCreated = (data) => {
  //   console.log("data", data);
  //   const id = data._id;
  //   navigate("/photographerDetailes", { state: { id } });
  // };

  const menu = [
    { name: 'Delete', icon: <Group />, onClick: deletePhotographer },
  ];

  const styled = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
    paddingBottom: 5
  };

  const searchProps = (data) => {
    console.log("data", data);
    setSearchData(data);
  };

  const calculateTotalCredits = (subscriptionHistory) => {
    let total = 0;
    subscriptionHistory?.forEach((item) => {
      total += item.creadits;
    });
    return total;
  };

  const addedUsers =(value)=>{
    console.log("value",value)
     navigate("/userDetailes",{state:{userId:value._id}})
  
  }
  return (
    <div style={{ marginTop: 10, marginInline: isMobileOrTablet ? 20 : 5 }}>
      <>
        {skeliton ? (
          <div style={{ display: 'flex', justifyContent: "center" }}>
            <CircularProgress color="inherit" />
          </div>
        ) : photographerData?.length === 0 || photographerData === undefined ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={myImage} alt="No data available" />
          </div>
        ) : (
          <>
            <Stack width={350}>
              <SearchField searchProps={searchProps} placeholder="Search Name,Email" />
            </Stack>

            <div style={{ marginTop: 10 }}>
              <Modal
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropProps={{ onClick: handleCloseDelete }}
              >
                <Box sx={styled}>
                  <Typography>Are you sure you want to delete this User?</Typography>
                  <div style={{ position: 'absolute', right: 20 }}>
                    <Button onClick={handleCloseDelete}>No</Button>
                    <Button onClick={deletePhotographerApi}>Yes</Button>
                  </div>
                </Box>
              </Modal>

              <TableCom>
                <THead>
                  {headers?.map((header, index) => (
                    <TableCell
                      key={index}
                      style={{
                        borderRight: "1px solid white",
                        paddingRight: "10px",
                        fontWeight: "40%",
                        fontSize: "100%",
                        color: "aliceblue",
                        backgroundColor: "#0C2142"
                      }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </THead>

                <TBody>
                  {photographerData?.map((value, index) => (
                    <Trow key={index} onClick={()=>addedUsers(value)}>
                      <TCell>
                        <Menus
                          menu={menu}
                          userdata={value}
                          types={"skill"}
                          // setProfile={setProfile}
                        />
                      </TCell>
                      <TCell>
                        <Typography>{value.name ? value.name : "N/A"}</Typography>
                      </TCell>
                      <TCell>
                        <Typography>{value.email ? value.email : "N/A"}</Typography>
                      </TCell>
                      <TCell>
                        <Typography>{value.subscriptionLimit ? value.subscriptionLimit : "N/A"} /{calculateTotalCredits(value?.SubscriptionHistory)}</Typography>
                      </TCell>
                      <TCell>
                        <Typography>{value.createdAt ? value.createdAt.split('T')[0] : "N/A"}</Typography>
                      </TCell>
                    </Trow>
                  ))}
                </TBody>
              </TableCom>
            </div>

            <Stack direction={"row"} style={{ justifyContent: "flex-end", marginTop: 10 }} spacing={1}>
              <Typography style={{ marginTop: 8 }}>TotalPage:<span fontWeight={"bold"}> {metaPage}</span></Typography>
              <Button onClick={goToPreviousPage} disabled={page === 1} variant="outlined">Pre..</Button>
              <Typography fontWeight={"bold"} style={{ marginTop: 8 }}>{page}</Typography>
              <Button onClick={goToNextPage} disabled={page === metaPage} variant="outlined">Next</Button>
            </Stack>
          </>
        )}
      </>
    </div>
  );
};

export default UserList;
