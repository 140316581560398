import React from 'react';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { Typography, Alert } from '@mui/material';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme } from '@mui/material/styles';
import { otpVerify } from '../../API/Auth';
// import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { addProfileRequest } from '../../Store/action';

export default function Login2({ Login2Data, errorMessage, userEmail, moveNextStep }) {
  const [otp, setOtp] = React.useState('');
  const [errorMessages, setErrorMessages] = React.useState(errorMessage);
  const [status, setStatus] = React.useState('');
  // const [isOtpValid, setIsOtpValid] = React.useState(false); // New state to track OTP validity
  // const theme = useTheme();
  // const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const handleChange = async (newValue) => {
    setErrorMessages("");
    const newValues = newValue.replace(/[^0-9]/g, '');
    setOtp(newValues);

    if (newValue.length === 6) {
      try {
        const data = {
          email: userEmail,
          otp: newValue
        };

        const response = await otpVerify(data);
        setStatus(response.status);
        
        if (response.status === 200) {
          setErrorMessages("");
          
          if (response?.data?.userType === null) {
            moveNextStep();
          } else {
            // Clear localStorage first
            localStorage.clear();
            
            // Set all required localStorage items
            const storageItems = {
              "accessToken": response?.data?.accessToken,
              "userId": response?.data?.data?._id,
              "email": response?.data?.data?.email,
              "profileImage": response?.data.data?.imageUrl
            };
            
            // Update localStorage atomically
            Object.entries(storageItems).forEach(([key, value]) => {
              if (value) localStorage.setItem(key, value);
            });

            // Dispatch profile request first
            const photoGrpherExist = response?.data?.IsExist;
            const datas = { 
              email: response?.data?.data?.email, 
              photoGrpherExist 
            };
            
            // Use Promise to ensure sequential execution
            await new Promise(resolve => {
              dispatch(addProfileRequest(datas));
              resolve();
            });

            setErrorMessages("Logged in Successfully");
            
            // Use a slightly longer timeout to ensure state updates are complete
            setTimeout(() => {
              setErrorMessages('');
              // Force a clean navigation
              window.location.href = '/home';
            }, 1500);
          }
        } else {
          setErrorMessages("Wrong OTP");
        }
      } catch (err) {
        console.error(err);
        setErrorMessages(err.message);
      }
    }

    Login2Data(newValue);
  };

  return (
    <div>
      {errorMessage || errorMessages ? (
        <Alert severity={status === 200 ? "success" : "error"}>
          {errorMessage ? errorMessage : errorMessages}
        </Alert>
      ) : null}

      <div style={{ marginTop: 10 }}>
        <Typography style={{ textAlign: "center" }}>
          An OTP has been sent to <span style={{ fontWeight: "bold" }}>{userEmail}</span>
        </Typography>
        <div style={{ marginTop: 10 }}>
          <MuiOtpInput value={otp} onChange={handleChange} length={6} />
        </div>
      </div>
    </div>
  );
}
