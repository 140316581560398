import React from 'react';
import { Container, Box, Typography, Avatar, Button, Grid } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: '#F4FAFF',
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  display:'flex',
  textAlign: 'center',
  alignItems:'center',
  justifyContent:'center',
  border: '1px solid #EAEAEA',
}));

const MessageBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#E3F2FD',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(2),
  textAlign: 'left',
}));

const Avrcard = ({ avatars, onAvatarSelect,selectedAvatar,searchDetaect}) => {
  //console.log("Avatars:", avatars);
  //console.log("On Avatar Select:", onAvatarSelect);
  //console.log("Selected Avatar:", selectedAvatar);
  //console.log("Search Detect:", searchDetaect);
  const handleAvatarClick = (id) => {
  
    onAvatarSelect(id);
  };

  const handleSearch=()=>{
    searchDetaect(selectedAvatar);
  }

  return (
    <StyledContainer maxWidth={'80%'}>
      <div style={{width:'80%', textAlign: 'center', alignItems:'center',justifyContent:'center',}}>
      <Typography sx={{color:'#2167CF',fontWeight:600,fontSize:'20px',lineHeight:'30px',fontFamily: "Poppins, sans-serif"}}>
        Select User
      </Typography>
      <MessageBox>
          <Typography variant="body1" sx={{ fontSize: '16px', fontWeight: 400, color: '#000000', lineHeight: '20px', fontFamily: "Poppins, sans-serif" }}>
            <ul style={{ paddingLeft: '20px' }}>
              <li>Select the user profile you wish to search.</li>
              <li>Add another user to search for their photos in the album.</li>
              <li>100% of clear front-facing photos will be accurately detected.</li>
              <li>Face detection may take time as the system searches for matching images in the album for each new user.</li>
              <li>Please wait patiently until all matching photos are displayed below.</li>
            </ul>
          </Typography>
        </MessageBox>
     

      {/* <Typography variant="body1" sx={{ margin: '16px 0',fontSize:'16px',fontWeight:400,color:'#000000',lineHeight:'20px',fontFamily: "Poppins, sans-serif"}}>
      Select 'User' to use our AI face detection technology, which makes it easy to find photos of yourself or others in the album from the event you attended, and let the AI identify your images."
      </Typography> */}
      <Grid container spacing={2} justifyContent="center" sx={{marginTop:'20px'}}>
        {avatars?.getUser?.map((avatar) => (
          <Grid item key={avatar._id}>
            <Box
              onClick={() => handleAvatarClick(avatar)}
              sx={{
                display: 'inline-flex',
                flexDirection: 'column',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <Avatar
                src={avatar.imageUrl} 
                alt={avatar.name}
                sx={{
                  width: 56,
                  height: 56,
                  marginBottom: 1,
                  borderWidth: '4px',
                  borderStyle: 'solid',
                  borderColor: 'transparent',
                  background: selectedAvatar._id === avatar._id 
                    ? `radial-gradient(circle, #297EFF, #00C3FF)`: 'none',
                }}
              />
              <Typography variant="body2" sx={{fontFamily: "Poppins, sans-serif",fontSize:'10px',lineHeight:'15px',fontWeight:400,color:'#000000'}}>{avatar.name}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Button variant="contained" color="primary" sx={{ marginTop: 4 }} onClick={handleSearch}>
        Search Photo
      </Button>
      </div>
    </StyledContainer>
  );
};

export default Avrcard;
