import axios from "axios";
import { server } from "./server";

// export const uploadImage = async (data) => {
//   console.log("data", data);
//   const AccesToken = localStorage.getItem("accessToken");
//   try {
//     let config = {
//       headers: {
//         Authorization: `Bearer ${AccesToken}`,
//         // 'Refresh-Token': `${refreshToken}`,
//         //   'Content-Type': `multipart/form-data`
//       },
//     };
//     const response = await axios.post(
//       `${server}/divine/file/upload-Image`,
//       data,
//       config
//     );
//     console.log(response, "resp1234567876543212345");
//     return response;
//   } catch (error) {
//     ////console.log("error:", error);
//     return error;
//   }
// };


export const uploadImage= async (data) => {
  const AccesToken = localStorage.getItem("accessToken");
    try {
    let config = {
        headers: {
           'Authorization': `Bearer ${AccesToken}`,
          // 'Refresh-Token': `${refreshToken}`,
         //  'Content-Type': `multipart/form-data`
        }
      };
      const response = await axios.post(`${server}/divine/file/upload-Image`, data,config);
     console.log(response , 'resp1234567876543212345');
      return response;
    } catch (error) {
      //console.log("error:", error);
      return error;
    }
  };
  const AccesToken = localStorage.getItem("accessToken");
  
  console.log("datasssssssssssss",AccesToken)


export const getAllImages = async (status, currentPage, event) => {
  console.log("status1235", status, currentPage, event);
  const page = currentPage;
  // //console.log("currentPage,ITEMS_PER_PAGE",currentPage,ITEMS_PER_PAGE)
  const AccesToken = localStorage.getItem("accessToken");

  try {
    let config = {
      headers: {
        Authorization: `Bearer ${AccesToken}`,
        // 'Refresh-Token': `${refreshToken}`,
        //   'Content-Type': `multipart/form-data`
      },
    };
    const response = await axios.get(
      `${server}/divine/file/getFile?status=${status}&page=${page}&search=${event}`,
      config
    );

    return response;
  } catch (error) {
    ////console.log("error:", error);
    return error;
  }
};

export const deleteImageApi = async (data) => {
  console.log("data125", data.item);
  const AccesToken = localStorage.getItem("accessToken");
  const id = data?.item._id;

  //  const event=data?.event

  console.log("id", id);
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${AccesToken}`,
        // 'Refresh-Token': `${refreshToken}`,
        //   'Content-Type': `multipart/form-data`
      },
    };
    // const response = await axios.delete(`${server}/divine/file/deleteFile/${id}/${filename}/${event}`,config);
    const response = await axios.delete(
      `${server}/divine/file/delete-processed-file/${id}`,
      config
    );

    return response;
  } catch (error) {
    ////console.log("error:", error);
    return error;
  }
};

export const DownloadImage = async (data) => {
  console.log("data", data);
  const AccesToken = localStorage.getItem("accessToken");
  //  const id =data?._id
  //  const fileName =data?.fileName
  const body = {
    key: data,
  };

  //console.log("id",id,fileName)
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${AccesToken}`,
      },
    };
    const response = await axios.post(
      `${server}/divine/file/downloadFile`,
      body,
      config
    );
    console.log(response, "responnnssee");

    return response;
  } catch (error) {
    ////console.log("error:", error);
    return error;
  }
};

export const CreateEvent = async (data) => {
  console.log(data, "dataqwerf");
  const AccesToken = localStorage.getItem("accessToken");
  //console.log("AccesToken",AccesToken);
  // const id = data?._id;
  // const fileName = data?.fileName;

  //console.log("id",id,fileName)
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${AccesToken}`,
      },
    };
    const response = await axios.post(
      `${server}/divine/event/evenCreate`,
      data,
      config
    );
    console.log(response, "responsBeresponseresponseresponse");
    return response;
  } catch (error) {
    return error;
  }
};

export const EditEvents = async (data) => {
  console.log("data12hvgbhnjm", data);
  const AccesToken = localStorage.getItem("accessToken");
  //console.log("AccesToken",AccesToken);
  // const id = data?._id;
  // const fileName = data?.fileName;

  //console.log("id",id,fileName)
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${AccesToken}`,
      },
    };
    const response = await axios.patch(
      `${server}/divine/event/editEvent`,
      data,
      config
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const MakeFavouritefrontApi = async (data) => {
  try {
    // Verify the data being sent
    console.log("API Request Data:", data);

    if (!data.albumId) {
      throw new Error("Album ID is required");
    }

    const response = await axios.patch(
      `${server}/divine/file/makeFavourite`,
      {
        id: data.id,
        favourite: data.favourite,
        albumId: data.albumId
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          'Content-Type': 'application/json'
        },
      }
    );
    return response;
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const GetEventApi = async (date) => {
  console.log(date, "date1234567");
  const AccesToken = localStorage.getItem("accessToken");
  //console.log("AccesToken",AccesToken);

  try {
    let config = {
      headers: {
        Authorization: `Bearer ${AccesToken}`,
      },
    };

    const response = await axios.get(
      `${server}/divine/event/getEvent?startDate=${
        date === undefined ? "" : date.startDate
      }&endDate=${date === undefined ? "" : date.endDate}&search=${
        date === undefined ? "" : date?.search
      }`,
      config
    );
    console.log(response, "responseresponseresponse");

    return response;
  } catch (error) {
    ////console.log("error:", error);
    return error;
  }
};

export const JoinEventApi = async (data) => {
  console.log("data", data);
  const AccesToken = localStorage.getItem("accessToken");
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${AccesToken}`,
      },
    };

    const response = await axios.post(
      `${server}/divine/event/redeemEventCode`,
      data,
      config
    );

    return response;
  } catch (error) {
    ////console.log("error:", error);
    return error;
  }
};

export const getProfileApi = async () => {
  const AccesToken = localStorage.getItem("accessToken");
  const UserId = localStorage.getItem("userId");
  console.log("UserId", UserId);

  try {
    let config = {
      headers: {
        Authorization: `Bearer ${AccesToken}`,
      },
    };

    const response = await axios.get(
      `${server}/divine/Auth/profile?id=${UserId}`,
      config
    );
    return response;
  } catch (error) {
    ////console.log("error:", error);
    return error;
  }
};

export const getJoinGroup = async (data, dates) => {
  console.log("datesdatesdatesdates", dates);
  const AccesToken = localStorage.getItem("accessToken");
  const eventCodesQuery = Array.isArray(data) ? data.join(",") : "";

  try {
    let config = {
      headers: {
        Authorization: `Bearer ${AccesToken}`,
      },
    };
    const response = await axios.get(
      `${server}/divine/event/getJoinEvent?eventCode=${eventCodesQuery}&startDate=${
        dates === undefined ? "" : dates.startDate
      }&endDate=${dates === undefined ? "" : dates.endDate}&search=${
        dates === undefined ? "" : dates?.search
      }`,
      config
    );
    return response;
  } catch (error) {
    ////console.log("error:", error);
    return error;
  }
};

export const getPublicGroup = async (dates) => {
  const AccesToken = localStorage.getItem("accessToken");
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${AccesToken}`,
      },
    };
    const response = await axios.get(
      `${server}/divine/event/getPublicEvents?eventTypes=Public&startDate=${
        dates === undefined ? "" : dates.startDate
      }&endDate=${dates === undefined ? "" : dates.endDate}&search=${
        dates === undefined ? "" : dates?.search
      }`,
      config
    );
    return response;
  } catch (error) {
    ////console.log("error:", error);
    return error;
  }
};

export const getPublicBanner = async (dates) => {
  const AccesToken = localStorage.getItem("accessToken");
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${AccesToken}`,
      },
    };
    const response = await axios.get(
      `${server}/divine/event/getEventWithBanner?eventTypes=Public&startDate=${
        dates === undefined ? "" : dates.startDate
      }&endDate=${dates === undefined ? "" : dates.endDate}&search=${
        dates === undefined ? "" : dates?.search
      }`,
      config
    );
    return response;
  } catch (error) {
    ////console.log("error:", error);
    return error;
  }
};

export const getPublicUserGroup = async (dates) => {
  const AccesToken = localStorage.getItem("accessToken");
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${AccesToken}`,
      },
    };
    const response = await axios.get(
      `${server}/divine/event/getPublicEvents?eventTypes=Public&startDate=${
        dates === undefined ? "" : dates.startDate
      }&endDate=${dates === undefined ? "" : dates.endDate}&search=${
        dates === undefined ? "" : dates?.search
      }`,
      config
    );

    return response;
  } catch (error) {
    ////console.log("error:", error);
    return error;
  }
};

export const getPrivateGroup = async (dates) => {
  const AccesToken = localStorage.getItem("accessToken");
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${AccesToken}`,
      },
    };
    const response = await axios.get(
      `${server}/divine/event/getPublicEvents?eventTypes=Private&startDate=${
        dates === undefined ? "" : dates.startDate
      }&endDate=${dates === undefined ? "" : dates.endDate}&search=${
        dates === undefined ? "" : dates?.search
      }`,
      config
    );
    return response;
  } catch (error) {
    ////console.log("error:", error);
    return error;
  }
};
export const deleteMyEvent = async (item) => {
  console.log(
    "Attempting to delete event with ID:",
    item._id,
    "and createrId:",
    item.createrId
  );
  const AccesToken = localStorage.getItem("accessToken");

  try {
    let config = {
      headers: {
        Authorization: `Bearer ${AccesToken}`,
      },
      params: {
        id: item._id,
        createrId: item.createrId,
      },
    };

    const response = await axios.delete(
      `${server}/divine/event/DeleteEvent`,
      config
    );

    console.log("Response from deleteMyEvent:", response);
    return response.data;
  } catch (error) {
    console.error(
      "Error in deleteMyEvent:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const updateProfileApi = async (data) => {
  try {
    const AccesToken = localStorage.getItem("accessToken");
    const UserId = localStorage.getItem("userId");
    let config = {
      headers: {
        Authorization: `Bearer ${AccesToken}`,
      },
    };

    const response = await axios.patch(
      `${server}/divine/Auth/updateProfile?id=${UserId}`,
      data,
      config
    );
    return response;
  } catch (error) {
    ////console.log("error:", error);
    return error;
  }
};

export const getCountImagesUpload = async () => {
  const AccesToken = localStorage.getItem("accessToken");

  try {
    let config = {
      headers: {
        Authorization: `Bearer ${AccesToken}`,
      },
    };
    const response = await axios.get(
      `${server}/divine/file/getuploadedImagesCount`,
      config
    );
    return response;
  } catch (error) {
    ////console.log("error:", error);
    return error;
  }
};

export const getEventcurousel = async (event) => {
  const AccesToken = localStorage.getItem("accessToken");
  const UserId = localStorage.getItem("userId");
  console.log("UserId", UserId);

  try {
    let config = {
      headers: {
        Authorization: `Bearer ${AccesToken}`,
      },
    };

    const response = await axios.get(
      `${server}/divine/event/getEventcurrosel/${event}`,
      config
    );
    return response;
  } catch (error) {
    ////console.log("error:", error);
    return error;
  }
};
export const fileCheking = async (files, event, formData) => {
  console.log("eventCode", files, event);
  const AccesToken = localStorage.getItem("accessToken");
  const userId = localStorage.getItem("userId");
  console.log("UserId", files);

  try {
    let config = {
      headers: {
        Authorization: `Bearer ${AccesToken}`,
      },
    };
    const fileQueryString = files.map((file) => `fileid=${file}`).join("&");
    console.log("fileQueryString", fileQueryString);
    const response = await axios.post(
      `${server}/divine/file/FileNameCheck?event=${event}&userId=${userId}&${fileQueryString}`,
      formData,
      config
    );
    return response;
  } catch (error) {
    ////console.log("error:", error);
    return error;
  }
};

export const faceDetectedImagesApi = async (data) => {
  const AccesToken = localStorage.getItem("accessToken");
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${AccesToken}`,
      },
    };
    const response = await axios.post(
      `${server}/divine/Auth/FaceMatch`,
      data,
      config
    );
    return response;
  } catch (error) {
    ////console.log("error:", error);
    return error;
  }
};

export const getDetectedHighImages = async (data) => {
  const AccesToken = localStorage.getItem("accessToken");
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${AccesToken}`,
      },
    };

    const response = await axios.get(
      `${server}/divine/file/geHighImages?image=${data}`,
      config
    );
    return response;
  } catch (error) {
    ////console.log("error:", error);
    return error;
  }
};

export const getDetectedLowImages = async (data) => {
  console.log("data123", data);

  const AccesToken = localStorage.getItem("accessToken");
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${AccesToken}`,
      },
    };

    const response = await axios.get(
      `${server}/divine/file/getDetectedLowImages?event=${data.event}&images=${data.imageURL}`,
      config
    );
    console.log(response, "responnnseeewert");
    return response;
  } catch (error) {
    ////console.log("error:", error);
    return error;
  }
};

export const checkFaceWithGurudevAndLowImages = async (
  gurudevImage,
  event,
  imageURL
) => {
  console.log(gurudevImage, event, imageURL, "gurudevImageimageURL");
  const AccessToken = localStorage.getItem("accessToken");

  try {
    const lowImagesResponse = await getDetectedLowImages({ event, imageURL });

    if (lowImagesResponse.data && lowImagesResponse.data.images) {
      const formData = new FormData();
      formData.append("gurudevImage", gurudevImage);
      formData.append(
        "lowImages",
        JSON.stringify(lowImagesResponse.data.images)
      );

      const matchResponse = await axios.post(
        `${server}/api/matchFaces`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${AccessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return matchResponse.data;
    } else {
      throw new Error(
        "No low images detected or there was an error in the API response"
      );
    }
  } catch (error) {
    console.error("Error in checkFaceWithGurudevAndLowImages:", error);
    throw error;
  }
};

export const UploadDetedImages = async (data) => {
  console.log("data123", data);

  const AccesToken = localStorage.getItem("accessToken");
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${AccesToken}`,
      },
    };

    const response = await axios.post(
      `${server}/divine/file/UploadDetectedImages`,
      data,
      config
    );
    console.log(response, "responnnseeewert");
    return response;
  } catch (error) {
    ////console.log("error:", error);
    return error;
  }
};

export const GetUploadDectedImages = async (data) => {
  console.log("data123", data);

  const AccesToken = localStorage.getItem("accessToken");
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${AccesToken}`,
      },
    };

    const response = await axios.get(
      `${server}/divine/file/getDetectedAllImages?userId=${data.userId}&albumId=${data.albumId}&page=${data.page}`,
      config
    );
    console.log(response, "responnnseeewert");
    return response;
  } catch (error) {
    ////console.log("error:", error);
    return error;
  }
};

export const uploadAndCheckFace = async (data) => {
  console.log("Data to upload:", data);
  try {
    const response = await axios.post(
      `${server}/divine/Auth/upload-single-image`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("Upload response:", response);
    return response;
  } catch (error) {
    console.error("Error in uploadAndCheckFace:", error);
    return error;
  }
};

// export const UploadProgress = async (id) => {
//   console.log("id12345", id);

//   const AccesToken = localStorage.getItem("accessToken");
//   try {
//     let config = {
//       headers: {
//         Authorization: `Bearer ${AccesToken}`,
//       },
//     };

//     const response = await axios.get(
//       `${server}/divine/file/upload-progress/${id}`,
//       config
//     );
//     console.log(response, "responnnseeewert");
//     return response;
//   } catch (error) {
//     ////console.log("error:", error);
//     return error;
//   }
// };


export const UploadProgress=async(id)=>{
  console.log("id12345",id)
  const AccesToken = localStorage.getItem("accessToken");
  try {
  let config = {
      headers: {
         'Authorization': `Bearer ${AccesToken}`,
      }
    };
    const response = await axios.get(`${server}/divine/file/upload-progress/${id}`,config);
    console.log(response , 'responnnseeewert');
    return response;
  } catch (error) {
    ////console.log("error:", error);
    return error;
  }
}


export const compareUserFace = async (data) => {
  console.log("Data to upload:", data);
  try {
    const response = await axios.post(
      `${server}/divine/Auth/update-profile-image`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("Upload response:", response);
    return response;
  } catch (error) {
    console.error("Error in uploadAndCheckFace:", error);
    return error;
  }
};

export const checkAlbumPermissionFront = async (albumId, subOwnerId) => {
  const accessToken = localStorage.getItem("accessToken");
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        albumId: albumId,
        subOwnerId: subOwnerId,
      },
    };
    const response = await axios.get(
      `${server}/divine/file/check-Album-Permission`,
      config
    );
    console.log(response, "response");
    return response;
  } catch (error) {
    console.error("Error fetching album permission:", error);
    return error;
  }
};

export const sendRequestMail = async (data) => {
  const accessToken = localStorage.getItem("accessToken");
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.post(
      `${server}/divine/file/send-Upload-Request`,
      data,
      config
    );
    console.log(response, "response");
    return response;
  } catch (err) {
    console.error("Error sending Request:", err);
    throw err;
  }
};

export const getRequestFromUser = async (userId) => {
  const accessToken = localStorage.getItem("accessToken");

  try {
    let config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        id: userId,
      },
    };

    const response = await axios.get(
      `${server}/divine/file/get-OwnerId-getRequest`,
      config
    );
    console.log("response", response);
    return response;
  } catch (error) {
    console.error("Error fetching album permission:", error);
    return error;
  }
};

export const updateUploadPermission = async (data) => {
  const accessToken = localStorage.getItem("accessToken");

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const response = await axios.patch(`${server}/divine/file/update-Permission`, data, config);
    return response;
  } catch (error) {
    console.error("Error in updateUploadPermission front-end API:", error);
    return error;
  }
};

export const getFavoritesApi = async (albumId) => {
  // const accessToken = localStorage.getItem("accessToken");
  const accessToken = localStorage.getItem("accessToken");
  // const accessToken = "eyJhbGciOiJIUzI1NiJ9.NjZiNWEwNjVjOGI3NmRkNWJlZWUyNzQz.bPNL4sqLyAzuNPkSxUtbVyAzc_2Sxm0liig-bWxTv7Q";
  
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const response = await axios.post(
      `${server}/divine/file/getFavorites`,
      { albumId },
      config
    );
    return response;
  } catch (error) {
    console.error("Error fetching favorites:", error);
    throw error;
  }
};
