import React, { useState, } from "react";
import {
  IconButton,
  Modal,
  Box,
  Typography,
  Button,
  Badge,
  Backdrop,
  CircularProgress,
  CardContent,
  CardActions,
  Divider,
  Paper,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import {
  getRequestFromUser,
  
} from "../../API/Photographer";
import { Grid } from "@mui/material";

const modalStyle = (theme) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%", // Adjusted width to 80% to reduce size
  maxWidth: 600, // Set a max width
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 2, // Reduced padding to make it more compact
  [theme.breakpoints.down('sm')]: {
    width: "95%",
    p: 1.5, // Reduced padding for small screens
  },
});

const NotificationModal = () => {
  const [open, setOpen] = useState(false);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pendingCount, setPendingCount] = useState(0);

  const profileData = useSelector((state) => state.user);
  const subOwnerID = profileData?.user?.data?.data?._id;

  const handleUpdatePermission = async (val, req) => {
    try {
      // const data = {
      //   albumId: val?.albumId,
      //   request: req,
      //   subOwnerId: val?.subOwnerId,
      //   name: val?.name,
      //   id: val?._id,
      // };
      setLoader(true);
      // const res = await updateUploadPermission(data);
      fetchNotifications(); // Refetch all data
      setLoader(false);
    } catch (err) {
      console.error("Error updating permission:", err);
      setLoader(false);
    }
  };

  const fetchNotifications = async () => {
    if (subOwnerID) {
      try {
        setLoader(true);
        const response = await getRequestFromUser(subOwnerID);
        if (response.data.data) {
          setPendingRequests(response.data.data);
          const pendingItems = response.data.data.filter(
            (item) => item.request === "Requested"
          );
          setPendingCount(pendingItems.length);
        } else {
          setPendingCount(0);
          setPendingRequests([]);
        }
        setLoader(false);
      } catch (error) {
        console.error("Error fetching notifications:", error);
        setLoader(false);
      }
    }
  };

  const handleOpen = () => {
    setOpen(true);
    fetchNotifications();
  };

  const handleClose = () => setOpen(false);

  return (
    <div>
      <IconButton color="primary" onClick={handleOpen}>
        <Badge
          badgeContent={pendingCount}
          color="error"
          invisible={pendingCount === 0}
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="notification-modal-title"
        aria-describedby="notification-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={(theme) => modalStyle(theme)} style={{ maxHeight: "400px", overflow: "auto" }}>
          <Typography
            id="notification-modal-title"
            variant="h6"
            component="h2"
            gutterBottom
            align="center"
          >
            User Requests
          </Typography>
          <Divider sx={{ mb: 2 }} />

          {loader && (
            <Box display="flex" justifyContent="center" my={2}>
              <CircularProgress />
            </Box>
          )}

          {pendingRequests.length > 0 ? (
            pendingRequests.map((request) => {
              const requestStatus = request.request || "";

              return (
                <Paper
                  key={request._id}
                  elevation={3}
                  sx={{ mb: 1.5, boxShadow: 2, borderRadius: 1, p: 1.5 }} // Reduced padding, margin, and border radius
                >
                  <CardContent sx={{ paddingBottom: "8px" }}>
                    <Grid container spacing={1}> {/* Reduced grid spacing */}
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2" fontWeight="bold"> {/* Reduced font size */}
                          User Name:
                        </Typography>
                        <Typography variant="body2">{request.name}</Typography> {/* Reduced font size */}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2" fontWeight="bold">
                          Album Name:
                        </Typography>
                        <Typography variant="body2">{request.albumName}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2" fontWeight="bold">
                          Request Status:
                        </Typography>
                        <Typography
                          variant="body2"
                          color={
                            requestStatus === "Accepted"
                              ? "success.main"
                              : requestStatus === "Rejected"
                              ? "error.main"
                              : "text.primary"
                          }
                        >
                          {requestStatus.charAt(0).toUpperCase() +
                            requestStatus.slice(1)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <CardActions
                    sx={{
                      justifyContent: "flex-end",
                      px: 2,
                      py: 0.5, // Reduced padding in actions
                    }}
                  >
                    {requestStatus === "Requested" ? (
                      <div>
                        <Button
                          variant="contained"
                          size="small" // Set button size to small
                          color="success"
                          startIcon={<CheckIcon />}
                          onClick={() =>
                            handleUpdatePermission(request, "Accepted")
                          }
                          sx={{ mr: 1 }}
                        >
                          Accept
                        </Button>
                        <Button
                          variant="contained"
                          size="small" // Set button size to small
                          color="error"
                          startIcon={<CloseIcon />}
                          onClick={() =>
                            handleUpdatePermission(request, "Rejected")
                          }
                        >
                          Reject
                        </Button>
                      </div>
                    ) : (
                      <Typography
                        variant="body2"
                        color={
                          requestStatus === "Accepted"
                            ? "success.main"
                            : "error.main"
                        }
                      >
                        {requestStatus.charAt(0).toUpperCase() +
                          requestStatus.slice(1)}
                      </Typography>
                    )}
                  </CardActions>
                </Paper>
              );
            })
          ) : (
            <Typography align="center" color="text.secondary">
              No pending requests
            </Typography>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default NotificationModal;





// import React, { useState, useEffect } from "react";
// import {
//   IconButton,
//   Modal,
//   Box,
//   Typography,
//   Button,
//   Badge,
//   Backdrop,
//   CircularProgress,
// } from "@mui/material";
// import NotificationsIcon from "@mui/icons-material/Notifications";
// import { useSelector } from "react-redux";
// import {
//   getRequestFromUser,
//   updateUploadPermission,
// } from "../../API/Photographer";
// import { Grid } from "@mui/material";

// const modalStyle = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 800,
//   bgcolor: "background.paper",
//   boxShadow: 24,
//   p: 4,
// };

// const NotificationModal = () => {
//   const [open, setOpen] = useState(false);
//   const [pendingRequests, setPendingRequests] = useState([]);
//   const [loader, setLoader] = useState(false);
//   const [pendingCount, setPendingCount] = useState(0);

//   const profileData = useSelector((state) => state.user);
//   const subOwnerID = profileData?.user?.data?.data?._id;

//   const handleUpdatePermission = async (val, req) => {
//     try {
//       console.log("request", val, req);

//       const data = {
//         albumId: val?.albumId,
//         request: req,
//         subOwnerId: val?.subOwnerId,
//         name: val?.name,
//         id: val?._id,
//       };
//       setLoader(true);
//       const res = await updateUploadPermission(data);
//       fetchNotifications();
//       setLoader(false);

//       console.log("nbb", res);
//     } catch (err) {
//       console.log("dhagfdhasfasda", err);
//     }
//   };

//   useEffect(() => {
//     fetchNotifications();
    
//   }, [subOwnerID]);

//   const fetchNotifications = async () => {
//     if (subOwnerID) {
//       try {
//         setLoader(true);
//         const response = await getRequestFromUser(subOwnerID);
//         console.log("Fetched requests:", response);

//         if (response.data.data) {
//           setPendingRequests(response.data.data);
//           const a = response.data.data.filter((item)=>item.request ==="Requested")
//           setPendingCount(a.length);
//           console.log("Pending requests set:", response.data.data);
//         } else {
//           setPendingCount(0);
//         }

//         setLoader(false);
//       } catch (error) {
//         setLoader(false);
//         console.error("Error fetching notifications:", error);
//       }
//     }
//   };

//   const handleOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => setOpen(false);

//   const handleAction = (requestId, action) => {
//     const updatedRequests = pendingRequests.map((request) =>
//       request._id === requestId ? { ...request, request: action } : request
//     );
//     setPendingRequests(updatedRequests);
//   };

//   return (
//     <div>
//       <IconButton color="primary" onClick={handleOpen}>
//         <Badge
//           badgeContent={pendingCount}
//           color="error"
//           invisible={pendingCount === 0}
//         >
//           <NotificationsIcon />
//         </Badge>
//       </IconButton>

//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="notification-modal-title"
//         aria-describedby="notification-modal-description"
//       >
//         <Box sx={modalStyle}>
//           <Typography id="notification-modal-title" variant="h6" component="h2">
//             User Requests
//           </Typography>
//           <Backdrop
//             sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
//             open={loader}
//             onClick={handleClose}
//           >
//             <CircularProgress color="inherit" />
//           </Backdrop>
//           {pendingRequests.length > 0 ? (
//             pendingRequests.map((request) => {
//               const requestStatus = request.request  || "";

//               return (
//                 <Box key={request._id} sx={{ my: 2 }}>
//                   <Grid container spacing={2} alignItems="center">
//                     <Grid item xs={12} sm={6} md={8}>
//                       <Typography variant="subtitle1">
//                         User Name: {request.name}
//                       </Typography>
//                       <Typography variant="subtitle1">
//                         Album Name: {request.albumName}
//                       </Typography>
//                       <Typography variant="subtitle1">
//                         Request Status: {requestStatus}
//                       </Typography>
//                     </Grid>

//                     <Grid
//                       item
//                       xs={12}
//                       sm={6}
//                       md={4}
//                       container
//                       spacing={1}
//                       justifyContent="flex-end"
//                     >
//                       {requestStatus === "Requested" ? (
//                         <>
//                           <Button
//                             variant="contained"
//                             color="success"
//                             sx={{ mr: 1 }}
//                             onClick={() =>
//                               handleUpdatePermission(request, "Accepted")
//                             }
//                           >
//                             Accept
//                           </Button>
//                           <Button
//                             variant="contained"
//                             color="error"
//                             onClick={() =>
//                               handleUpdatePermission(request, "Rejected")
//                             }
//                           >
//                             Reject
//                           </Button>
//                         </>
//                       ) : (
//                         <Typography
//                           color={requestStatus === "Accepted" ? "green" : "red"}
//                         >
//                           {requestStatus.charAt(0).toUpperCase() +
//                             requestStatus.slice(1)}
//                         </Typography>
//                       )}
//                     </Grid>
//                   </Grid>
//                 </Box>
//               );
//             })
//           ) : (
//             <Typography>No pending requests</Typography>
//           )}
//         </Box>
//       </Modal>
//     </div>
//   );
// };

// export default NotificationModal; 


