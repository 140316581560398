// import React, { useState, useEffect } from 'react';
// import { Box, CircularProgress, Avatar } from '@mui/material';
// import styles from '../../css/AvatarLoader.module.css';
// import { useSelector } from 'react-redux';

// const AvatarLoader = () => {
//   const [seconds, setSeconds] = useState(0);
//   const profileData = useSelector((state) => state.user.user);
//   console.log(profileData , 'profileData');


//   useEffect(() => {
//     const interval = setInterval(() => {
//       setSeconds((prevSeconds) => prevSeconds + 1);
//     }, 1000);

//     return () => clearInterval(interval);
//   }, []);

//   const formatTime = (totalSeconds) => {
//     const minutes = Math.floor(totalSeconds / 60);
//     const seconds = totalSeconds % 60;
//     return `${String(minutes).padStart(2, '0')}m:${String(seconds).padStart(2, '0')}s`;
//   };

//   return (
//     <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
//       <Box
//         position="relative"
//         display="inline-flex"
//         alignItems="center"
//         justifyContent="center"
//       >
//         <CircularProgress
//           variant="determinate"
//           value={100}
//           size={78}
//           thickness={5}
//           style={{ color: '#00C3FF' }}
//         />
//         <CircularProgress
//           variant="indeterminate"
//           disableShrink
//           size={78}
//           thickness={5}
//           style={{
//             color: '#297EFF',
//             position: 'absolute',
//           }}
//         />
//         <Avatar
//           src={profileData.UserImage}
//           alt="Center Image"
//           style={{
//             width: 70,
//             height: 70,
//             position: 'absolute',
//           }}
//         />
//       </Box>
//     </div>


//   );
// };

// export default AvatarLoader;

import React, { useEffect } from 'react';
import { Box, CircularProgress, Avatar } from '@mui/material';
import { useSelector } from 'react-redux';
// import styles from '../../css/AvatarLoader.module.css';

const AvatarLoader = () => {
  // const [seconds, setSeconds] = useState(0);
  const profileData = useSelector((state) => state.user.user);
  console.log(profileData, 'profileData');

  useEffect(() => {
    const interval = setInterval(() => {
      // setSeconds((prevSeconds) => prevSeconds + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // const formatTime = (totalSeconds) => {
  //   const minutes = Math.floor(totalSeconds / 60);
  //   const seconds = totalSeconds % 60;
  //   return `${String(minutes).padStart(2, '0')}m:${String(seconds).padStart(2, '0')}s`;
  // };

  return (
    <Box
      position="absolute"
      top="50%"
      left="50%"
      style={{
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        position="relative"
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress
          variant="determinate"
          value={100}
          size={78}
          thickness={5}
          style={{ color: '#00C3FF' }}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          size={78}
          thickness={5}
          style={{
            color: '#297EFF',
            position: 'absolute',
          }}
        />
        <Avatar
          src={profileData.UserImage}
          alt="Center Image"
          style={{
            width: 70,
            height: 70,
            position: 'absolute',
          }}
        />
      </Box>
    </Box>
  );
};

export default AvatarLoader;

