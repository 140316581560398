import React, { useState } from "react";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";



export default function Menus({ userdata, onclickprofile, setProfile,menu}) {

  // const userType = localStorage.getItem("userType");
  const [anchorEl, setAnchorEl] = useState(null);
  // const navigate = useNavigate();
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getprofileData = () => {
    setProfile(userdata);
  };

  return (
    <>
      <Box sx={{ marginLeft: "auto" }}>
        <IconButton onClick={handleMenuClick}>
          <MoreVertIcon />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ boxShadow: "none" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            width: "31ch",
          },
        }}
      >
        {menu?.map((value,key)=>(
          <MenuItem
          key={key}
          onClick={() => {
            value?.onClick(userdata);
            setAnchorEl(null);
            getprofileData();
          }}
        >
          <Stack flexDirection="row" gap={2}>
            <div style={{ marginLeft: 4 }}>
            {value?.icon}
            </div>
            <div>
              <Typography variant="h6" style={{ color: "black" }}>
               {value?.name}
              </Typography>
            </div>
          </Stack>
        </MenuItem>
        ))}
      </Menu>
    </>
  );
}