

// import React, { useEffect, useState } from 'react';
// import { Link, NavLink, useLocation } from 'react-router-dom';
// import Menu from './adminmenuData';
// import '../../../css/AdmineSidebar.css';
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// function AdminSideBar({ onClik }) {
//     const menuItems = Menu.children;
//     const location = useLocation();
//     const [openSubmenus, setOpenSubmenus] = useState(Array(menuItems.length).fill(false));
  
//     const handleMainMenu = (index) => {
//         //  onClik()
//       setOpenSubmenus((prevOpenSubmenus) => {
//         const newOpenSubmenus = [...prevOpenSubmenus];
//         newOpenSubmenus[index] = !newOpenSubmenus[index];
//         return newOpenSubmenus;
//       });
   
//     };
  
//     const isActiveLink = (url) => {
//       return location.pathname === url;
//     };

//     return (
//       <div className='Sidebar-Body'>
//         {menuItems.map((item, index) => (
//           <div key={item.id}>
//             <div className={isActiveLink(item.url) ? "Sidebar-link":'Sidebar-items'} >
//               <NavLink
//                 to={item.url}
//                 style={{ textDecoration: 'none' }}
//                 onClick={() => handleMainMenu(index)}
//                 className={isActiveLink(item.url) ? "Sidebar-textactive":'Sidebar-textitems'}>
//                 <div>

             
//                 <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between',padding:'10px'}}>
//                   <div >
//                     <span style={{ marginRight: '10px' }}>{item.icon}</span>
//                     <span>{item.title}</span>
//                   </div>
//                   {item.children && (
//                     <span className="submenu-arrow">
//                       {openSubmenus[index] ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
//                     </span>
//                   )}
//                 </div>
//                 </div>
//               </NavLink>
//             </div>
//             {item.children && openSubmenus[index] && (
//               <div>
//                 {item.children.map((subItem, subIndex) => (
//                   <div className={isActiveLink(subItem.url) ? "Sidebar-subitems-link":'Sidebar-subitems'} key={subItem.id}>
//                     <NavLink to={subItem.url} style={{ textDecoration: 'none' }}>
//                       <div>
//                         <div>
//                           <span>{subItem.icon}</span>
//                           <span>{subItem.title}</span>
//                         </div>
//                       </div>
//                     </NavLink>
//                   </div>
//                 ))}
//               </div>
//             )}
//           </div>
//         ))}
//       </div>
//     );
//   }
  
//   export default AdminSideBar;
  
import React, { useEffect, useState } from 'react';
import {  NavLink, useLocation } from 'react-router-dom';
import Menu from './adminmenuData';
import '../../../css/AdmineSidebar.css';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function AdminSideBar({ onClik }) {
    const menuItems = Menu.children;
    const location = useLocation();
    const [openSubmenus, setOpenSubmenus] = useState(() => {
        // Retrieve openSubmenus state from localStorage, or initialize it to all false
        const storedState = localStorage.getItem('openSubmenus');
        return storedState ? JSON.parse(storedState) : Array(menuItems.length).fill(false);
    });

    useEffect(() => {
        // Save openSubmenus state to localStorage whenever it changes
        localStorage.setItem('openSubmenus', JSON.stringify(openSubmenus));
    }, [openSubmenus]);

    const handleMainMenu = (index) => {
        setOpenSubmenus((prevOpenSubmenus) => {
            const newOpenSubmenus = [...prevOpenSubmenus];
            newOpenSubmenus[index] = !newOpenSubmenus[index];
            return newOpenSubmenus;
        });
    };

    const isActiveLink = (url) => {
        return location.pathname === url;
    };

    return (
        <div className='Sidebar-Body'  >
            <div style={{marginTop:"20px"}}>
            {menuItems.map((item, index) => (
                <div key={item.id}  >
                    <div className={isActiveLink(item.url) ? "Sidebar-link" : 'Sidebar-items'} >
                        <NavLink
                            to={item.url}
                            style={{ textDecoration: 'none' }}
                            onClick={() => handleMainMenu(index)}
                            className={isActiveLink(item.url) ? "Sidebar-textactive" : 'Sidebar-textitems'}>
                            <div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px', color:"white" }}>
                                    <div  >
                                        <span style={{ marginRight: '10px'}}>{item.icon}</span>
                                        <span>{item.title}</span>
                                    </div>
                                    {item.children && (
                                        <span className="submenu-arrow">
                                            {openSubmenus[index] ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    {item.children && openSubmenus[index] && (
                        <div>
                            {item.children.map((subItem, subIndex) => (
                                <div className={isActiveLink(subItem.url) ? "Sidebar-subitems-link" : 'Sidebar-subitems'} key={subItem.id}>
                                    <NavLink to={subItem.url} style={{ textDecoration: 'none',color:"white" }}>
                                        <div>
                                            <div>
                                                <span>{subItem.icon}</span>
                                                <span>{subItem.title}</span>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
        </div>
    );
}

export default AdminSideBar;



