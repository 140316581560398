import React from "react";
import { Button, Typography, Stack } from "@mui/material";
// import EmailIcon from '@mui/icons-material/Email';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Link, Link as RouterLink } from "react-router-dom";

//try

function LoginStep({ onEmailClick, onGoogleSuccess, onGoogleFailure, onGoogleClick }) {
  // const clientId = 
  // // '279620147034-ece3pi24f9a8u42gpl5qnmb7sv9povq3.apps.googleusercontent.com'; 
  // '134499163860-jaorfbkoei1acde625dm4s8aegli500h.apps.googleusercontent.com';

  return (
    <Stack 
      marginTop={8}
      direction="column" 
      spacing={4} 
      alignItems="center" 
      // sx={{ padding: "40px", backgroundColor: "#f5f5f5", borderRadius: "12px", boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.1)" }}
    >
      <Typography 
        variant="h5" 
        align="center" 
        sx={{ fontWeight: "bold", color: "#333", marginBottom: "20px" }}
      >
        Welcome 
      </Typography>

      <Button
        variant="contained"
        fullWidth
        startIcon={<MailOutlineIcon />}
        sx={{
          // backgroundColor: "#1E3CFF",
          color: "#fff",
          padding: "12px 20px",
          borderRadius: "8px",
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
          transition: "transform 0.3s ease, box-shadow 0.3s ease",
          '&:hover': {
            // backgroundColor: "#1B36E3",
            transform: "translateY(-3px)",
            boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.2)",
          },
        }}
        onClick={onEmailClick}
      >
        Continue with Email
      </Button>

      {/* <GoogleLogin
        clientId={clientId}
        render={renderProps => (
          <Button
            fullWidth
            variant="outlined"
            startIcon={<GoogleIcon sx={{ color: "#DB4437" }} />}
            sx={{
              borderColor: "#DB4437",
              color: "#DB4437",
              padding: "12px 20px",
              borderRadius: "8px",
              backgroundColor: "#fff",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              '&:hover': {
                transform: "translateY(-3px)",
                boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.2)",
              },
            }}
            onClick={onGoogleClick}
            // disabled={renderProps.disabled}
            // cookiePolicy={'single_host_origin'}

          >
            Sign in with Google
          </Button>
        )}
        onSuccess={onGoogleSuccess}
        onFailure={onGoogleFailure}
        cookiePolicy={'single_host_origin'}
      /> */}

<Typography style={{ marginTop: 40, textAlign:'justify' }}>
                By accepting to Register on our platform, you agree that you
                have read and accepted our &nbsp;
                <Link
                  style={{ fontSize: 13 }}
                  component={RouterLink}
                  to="https://findmypik.com/terms-of-service.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </Link>
                ,
                &nbsp; and
                <Link
                  style={{ fontSize: 13 }}
                  component={RouterLink}
                  to="https://findmypik.com/privacy-policy.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                &nbsp;  Privacy Policy
                </Link>
              </Typography>
    </Stack>
  );
}


export default LoginStep;