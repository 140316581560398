import React, { useEffect, useState, useCallback } from "react";
// import { useLocation, useNavigate } from "react-router";
import { DownloadImage, getAllImages } from "../API/Photographer";
import { Alert, Box,
  //  Button,
    CircularProgress, IconButton, Modal, Snackbar, Stack } from "@mui/material";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import DownloadIcon from '@mui/icons-material/Download';
import SystemUpdateAltOutlinedIcon from "@mui/icons-material/SystemUpdateAltOutlined";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ZoomInOutlinedIcon from "@mui/icons-material/ZoomInOutlined";
import ZoomOutOutlinedIcon from "@mui/icons-material/ZoomOutOutlined";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
// import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
// import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import FilterOutlinedIcon from "@mui/icons-material/FilterOutlined";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import "../css/view.css";
import { Clear } from "@mui/icons-material";
// import axios from "axios";

function ViewImages({ deleteData, closeDeleteImage, eventData }) {

  console.log("Evensdvsdvwegvwes:", eventData);

  const isMobile = window.innerWidth <= 750;

  // const location = useLocation();
  const eventDatas = deleteData;
  console.log("ReceivezxcscsdacscViewImages:", eventDatas);

  const [currentPage, setCurrentPage] = useState(eventDatas?.currentPage);
  const [event,] = useState(eventDatas.filename);
  const [activeImage, setActiveImage] = useState(eventDatas?.Url);
  const [images, setImages] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  // const [imagesPerPage, setImagesPerPage] = useState(10);
  // const [availImage, setAvailImage] = useState("");
  // const [originalName, setOriginalName] = useState(eventDatas.originalName);
  // const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success"); // "success" or "error"
  const [loading, setLoading] = useState(false); // Add loading state

  const getAllCarouselImages = useCallback(async () => {
    try {
      const status = "allPhotos";
      const response = await getAllImages(status, currentPage, event);
      const newImages = response?.data?.data?.uploads || [];
      setImages(newImages);
      setTotalPage(response.data.Pages.totalPage);
      // setAvailImage(response.data.Pages.imagessofthePage);
    } catch (err) {
      setErrorMessage("Error fetching images.");
      setSnackbarType("error");
      setOpenSnackbar(true);
    }
  }, [currentPage, event]);

  useEffect(() => {
    getAllCarouselImages();
  }, [getAllCarouselImages]);

  const handlePrevious = async () => {
    try {
      const currentIndex = images.findIndex(
        (image) => image.fileUrl === activeImage
      );
      if (currentIndex > 0) {
        setActiveImage(images[currentIndex - 1].fileUrl);
        // setOriginalName(images[currentIndex - 1].fileName);
      } else if (currentPage === 1 && currentIndex === 0) {
        setCurrentPage(totalPage);
        const response = await getAllImages("allPhotos", totalPage, event);
        const newImages = response?.data?.data?.uploads || [];
        setImages(newImages);
        setActiveImage(newImages[newImages.length - 1]?.fileUrl);
        // setOriginalName(newImages[newImages.length - 1]?.fileName);
        // setAvailImage(response.data.Pages.imagessofthePage);
      } else if (currentPage > 1 && currentIndex === 0) {
        setCurrentPage(currentPage - 1);
        const response = await getAllImages("allPhotos", currentPage - 1, event);
        const newImages = response?.data?.data?.uploads || [];
        setImages(newImages);
        setActiveImage(newImages[newImages.length - 1]?.fileUrl);
        // setOriginalName(newImages[newImages.length - 1]?.fileName);
        // setAvailImage(response.data.Pages.imagessofthePage);
      }
    } catch (err) {
      setErrorMessage("Error loading previous image.");
      setSnackbarType("error");
      setOpenSnackbar(true);
    }
  };

  // const handleForward = async () => {
  //   try {
  //     const currentIndex = images.findIndex(
  //       (image) => image.fileUrl === activeImage
  //     );
  //     if (currentIndex < imagesPerPage - 1 && currentIndex < images.length - 1) {
  //       setActiveImage(images[currentIndex + 1].fileUrl);
  //       setOriginalName(images[currentIndex + 1]?.fileName);
  //     } else if (currentPage < totalPage) {
  //       setCurrentPage((prevPage) => prevPage + 1);
  //       const response = await getAllImages("allPhotos", currentPage + 1, event);
  //       const newImages = response?.data?.data?.uploads || [];
  //       setImages(newImages);
  //       setActiveImage(newImages[0]?.fileUrl);
  //       setOriginalName(newImages[0]?.fileName);
  //       setAvailImage(response.data.Pages.imagessofthePage);
  //     } else if (currentPage === totalPage) {
  //       setCurrentPage(1);
  //       const response = await getAllImages("allPhotos", 1, event);
  //       const newImages = response?.data?.data?.uploads || [];
  //       setImages(newImages);
  //       setActiveImage(newImages[0]?.fileUrl);
  //       setOriginalName(newImages[0]?.fileName);
  //       setAvailImage(response.data.Pages.imagessofthePage);
  //     }
  //   } catch (err) {
  //     setErrorMessage("Error loading next image.");
  //     setSnackbarType("error");
  //     setOpenSnackbar(true);
  //   }
  // };

  const handleDownload = async (datas) => {
    setLoading(true); // Start loader
    try {
      const urlss = datas.split('/');
      const lastTwoIndices = urlss.slice(-2);
      let uri = lastTwoIndices.join('/');
      let modifiedUri = uri;
      console.log("kkkkkkkkk",uri);

      if (uri.includes('%20')) {
        modifiedUri = uri.replace(/%20/g, ' ');
      }
      console.log("urlssurlss", modifiedUri);

      const data = { key: modifiedUri };
      console.log('url1data', datas);
      const res = await DownloadImage(data);
      const fileName = 'download_divine_images';
      const imageData = new Uint8Array(res.data.Body.data);
      // const contentType = res.ContentType;
      const imageBlob = new Blob([imageData.buffer], { type: "image/jpeg" });
      const url = window.URL.createObjectURL(imageBlob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setSuccessMessage("Image downloaded successfully.");
      setSnackbarType("success");
      setOpenSnackbar(true);
    } catch (err) {
      setErrorMessage("Error downloading image.");
      setSnackbarType("error");
      setOpenSnackbar(true);
    } finally {
      setLoading(false); // Stop loader
    }
  };


  const uploadImages = () => {
    closeDeleteImage(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Modal open={true}>
      <div
        style={{
          position: "relative",
          backgroundColor: "rgba(251, 253, 255, 1)",
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: isMobile ? 0 : 65,
          marginInline: 5,
          flexDirection: isMobile ? "column" : "row",
          overflowY: isMobile ? "auto" : "hidden",
        }}
      >


        <Clear
          className="view-Cancel"
          sx={{ fontSize: "30px", color: "black" }}
          onClick={uploadImages}
        />

        <ArrowBackIosNewIcon
          onClick={handlePrevious}
          sx={{
            position: "absolute",
            left: 0,
            top: "50%",
            transform: "translateY(-50%)",
            color: "white",
          }}
        />

        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            height: "100%",
            width: isMobile ? "70%" : "100%",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: isMobile ? "100%" : "60%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(251, 253, 255, 1)",
              position: "relative",
            }}
          >

            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              action={
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleCloseSnackbar}
                >
                  <Clear fontSize="small" />
                </IconButton>
              }
            >
              <Alert
                onClose={handleCloseSnackbar}
                severity={snackbarType}
                sx={{ width: "100%" }}
              >
                {errorMessage || successMessage}
              </Alert>
            </Snackbar>


            <TransformWrapper
              defaultScale={1}
              defaultPositionX={1}
              defaultPositionY={1}
            >
              {({ zoomIn, zoomOut }) => (
                <>
                  <TransformComponent>
                    <img
                      src={activeImage}
                      alt="images"
                      style={{
                        maxWidth: "100%",
                        maxHeight: isMobile ? "70vh" : "90vh",
                      }}
                    />
                  </TransformComponent>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: isMobile ? "row" : "column",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "5px",
                      padding: "10px",
                      position: isMobile ? "absolute" : "relative",
                      bottom: isMobile ? "-50px" : "auto",
                      left: isMobile ? "50px" : "auto",
                      right: isMobile ? "auto" : "0px",
                      top: isMobile ? "auto" : "10px",
                    }}
                  >
                    <button
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "none",
                        cursor: "pointer",
                        padding: "8px",
                        borderRadius: "5px",
                        marginBottom: isMobile ? "0" : "30px",
                        marginRight: isMobile ? "30px" : "-1px",
                        fontSize: isMobile ? "18px" : "24px",
                      }}
                      onClick={() => zoomIn()}
                    >
                      <ZoomInOutlinedIcon
                        sx={{ fontSize: isMobile ? "18px" : "24px", color: "#007BFF" }}
                      />
                    </button>

                    <button
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "none",
                        cursor: "pointer",
                        padding: "8px",
                        borderRadius: "5px",
                        fontSize: isMobile ? "18px" : "24px",
                      }}
                      onClick={() => zoomOut()}
                    >
                      <ZoomOutOutlinedIcon
                        sx={{ fontSize: isMobile ? "18px" : "24px", color: "#007BFF" }}
                      />
                    </button>
                  </div>
                </>
              )}
            </TransformWrapper>

          </div>

          <div
            style={{
              width: isMobile ? "100%" : "40%",
              marginTop: isMobile ? "10px" : "20px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              padding: "20px",
              color: "black",
              backgroundColor: "#fff",
              margin: isMobile ? "0" : "20px",
            }}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <FilterOutlinedIcon />
              <div>
                <h4 style={{ color: "black", marginTop: "20px" }}>
                  Album Details
                </h4>
              </div>
            </Stack>

            {images.length > 0 && (
              <ul
                style={{
                  listStyleType: "none",
                  paddingLeft: 0,
                  marginBottom: "20px",
                }}
              >
                {(() => {
                  const currentImage = images.find(
                    (img) => img.Images.highResolution === activeImage || img.Images.highResolution === activeImage
                  );
                  if (!currentImage) return null;

                  return (
                    <>
                      <Box mb={2}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <div
                            style={{
                              fontWeight: "bold",
                              fontSize: isMobile ? "16px" : "19px",
                              color: "#007BFF",
                            }}
                          >
                            {eventData.name}
                          </div>
                          <div
                            style={{
                              fontWeight: "bold",
                              fontSize: isMobile ? "16px" : "19px",
                              color: "#007BFF",
                            }}
                          >
                            |
                          </div>
                          <div
                            style={{
                              fontWeight: "bold",
                              fontSize: isMobile ? "16px" : "19px",
                              color: "#007BFF",
                            }}
                          >
                            {eventData.venueAlias}
                          </div>
                        </Stack>
                      </Box>
                      <Box mb={2}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <FmdGoodOutlinedIcon />
                          <div
                            style={{
                              fontWeight: 400,
                              fontSize: isMobile ? "12px" : "14px",
                              color: "#425466",
                            }}
                          >
                            {eventData.area}, {eventData.city}
                          </div>
                        </Stack>
                      </Box>
                      <Box mb={2}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <WatchLaterOutlinedIcon />
                          <div
                            style={{
                              fontSize: isMobile ? "12px" : "14px",
                              fontWeight: 400,
                              color: "#425466",
                            }}
                          >
                            {formatDate(eventData.eventDate)}
                          </div>
                        </Stack>
                      </Box>
                    </>
                  );
                })()}
              </ul>
            )}
            <div
              style={{
                position: "relative",
                bottom: isMobile ? 0 : -40,
                right: 0,
                display: "flex",
                alignItems: "center",
                padding: "10px",
                marginTop: "20px",

              }}
            >

              
              <button
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#007bff",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  padding: "10px 20px",
                  boxSizing: "border-box",
                  color: "#fff",
                  fontWeight: "bold",
                  position: "absolute",
                  bottom: 30,
                  right: 30,
                  // Add relative positioning to make sure loader and text align properly
                 
                }}
                onClick={() => handleDownload(activeImage)}
                disabled={loading} // Disable button while loading
              >
                {loading ? (
                  <CircularProgress
                    size={24}
                    style={{ color: "white", marginRight: "10px" }}
                  />
                ) : (
                  <SystemUpdateAltOutlinedIcon
                    sx={{ fontSize: "24px", color: "#fff" }}
                  />
                )}
                <span style={{ marginLeft: "10px" }}>
                  {loading ? "Downloading..." : "Download Image"}
                </span>
              </button>            </div>
          </div>
        </div>
      </div>
    </Modal>

  );
}

export default ViewImages;
