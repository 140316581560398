// import React, { useEffect, useState } from 'react'
// import Box from '@mui/material/Box';
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import myImage from "../../../images/profile.jpg"
// import { GetEventApi, getAllImages, getCountImagesUpload, getProfileApi, updateProfileApi } from '../../../API/Photographer'
// import { Grid, Card, Typography, Button, TextField, Stack, Divider, InputLabel, Alert, CircularProgress } from '@mui/material';
// import { CameraAlt, CameraEnhance, CropOriginal, LocalMall, Padding, Person, ShoppingBasket, Store } from '@mui/icons-material';
// import SubscriptionPay from '../../payment'
// import { getEventListApi } from '../../../API/Admin';
// import AddUser from './addUser';
// export default function Profile() {
//  const [open, setOpen] = useState(false);
//  const [selectedItem, setSelectedItem] = useState("profile");
//  const [profileData, setProfileData] = useState([]);
//  const [errorMessage, setErrorMessage] = useState('');
//  const [totalImages, setTotalImages] = useState('a');
//  const [status, setStatus] = useState('');
//  const [loader, setLoader] = useState(false);
//  const [subscribeMessage,setSubscribeMessage ] = useState('');
//  const [totalAlbums, setTotalAlbums] = useState({
//    private:'',
//    public:''
//  });
//  const [userData,setUserData] = useState({
//     name:'',
//     email:'',
//     phone:'',
//     location:'',
//     studioName:''

//  });

//  const toggleDrawer = (newOpen) => () => {
//     setOpen(newOpen);
//  };

//  const handleItemClick = (item) => {
//     setSelectedItem(item);
//     toggleDrawer(false)(); // Close the drawer
//  };

//  const handleChange=(e)=>{
//   setErrorMessage("")
//   const {name,value}=e.target
//   setUserData({
//     ...userData,
//     [name]:value
//   })
//  }

//  const getProfile=async()=>{

//   try {
//     // setLoader(true)
//    const response=await getProfileApi()
//    setProfileData(response?.data?.data)
//    const datas=response?.data?.data
//    setUserData({
//     name:datas.name,
//     email:datas.email,
//     phone:datas.phone,
//     location:datas.location,
//     studioName:datas.studioName
//    })
//   //  setLoader(false)

//    console.log("response",response)
//   } catch (error) {

//   }
// }

// const subscriptionStatus =()=>{

// }

// const updateProfile= async()=>{
//     if(userData.name ===''){
//       setErrorMessage("Name is required")
//     }
//     else if(userData.email===''){
//       setErrorMessage("Email is required")
//     }

//     else if(userData.phone===''){
//       setErrorMessage("Phone number is required")
//     }

//     // else if(userData.studioName ===''){
//     //   setErrorMessage("Studio Name is required")
//     // }

//     // else if(userData.location ===''){
//     //   setErrorMessage("Location is required")
//     // }
//     else{
//       try {
//         const response= await updateProfileApi(userData)
//         console.log("response",response)
//         setErrorMessage(response?.data?.Message)
//         setStatus(response?.status)
//         setTimeout(()=>{
//           setErrorMessage('')
//         },2000)

//        } catch (error) {
//         console.log("error",error)
//        }

//     }

// }

// const getAlbumList = async () => {
//   try {
//      const response = await GetEventApi();
//      console.log("Res", response);
//      let totalPrivate = [];
//      let totalPublic = [];

//      response?.data?.data?.forEach((item) => {
//        console.log(item.eventType);
//        if (item.eventType === "Private") {
//          totalPrivate.push(item.eventType);
//        } else {
//          totalPublic.push(item.eventType);
//        }
//      });
//      setTotalAlbums({
//        private:totalPrivate?.length,
//        public:totalPublic.length
//      })

//      console.log("Test", totalPrivate, totalPublic);
//      console.log("Test", totalPrivate.length, totalPublic.length);
//   } catch (error) {
//      console.log("error", error);
//   }
//  };

// let total=0;
// profileData?.SubscriptionHistory?.forEach((item)=>
//   total+=item.creadits
// )

// console.log("loader",loader)
// const getAllImage=async()=>{
//     try {
//       const response= await getCountImagesUpload()
//       console.log("response123",response)
//       setTotalImages(response?.data?.data)
//     } catch (error) {

//     }
// }
// useEffect(()=>{
//   getProfile()
//   getAlbumList()
//   getAllImage()
// },[loader])
//  console.log("userData",profileData)

//  const subscriptionMessage=(data)=>{

//   console.log("data",data)
//   // setSubscribeMessage(data)
//   setLoader(data)

//  }

//  return (
//     <div style={{ marginTop: 10,backgroundColor:"#F5F5F5",paddingBottom:5}}>

//       <Grid container direction={"row"} spacing={30}>
//         <Grid item md={2}>
//           <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
//             <List>
//               {['Profile', 'Subscription Plan',"Add User"].map((text, index) => (
//                 <ListItem key={text} disablePadding>
//                  {selectedItem === text.toLowerCase().replace(' ', '') && (
//                     <Card sx={{ width: 240 }}>
//                       <ListItemButton onClick={() => handleItemClick(text.toLowerCase().replace(' ', ''))}>
//                         <ListItemIcon>
//                           {index % 2 === 0 ? <Person/> : <LocalMall />}
//                         </ListItemIcon>
//                         <ListItemText primary={text} />
//                       </ListItemButton>
//                     </Card>
//                  )}
//                  {selectedItem !== text.toLowerCase().replace(' ', '') && (
//                     <ListItemButton onClick={() => handleItemClick(text.toLowerCase().replace(' ', ''))}>
//                       <ListItemIcon>
//                         {index % 2 === 0 ? <Person /> : <LocalMall />}
//                       </ListItemIcon>
//                       <ListItemText primary={text} />
//                     </ListItemButton>
//                  )}
//                 </ListItem>
//               ))}
//             </List>
//           </Box>
//         </Grid>

//         <Grid item xs={12} md={10}>
//           {selectedItem === 'profile' && (
//             <>
//               {errorMessage ? <Alert severity={status === 200 ? "success" : "error"} style={{zIndex:2}}>{errorMessage}</Alert>:null }
//             <Card elevation={3} style={{borderRadius:5, minHeight:490,overflowY:"auto",maxHeight:490}}>
//               <Grid container>
//               <Grid item  md={6 } padding={2}>
//                 <Box>
//                 <img src={profileData?.imageUrl?profileData?.imageUrl:myImage} style={{width:150,height:150,borderRadius:20}} />

//                 </Box>
//                 <div style={{marginTop:10,}}>
//                 <Divider/>
//                 </div>
//                <Stack gap={1}>
//                 <Stack style={{marginTop:10,}}gap={0.5}>
//                 <InputLabel>Name <span style={{color:"red"}}>*</span></InputLabel>
//                 <TextField fullWidth onChange={handleChange} name='name' value={userData?.name}/>
//                 </Stack>

//                 <Stack gap={0.5}>
//                 <InputLabel>Email  <span style={{color:"red"}}>*</span></InputLabel>
//                 <TextField fullWidth onChange={handleChange} name='email' value={userData?.email}/>
//                 </Stack>

//                 <Stack gap={0.5}>
//                 <InputLabel>Phone Number  <span style={{color:"red"}}>*</span></InputLabel>
//                 <TextField fullWidth onChange={handleChange} name='phone' value={userData?.phone}/>
//                 </Stack>

//                 {/* <Stack gap={0.5}>
//                 <InputLabel>Studio Name  <span style={{color:"red"}}>*</span></InputLabel>
//                 <TextField fullWidth onChange={handleChange} name='studioName' value={userData?.studioName}/>
//                 </Stack>

//                 <Stack gap={0.5}>
//                 <InputLabel>Location  <span style={{color:"red"}}>*</span></InputLabel>
//                 <TextField fullWidth onChange={handleChange} name='location' value={userData?.location}/>
//                 </Stack> */}

//                 <Stack mt={2}>
//                   <Button variant='outlined' onClick={updateProfile}>Update</Button>
//                 </Stack>

//                 </Stack>

//              </Grid>
//              <Grid item md={6} style={{padding:10}}>
//               <Typography style={{marginTop:175}}>Credits utilization</Typography>
//               <Card elevation={3}   style={{width:"75%",marginTop:5}} >
//                 <Stack style={{padding:35}} direction={"row"} gap={1}>
//                 <Store fontSize='large' color='secondary'/>
//                 <Typography style={{marginTop:5}} > <span style={{fontWeight:"bold"}}>{profileData?.subscriptionLimit}</span> of  <span style={{fontWeight:"bold"}}>{total}</span> Credits </Typography>
//                 </Stack>
//               </Card>

//               <Typography style={{marginTop:20}}>Albums</Typography>
//               <Card elevation={3}   style={{width:"75%",marginTop:5}}>
//                 <div style={{padding:23 ,marginLeft:18}}>
//                 <Stack  direction={"row"} gap={1}>
//                 <CameraAlt fontSize='large' color='secondary'/>
//                 <Typography style={{marginTop:5}} > <span style={{fontWeight:"bold"}}>{totalAlbums?.private}</span> Private Albums</Typography>
//                 </Stack>

//                 <Stack  direction={"row"} gap={1}>
//                 <CameraEnhance fontSize='large' color='secondary'/>
//                 <Typography  style={{marginTop:5}}><span style={{fontWeight:"bold"}}>{totalAlbums?.public}</span> Public Albums </Typography>
//                 </Stack>
//                 </div>
//               </Card>

//               <Typography style={{marginTop:20}}>Uploaded Images</Typography>
//               <Card elevation={3}   style={{width:"75%",marginTop:5}} >
//                 <Stack style={{padding:35}} direction={"row"} gap={1}>
//                 <CropOriginal fontSize='large' color='secondary'/>
//                 <Typography style={{marginTop:5}} > <span style={{fontWeight:"bold"}}>{totalImages}</span> images </Typography>
//                 </Stack>
//               </Card>

//              </Grid>

//               </Grid>

//             </Card>
//             </>
//           )}
//           {selectedItem === 'subscriptionplan' && (
//            <Card elevation={3} style={{overflow:"auto", minHeight:490,paddingInline:35,borderRadius:5,overflowY:"auto",maxHeight:490}}>
//             {  loader ?
//               <div style={{display:"flex",justifyContent:"center",marginTop:10}}>
//                   <CircularProgress/>
//               </div>

//               :
//               <>
//              <Grid container  spacing={2}  marginTop={2}>
//            <Grid item  md={5} padding={2}  >

//            {
//               Array.isArray(profileData?.SubscriptionHistory) && profileData?.SubscriptionHistory.map((item, index)=>
//               <Card  key={index} elevation={2} style={{height:65,backgroundColor:"#DCDCDC",marginTop:5,padding:3 }} >
//               <div style={{display:'flex',justifyContent:"space-between"}}>
//                    <Typography style={{fontWeight:"bold"}}> {item?.name} </Typography>
//                    <Typography  style={{fontWeight:"bold"}}>{item?.amount}{item?.amount==="Free"?null:"/-"}</Typography>

//                </div>
//                <Typography>You can upload <span style={{fontWeight:"bold"}}>{item?.creadits}</span>  images </Typography>

//            </Card>
//               )
//             }

//           </Grid>
//           <Grid item md={7} >
//             <div style={{border:'1px solid', borderColor:"#B0C4DE", paddingInline:5, paddingBlock:19 ,width:400 ,borderRadius:10}}>
//               <SubscriptionPay subscriptionMessage={subscriptionMessage}  subscriptionStatus={subscriptionStatus} />
//             </div>

//           </Grid>

//            </Grid>
//               </>

//             }

//          </Card>
//           )}

//             {selectedItem === 'adduser' && (
//            <Card elevation={3} style={{overflow:"auto", minHeight:490,paddingInline:35,borderRadius:5,overflowY:"auto",maxHeight:490}}>

//           <div>
//           <AddUser/>
//           </div>

//          </Card>
//           )}
//         </Grid>
//       </Grid>
//     </div>
//  );
// }

// import React, { useEffect, useState } from "react";
// import Box from "@mui/material/Box";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemButton from "@mui/material/ListItemButton";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import ListItemText from "@mui/material/ListItemText";
// import myImage from "../../../images/profile.jpg";
// import {
//   GetEventApi,
//   getAllImages,
//   getCountImagesUpload,
//   getProfileApi,
//   updateProfileApi,
// } from "../../../API/Photographer";
// import {
//   Grid,
//   Card,
//   Typography,
//   Button,
//   TextField,
//   Stack,
//   Divider,
//   InputLabel,
//   Alert,
//   CircularProgress,
//   Avatar,
// } from "@mui/material";
// import {
//   CameraAlt,
//   CameraEnhance,
//   CropOriginal,
//   LocalMall,
//   Person,
//   Store,
// } from "@mui/icons-material";
// import SubscriptionPay from "../../payment";
// import { getEventListApi } from "../../../API/Admin";
// import AddUser from "./addUser";
// import { useDispatch, useSelector } from "react-redux";
// import { updateProfileRequest } from "../../../Store/action";

// export default function Profile() {
//   const [open, setOpen] = useState(false);
//   const [selectedItem, setSelectedItem] = useState("profile");
//   const [profileData, setProfileData] = useState([]);
//   const [errorMessage, setErrorMessage] = useState("");
//   const [totalImages, setTotalImages] = useState("a");
//   const [status, setStatus] = useState("");
//   const [loader, setLoader] = useState(false);
//   const [editImage, setEditImage] = useState({
//     file: "",
//     imageUrl: "",
//   });
//   const [totalAlbums, setTotalAlbums] = useState({
//     private: "",
//     public: "",
//   });
//   const [userData, setUserData] = useState({
//     name: "",
//     email: "",
//     phone: "",
//     location: "",
//     studioName: "",
//   });

//   const toggleDrawer = (newOpen) => () => {
//     setOpen(newOpen);
//   };

//   const handleItemClick = (item) => {
//     setSelectedItem(item);
//     toggleDrawer(false)(); // Close the drawer
//   };

//   const handleChange = (e) => {
//     setErrorMessage("");
//     const { name, value } = e.target;
//     setUserData({
//       ...userData,
//       [name]: value,
//     });
//   };

//   const dispatch = useDispatch();
//   const handleImageChange = async (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       // Handle image upload logic here
//       console.log("Selected file:", file);
//       const imageUrl = URL.createObjectURL(file);
//       console.log("imageUrl", imageUrl);

//       setEditImage((prev) => ({
//         ...prev,
//         file: file,
//         imageUrl: imageUrl,
//       }));
//       // You can use FormData to send the image to your server
//     }
//   };

//   const getProfile = async () => {
//     try {
//       const response = await getProfileApi();
//       setProfileData(response?.data?.data);
//       const datas = response?.data?.data;
//       setUserData({
//         name: datas.name,
//         email: datas.email,
//         phone: datas.phone,
//         location: datas.location,
//         studioName: datas.studioName,
//       });
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const subscriptionStatus = () => { };

//   const updateProfile = async () => {
//     if (userData.name === "") {
//       setErrorMessage("Name is required");
//     } else if (userData.email === "") {
//       setErrorMessage("Email is required");
//     } else if (userData.phone === "") {
//       setErrorMessage("Phone number is required");
//     } else {
//       try {
//         console.log("UserData", userData);
//         const files = editImage.file;
//         console.log("file", files);
//         const formData = new FormData();
//         formData.append("userData", JSON.stringify(userData));
//         if (files) {
//           console.log("File123", files);
//           formData.append("file", files, files.name);
//         }

//         console.log("Test1");
//         const response = await updateProfileApi(formData);
//         console.log("Test");
//         console.log("response", response);
//         setErrorMessage(response?.data?.Message);
//         setStatus(response?.status);
//         if (response?.status === 200) {
//           dispatch(updateProfileRequest());
//         }
//         setTimeout(() => {
//           setErrorMessage("");
//           setStatus("");
//         }, 2000);
//       } catch (error) {
//         console.log("error", error);
//       }
//     }
//   };

//   const getAlbumList = async () => {
//     try {
//       const response = await GetEventApi();
//       let totalPrivate = [];
//       let totalPublic = [];

//       response?.data?.data?.forEach((item) => {
//         if (item.eventType === "Private") {
//           totalPrivate.push(item.eventType);
//         } else {
//           totalPublic.push(item.eventType);
//         }
//       });
//       setTotalAlbums({
//         private: totalPrivate?.length,
//         public: totalPublic.length,
//       });
//     } catch (error) {
//       console.log("error", error);
//     }
//   };

//   let total = 0;
//   profileData?.SubscriptionHistory?.forEach((item) => (total += item.creadits));

//   const getAllImage = async () => {
//     try {
//       const response = await getCountImagesUpload();
//       setTotalImages(response?.data?.data);
//     } catch (error) {
//       console.log(error);setLoader
//     }
//   };

//   useEffect(() => {
//     getProfile();
//     getAlbumList();
//     getAllImage();
//   }, [loader]);

//   const subscriptionMessage = (data) => {
//     setLoader(data);
//   };

//   const profileReduxData = useSelector((state) => state.user);
//   console.log("profileReduxData", profileReduxData);
//   return (
//     //     <>
//     //       <Box
//     //         width="100vw"
//     //         height="95px"
//     //         backgroundColor="rgb(237,250,255);
//     // "
//     //       ></Box>
//     //       <Box display="flex" height="calc(100vh - 95px)" bgcolor="#FAFDFF">
//     //         {/* Sidebar */}
//     //         <Box width="270px" bgcolor="#FAFDFF" p={2}>
//     //           <Box display="flex" flexDirection="column" gap={2} width={"159px"}
//     //             height={"36px"}
//     //             sx={{
//     //               borderRadius: "5px",
//     //               backgroundColor: "#E4F4FF",
//     //               paddingTop: "10px",
//     //               textTransform: "none",
//     //               fontSize: "18px",
//     //               marginLeft: "40px"
//     //             }} >
//     //             <Button>
//     //               <Typography
//     //                 textTransform={"none"}
//     //               >
//     //                 My Profile
//     //               </Typography>
//     //             </Button>
//     //             <Button >
//     //               <Typography textTransform={"none"} >Add User</Typography>
//     //             </Button>
//     //           </Box>
//     //         </Box>

//     //         {/* Profile Details */}
//     //         <Box flex={1} p={4} bgcolor="#fff">
//     //           <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
//     //             <Box display="flex" alignItems="center">
//     //               <Box
//     //                 sx={{
//     //                   fontFamily: 'Poppins, Arial, sans-serif',
//     //                   width: 100,
//     //                   height: 100,
//     //                   borderRadius: '50%',
//     //                   backgroundColor: '#EAF3FF',
//     //                   display: 'flex',
//     //                   justifyContent: 'center',
//     //                   alignItems: 'center',
//     //                   p: 'px',
//     //                   m: 2,
//     //                   position: "relative",
//     //                   // top: "10px"

//     //                 }}
//     //               ></Box>
//     //               <Box height={"54px"} width={"286px"}>
//     //                 <Typography style={{

//     //                   fontSize: "20px",
//     //                   // fontWeight: "400",
//     //                   fontWeight: "bold",
//     //                   color: "#3974CB"
//     //                 }} >
//     //                   UserName
//     //                 </Typography>
//     //                 <Typography style={{
//     //                   fontSize: "16px",
//     //                   color: "gray"
//     //                 }}>
//     //                   exampleemail@exampleemail.com
//     //                 </Typography>
//     //               </Box>
//     //             </Box>
//     //             <Button style={{
//     //               backgroundColor: "#3974CB",
//     //               color: "#fff",
//     //               fontSize: "16px",
//     //               width: "98px",
//     //               height: "44px",
//     //               borderRadius: "5px"
//     //             }}>Edit</Button>
//     //           </Box>

//     //           <Box display="flex" flexDirection="column" gap={2}>
//     //             <Stack spacing={2} direction="column" justifyContent="space-between" width={"592px"} height={"52px"}>
//     //               <Typography>
//     //                 Full Name
//     //               </Typography>
//     //               <TextField
//     //                 placeholder="Enter"
//     //                 InputProps={{
//     //                   style: {
//     //                     backgroundColor: "#F9F9F9",
//     //                     border: "none" // Remove border
//     //                   },
//     //                   disableUnderline: true // Remove underline
//     //                 }}
//     //                 InputLabelProps={{
//     //                   style: {
//     //                     display: 'none' // Hide label
//     //                   }
//     //                 }}
//     //               />
//     //               <Typography>
//     //                 Number
//     //               </Typography>
//     //               <TextField
//     //                 placeholder="Enter"
//     //                 InputProps={{
//     //                   style: {
//     //                     backgroundColor: "#F9F9F9",
//     //                     border: "none" // Remove border
//     //                   },
//     //                   disableUnderline: true // Remove underline
//     //                 }}
//     //                 InputLabelProps={{
//     //                   style: {
//     //                     display: 'none' // Hide label
//     //                   }
//     //                 }}
//     //               />
//     //               <Typography>
//     //                 Email
//     //               </Typography>
//     //               <TextField
//     //                 placeholder="Enter"
//     //                 InputProps={{
//     //                   style: {
//     //                     backgroundColor: "#F9F9F9",
//     //                     border: "none" // Remove border
//     //                   },
//     //                   disableUnderline: true // Remove underline
//     //                 }}
//     //                 InputLabelProps={{
//     //                   style: {
//     //                     display: 'none' // Hide label
//     //                   }
//     //                 }}
//     //               />
//     //             </Stack>
//     //           </Box>
//     //         </Box>
//     //       </Box>
//     //     </>

//     <div style={{ display: "flex", marginTop: 20, justifyContent: "space-between", backgroundColor: "#F5F5F5", height: "100vh", }}>
//       <Grid container direction={"row"} spacing={15}>
//         <Grid item md={2}>
//           <Box sx={{ width: 200 }} role="presentation" onClick={toggleDrawer(false)}>
//             <List >
//               {['Profile', "Add User"].map((text, index) => (
//                 <ListItem key={text} disablePadding>
//                   {selectedItem === text.toLowerCase().replace(' ', '') && (
//                     <Card sx={{ width: 240 }}>
//                       <ListItemButton onClick={() => handleItemClick(text.toLowerCase().replace(' ', ''))}>
//                         <ListItemIcon>
//                           {index % 2 === 0 ? <Person /> : <LocalMall />}
//                         </ListItemIcon>
//                         <ListItemText primary={text} />
//                       </ListItemButton>
//                     </Card>
//                   )}
//                   {selectedItem !== text.toLowerCase().replace(' ', '') && (
//                     <ListItemButton onClick={() => handleItemClick(text.toLowerCase().replace(' ', ''))}>
//                       <ListItemIcon>
//                         {index % 2 === 0 ? <Person /> : <LocalMall />}
//                       </ListItemIcon>
//                       <ListItemText primary={text} />
//                     </ListItemButton>
//                   )}
//                 </ListItem>
//               ))}
//             </List>
//           </Box>
//         </Grid>

//         <Grid item xs={12} md={10}>
//           {selectedItem === 'profile' && (
//             <>
//               <Card elevation={3} style={{ borderRadius: 5, minHeight: 490, overflowY: "auto", maxHeight: 490 }}>
//                 <Box onClick={() => document.getElementById('image-upload').click()}>
//                   <img src={editImage.imageUrl ? editImage.imageUrl : (profileData?.imageUrl ? profileData.imageUrl : myImage)} style={{ width: 150, height: 150, borderRadius: 20 }} />
//                 </Box>
//                 <input
//                   type="file"
//                   id="image-upload"
//                   style={{ display: 'none' }}
//                   onChange={handleImageChange}
//                 />
//                 <div style={{ marginTop: 10 }}>
//                   <Divider />
//                 </div>

//                 <Grid container>
//                   <Grid item md={6} padding={2}>
//                     {errorMessage ? <Alert severity={status === 200 ? "success" : "error"} style={{ zIndex: 2 }}>{errorMessage}</Alert> : null}

//                     <Stack gap={3}>
//                       <Stack style={{ marginTop: 10 }} gap={0.5}>
//                         <InputLabel>Name<span style={{ color: "red" }}>*</span></InputLabel>
//                         <TextField fullWidth onChange={handleChange} name='name' value={userData?.name} />
//                       </Stack>

//                       <Stack gap={0.5}>
//                         <InputLabel>Email<span style={{ color: "red" }}>*</span></InputLabel>
//                         <TextField fullWidth onChange={handleChange} name='email' value={userData?.email} disabled />
//                       </Stack>
//                     </Stack>
//                   </Grid>
//                   <Grid item md={6} style={{ padding: 10 }}>
//                     <Stack gap={0.5} style={{ marginTop: 15 }}>
//                       <InputLabel>Phone Number <span style={{ color: "red" }}>*</span></InputLabel>
//                       <TextField fullWidth onChange={handleChange} name='phone' value={userData?.phone} />
//                     </Stack>
//                   </Grid>
//                   <Stack style={{ display: "flex", position: "absolute", bottom: 200, right: 90 }}>
//                     <Stack mt={2}>
//                       <Button variant='outlined' onClick={updateProfile} style={{ width: 200 }}>Update</Button>
//                     </Stack>
//                   </Stack>
//                 </Grid>
//               </Card>
//             </>
//           )}
//           {selectedItem === 'adduser' && (
//             <Card elevation={3} style={{ overflow: "auto", minHeight: 490, paddingInline: 35, borderRadius: 5, overflowY: "auto", maxHeight: 490 }}>
//               <AddUser />
//             </Card>
//           )}
//         </Grid>
//       </Grid>
//     </div>
//   );
// }
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  // Grid,
  Card,
  Typography,
  Button,
  TextField,
  Stack,
  Alert,
  // CircularProgress,
  Modal,
  Backdrop,
  InputAdornment
} from "@mui/material";
import myImage from "../../../images/profile.jpg";
import {
  GetEventApi,
  compareUserFace,
  // getAllImages,
  // getCountImagesUpload,
  getProfileApi,
  updateProfileApi,
} from "../../../API/Photographer";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileRequest } from "../../../Store/action";
import AddUser from "./addUser";
// import { uploadAndCheckFace } from "../../../API/Photographer";
import EditIcon from '@mui/icons-material/Edit';
import { updateProfileImage } from "../../../Store/reducer.jsx";


export default function Profile() {
  // const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("profile");
  const [profileData, setProfileData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  // const [totalImages, setTotalImages] = useState("a");
  const [status, setStatus] = useState("");
  const [loader,] = useState(false);
  const [activeButton, setActiveButton] = useState("");
  // const [openImageModal, setOpenImageModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage the visibility of the modal
  const [isEditing, setIsEditing] = useState(false);
  // const [message, setMessage] = useState({ text: "", type: "" });



  const [editImage, setEditImage] = useState({
    file: "",
    imageUrl: "",
  });
  // const [totalAlbums, setTotalAlbums] = useState({
  //   private: "",
  //   public: "",
  // });
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
    location: "",
    studioName: "",
  });
  const handleEditClick = () => {
    if (isEditing) {
      updateProfile();
    }
    setIsEditing(!isEditing);
  };

  const toggleDrawer = (newOpen) => () => {
    // setOpen(newOpen);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    toggleDrawer(false)(); // Close the drawer
    setActiveButton(item);
  };

  const handleChange = (e) => {
    setErrorMessage("");
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };



  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };



  const dispatch = useDispatch();


  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setEditImage((prev) => ({
        ...prev,
        file: file,
        imageUrl: imageUrl,
      }));
    }
  };

  const getProfile = async () => {
    try {
      const response = await getProfileApi();
      setProfileData(response?.data?.data);
      const datas = response?.data?.data;
      setUserData({
        name: datas.name,
        email: datas.email,
        phone: datas.phone,
        location: datas.location,
        studioName: datas.studioName,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const updateProfile = async () => {
    if (userData.name === "") {
      setErrorMessage("Name is required");
    } else if (userData.email === "") {
      setErrorMessage("Email is required");
    } else if (userData.phone === "") {
      setErrorMessage("Phone number is required");
    } else {
      try {
        let faceCheckPassed = true;
  
        if (editImage && editImage.file) {
          // Upload the face image and check for Gurudev's face
          const faceFormData = new FormData();
          faceFormData.append("files", editImage.file);
          faceFormData.append("url", profileData?.imageUrl)
          console.log("Uploading face image...");
  
          const uploadResponse = await compareUserFace (faceFormData);
  
          console.log("Upload response:", uploadResponse);
  
          if (uploadResponse.data.result.status === true) {
            setErrorMessage(uploadResponse.data.result.message);
            faceCheckPassed = false;
          }
        }
  
        if (faceCheckPassed) {
          // Proceed with updating the profile
          const formData = new FormData();
          formData.append("userData", JSON.stringify(userData));
  
          if (editImage && editImage.file) {
            formData.append("file", editImage.file, editImage.file.name);
          } else {
            // Append the existing image URL if no new image is provided
            formData.append("existingImage", userData.imageUrl);
          }
  
          console.log("Updating profile with formData:", formData);
  
          const response = await updateProfileApi(formData);
  
          console.log("Profile update response:", response);
  
          setErrorMessage(response?.data?.Message || "");
          setStatus(response?.status || "");
          if (response?.status === 200) {
            dispatch(updateProfileRequest());
            dispatch(updateProfileImage(editImage?.imageUrl || profileData?.imageUrl)); 
            setIsEditing(false);
            // setMessage({ text: "Profile updated successfully!", type: "success" });
          }
          setTimeout(() => {
            setErrorMessage("");
            setStatus("");
          }, 3000);
        }
      } catch (error) {
        console.error("Error while updating profile:", error);
        if (!(editImage && editImage.file)) {
          setErrorMessage(error.response?.data?.message || "An error occurred while updating the profile.");
        }
      }
    }
  };



  const getAlbumList = async () => {
    try {
      const response = await GetEventApi();
      let totalPrivate = [];
      let totalPublic = [];

      response?.data?.data?.forEach((item) => {
        if (item.eventType === "Private") {
          totalPrivate.push(item.eventType);
        } else {
          totalPublic.push(item.eventType);
        }
      });
      // setTotalAlbums({
      //   private: totalPrivate?.length,
      //   public: totalPublic.length,
      // });
    } catch (error) {
      console.log("error", error);
    }
  };

  let total = 0;
  profileData?.SubscriptionHistory?.forEach((item) => (total += item.creadits));

  const getAllImage = async () => {
    try {
      // const response = await getCountImagesUpload();
      // setTotalImages(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProfile();
    getAlbumList();
    getAllImage();
  }, [loader]);

  // const subscriptionMessage = (data) => {
  //   setLoader(data);
  // };

  const profileReduxData = useSelector((state) => state.user);
  console.log("profileReduxData", profileReduxData);

  return (
    <>
      {/* <Box width="100%" height="95px" backgroundColor="rgb(237,250,255)"></Box> */}
      <Box marginTop={"70px"} display="flex" height="calc(100vh - 95px)" bgcolor="#FAFDFF">
        {/* Sidebar */}
        <Box width={{ xs: '100%', sm: '220px' }} bgcolor="#FAFDFF">
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            width={{ xs: '100%', sm: '159px' }}
            height="36px"
            sx={{
              borderRadius: "5px",
              paddingTop: "8px",
              textTransform: "none",
              fontSize: "18px",
              marginLeft: { xs: '0', sm: '40px' },
            }}
          >
            <Button
              onClick={() => handleItemClick("profile")}
              sx={{
                backgroundColor: activeButton === "profile" ? "#E4F4FF" : "transparent",
                '&:hover': {
                  backgroundColor: activeButton === "profile" ? "#D0E9F7" : "#E4F4FF",
                },
              }}
            >
              <Typography textTransform="none">My Profile</Typography>
            </Button>
            <Button
              onClick={() => handleItemClick("adduser")}
              sx={{
                backgroundColor: activeButton === "adduser" ? "#E4F4FF" : "transparent",
                '&:hover': {
                  backgroundColor: activeButton === "adduser" ? "#D0E9F7" : "#E4F4FF",
                },
              }}
            >
              <Typography textTransform="none">Add User</Typography>
            </Button>
          </Box>
        </Box>

        {/* Profile Details */}
        <Box flex={1} p={{ xs: 2, sm: 4 }} bgcolor="#fff">
          {selectedItem === "profile" && (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={4}
                flexDirection={{ xs: "column", sm: "row" }}
              >
               <Box display="flex" alignItems="center" flexWrap="wrap">
      <Box
        onClick={isEditing ? () => document.getElementById("image-upload").click() : handleImageClick}
        sx={{
          fontFamily: "Poppins, Arial, sans-serif",
          width: { xs: 80, sm: 100 },
          height: { xs: 80, sm: 100 },
          borderRadius: "50%",
          backgroundColor: "#EAF3FF",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          position: "relative",
          m: 2,
        }}
      >
        <img
          src={editImage?.imageUrl || profileData?.imageUrl || myImage}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          alt="Profile"
        />
        {isEditing && (
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              backgroundColor: "#fff",
              borderRadius: "50%",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            <EditIcon style={{ fontSize: "18px", color: "#3974CB" }} />
          </Box>
        )}
      </Box>

      <input
        type="file"
        id="image-upload"
        accept=".jpg, .jpeg" // Added accept attribute
        style={{ display: "none" }}
        disabled={!isEditing}
        onChange={handleImageChange}
      />

      <Box
        sx={{
          height: { xs: "auto", sm: "54px" },
          width: { xs: "auto", sm: "286px" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          m: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "16px", sm: "20px" },
            fontWeight: "bold",
            color: "#3974CB",
          }}
        >
          {profileData?.username || userData?.name}
        </Typography>
        <Typography sx={{ fontSize: { xs: "14px", sm: "16px" }, color: "gray" }}>
          {profileData?.email || "exampleemail@exampleemail.com"}
        </Typography>
      </Box>
    </Box>

                <Modal
                  open={isModalOpen}
                  onClose={handleCloseModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    style: {
                      backgroundColor: 'transparent', // Set the backdrop to transparent
                      cursor: "pointer"
                    },
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100vh"
                    onClick={handleCloseModal}
                  >
                    <img
                      src={editImage?.imageUrl || profileData?.imageUrl || myImage}
                      style={{ maxWidth: "90%", maxHeight: "90%", cursor: "pointer" }}
                      alt="Profile"
                      onClick={(e) => e.stopPropagation()} // Prevent click event from bubbling up to the Box
                    />
                  </Box>
                </Modal>
              </Box>

              {errorMessage && (
                <Alert
                  severity={status === 200 ? "success" : "error"}
                  sx={{ zIndex: 2, width: { xs: "90%", sm: "40%" }, marginBottom: "20px" }}
                  onClose={() => setErrorMessage('')}
                >
                  {errorMessage}
                </Alert>
              )}
              <Box display="flex" flexDirection="column" gap={2}>
                <Stack
                  spacing={2}
                  direction="column"
                  justifyContent="space-between"
                  width="100%"
                  sx={{ maxWidth: { sm: '500px'} }}
                  >
                  <Typography>Full Name</Typography>
                  <TextField
                    placeholder="Enter"
                    InputProps={{
                      sx: {
                        backgroundColor: "#F9F9F9",
                        border: "none",
                        width: "100%",
                        maxWidth: { xs: '200px', sm: '400px' },

                      },
                      disableUnderline: true,
                      endAdornment: isEditing && (
                        <InputAdornment position="end">
                          <EditIcon />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      style: {
                        display: "none",
                      },
                    }}
                    name="name"
                    value={userData?.name}
                    onChange={handleChange}
                    disabled={!isEditing}
                  />
                  <Typography>Number</Typography>
                  <TextField
                    placeholder="Enter"
                    InputProps={{
                      sx: {
                        backgroundColor: "#F9F9F9",
                        border: "none",
                        width: "100%",
                        maxWidth: { xs: '200px', sm: '400px' },

                      },
                      disableUnderline: true,
                      endAdornment: isEditing && (
                        <InputAdornment position="end">
                          <EditIcon />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      style: {
                        display: "none",
                      },
                    }}
                    name="phone"
                    value={userData?.phone}
                    onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, ''); 
                        if (value.length <= 10) { 
                            setUserData({
                                ...userData,
                                phone: value
                            });
                        }
                    }} 
                    disabled={!isEditing}
                  />
                  <Typography>Email</Typography>
                  <TextField
                    placeholder="Enter"
                    InputProps={{
                      sx: {
                        backgroundColor: "#F9F9F9",
                        border: "none",
                        width: "100%",
                        maxWidth: { xs: '200px', sm: '400px' },

                      },
                      disableUnderline: true,
                    }}
                    InputLabelProps={{
                      style: {
                        display: "none",
                      },
                    }}
                    name="email"
                    value={userData?.email}
                    onChange={handleChange}
                    disabled
                  />
                  <Button
                    style={
                      isEditing
                        ? {
                          marginTop: "30px",
                          backgroundColor: "#fff",
                          color: "#3974cb",
                          border: "2px solid #3974cb",
                          fontSize: "16px",
                          width: "98px",
                          height: "44px",
                          borderRadius: "5px",
                        }
                        : {
                          marginTop: "30px",
                          backgroundColor: "#3974CB",
                          color: "#fff",
                          fontSize: "16px",
                          width: "98px",
                          height: "44px",
                          borderRadius: "5px",
                        }
                    }
                    sx={{
                      marginTop: "30px",
                      backgroundColor: isEditing ? "#fff" : "#3974CB",
                      color: isEditing ? "#3974cb" : "#fff",
                      border: isEditing ? "2px solid #3974cb" : "none",
                      fontSize: "16px",
                      width: { xs: "100%", sm: "98px" },
                      height: "44px",
                      borderRadius: "5px",
                    }}
                    onClick={handleEditClick}
                  >
                    {isEditing ? "Update" : "Edit"}
                  </Button>
                </Stack>
              </Box>
            </>
          )}
          {selectedItem === "adduser" && (
            <Card
              elevation={0}
              sx={{
                overflow: "auto",
                minHeight: 490,
                borderRadius: 5,
                overflowY: "auto",
                p: { xs: 2, sm: 4 },
              }}
            >
              <AddUser />
            </Card>
          )}
        </Box>
      </Box>
    </>
  );
}
