import React, { useEffect, useState, useCallback } from "react";
import {
  Typography,
  TableCell,
  CircularProgress,
  useTheme, useMediaQuery, Stack,
  Modal,
  Box,
  Button,
  IconButton,
  Tooltip,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  
} from "@mui/material";

import myImage from "../../../images/nodata.png"

import {
  TableCom,
  THead,
  TCell,
  TBody,
  Trow,
} from "../../Component/table"
import { DeletePhotographerApi, getphotographerList, Photographerdelete } from "../../../API/Admin";
import { SearchField } from "../../Component/SerachField";
// import { useNavigate } from "react-router";
import AddPhotographer from "./AddPhotographer";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const PhotographerList = () => {
  const [photographerData, setPhotographerData] = useState([]);
  const [metaPage, setmetaPage] = useState(1);
  // const [profile,setProfile] = useState('');
  const [propsStatus,setPropsStatus] = useState('');
  const [searchData,setsearchData] = useState('');
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('sm'));
  const [page, setPage] = useState(1);
  const [skeliton, setskeliton] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [photographerId] = useState('');


  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);


  // const navigate=useNavigate()


  const getPotgrapherData = useCallback(async () => {
    const data = {
      page,
      searchData
    }
    setskeliton(true)   
    try {
      console.log("ddddfgfdd",data)
      const response = await getphotographerList(data)
      console.log(response , 'getphotographerListgetphotographerList');
      console.log("Totalpage",response?.data?.pagination?.totalPage);
      
      setPhotographerData(response?.data?.data)
      setmetaPage(response?.data?.pagination?.totalPage)
      console.log("response",response)
      
      setskeliton(false)
    } catch (error) {
      console.log("error", error);
    }
  }, [page, searchData]);



  // const DeletePhotographer = async () => {
  //   try {
  //     const response = await DeletePhotographerAp(photographerId);
  //     console.log("response", response);
  //     if (response?.status === 200) {
  //       handleCloseDelete();
  //       getPhotographerData();
  //     }
  //     console.log("response", response);
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };



  const useDebouncedValue = (inputValue, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(inputValue);
  
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(inputValue);
      }, delay);
  
      return () => {
        clearTimeout(handler);
      };
    }, [inputValue, delay]);
  
    return debouncedValue;
  };

  const debouncedSearchTerm = useDebouncedValue(searchData, 1500);


  useEffect(() => {
    getPotgrapherData()
  }, [page,debouncedSearchTerm,propsStatus, getPotgrapherData]);



  const goToPreviousPage = () => {
    console.log("Prev")
    setPage(page - 1);
  };

  const goToNextPage = async () => {
    try {
      const nextPage = page + 1;
      setPage(nextPage);
    } catch (error) {
      //console.log("error", error);
    }
  };

  
  const headers = [
    // "Action",
    "Name",
    "Email",
    "Phone Number",
     "Action",
    // "Number",
    // "Upload Limits",
    // "Registered",
    
      
  ];

const [edit, setEdit]=useState('');
const [edits, setEdits]=useState(false);
  const handleEditClick = (data) => {
    setEdit(data);
    setEdits(true);

    console.log('Edit button clicked');
  };

  const editClose=()=>{
    setEdits(false);
    setEdit('');
  }


  const handleClickOpen = (id) => {
    setSelectedId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedId(null);
  };


  const handleConfirmDelete = async () => {
    try {
      const response = await Photographerdelete(selectedId);
      console.log("response", response);
      if (response?.status === 200) {
        handleClose();
        getPotgrapherData()
      }
    } catch (error) {
      console.error("Error in deletePhoto:", error);
    }
  };



  
  const deletePhotographerApi = async() => {
      try {
        const response =await DeletePhotographerApi(photographerId) 
        console.log("response",response)
        if(response?.status===200){
          handleCloseDelete()
          getPotgrapherData()
        }
        console.log("response",response)
        
      } catch (error) {
        console.log("error",error)
      }

  };



  const handleCloseDelete = () => {
    setOpenDelete(false);
 };

//  const handleOpenDelete = () => {
//     setOpenDelete(true);
//  };

  // const deletePhotographer=(data)=>{
  //   setphotographerId(data?._id)
  //   setOpenDelete(true);
  //   // handleOpenDelete()

  // }


  // const albumCreated=(data)=>{
  //   console.log("data",data)
  //   const id=data._id

  //   navigate("/photographerDetailes",{state:{id}})
    

  // }
  // const menu = [
  //   // { name: "Employee List", icon: <Group/>, onClick: employeeList },
  //   // { name: 'Copy Referral Link', icon: <ContentCopyIcon />, onClick: handleCopylink },
  //   { name: "Album created List", icon: <Group/>,  onClick:albumCreated},
  //   { name: 'Delete ', icon: <Group/>,onClick:deletePhotographer},
    
  
  // ]



  const styled = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
    paddingBottom:5
 };



 const searchProps=(data)=>{
    console.log("data",data)
    setsearchData(data)
 }


//  const calculateTotalCredits = (subscriptionHistory) => {
//   let total = 0;
//   subscriptionHistory?.forEach((item) => {
//      total += item.creadits;
//   });
//   return total;
//  };


 const responseStatus=(data)=>{
  setPropsStatus(data)
 }

  return (
    <div  style={{marginTop:10,marginInline: isMobileOrTablet ?20:5 }}>
      <AddPhotographer responseStatus={responseStatus} handleEdit={edits} handleC={editClose} editData={edit}/>
      
      <>
        {skeliton ? (
       
        <div style={{display:'flex',justifyContent:"center"}}>
            <CircularProgress color="inherit" />
        </div>
       
        ) : photographerData?.length === 0|| photographerData===undefined ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
               <img src={myImage} alt="No data available" />
          </div>
        ) : (
          <>
          
            <SearchField searchProps={searchProps} placeholder="Search Name,Email"/>
            
              <div style={{marginTop:10}}>

        <Modal
          open={openDelete}
          onClose={handleCloseDelete}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styled}>
            <Typography>Are you sure you want to delete this Photographer?</Typography>
            <div style={{position:'absolute',right:20}}>
            <Button onClick={handleCloseDelete}>No</Button>
            <Button onClick={deletePhotographerApi}>Yes</Button>
            </div>
           
          </Box>
        </Modal>
            
              <TableCom>
                <THead>

                  {headers?.map((header, index) => (
                    <TableCell
                      key={index}
                      style={{
                        borderRight: "1px solid white",
                        paddingRight: "10px",
                        fontWeight: "40%",
                        fontSize: "100%",
                        color: "aliceblue",
                        backgroundColor: "#0C2142"
                      }}
                    >
                      {header}
                    </TableCell>

                  ))}
                </THead>

             
                <TBody>
                  {photographerData?.map((value, index) => (

                    <Trow key={index} >
                      
                        {/* <TCell>

                           
                        <Menus
                          menu={menu}
                          userdata={value}
                          types={"skill"}
                          setProfile={setProfile}
                        />
                        </TCell> */}



                      <TCell>
                       <Typography>{value.name ? value.name:"N/A"}</Typography>
          
                      </TCell>
                     
                      <TCell>
                      <Typography>{value.email? value.email:"N/A"}</Typography>
                      </TCell>

                      <TCell>
                      <Typography>{value?.number}</Typography>
                      </TCell>

                                
                      <TCell>





<div>
      
      <Box display="flex" alignItems="center">
        <Tooltip title="Delete">
          <IconButton style={{ color: '#0C2142', marginRight: '8px' }} onClick={() => handleClickOpen(value?._id)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Edit">
          <IconButton style={{ color: '#0C2142' }} onClick={()=>handleEditClick(value)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Box>

    
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this photographer?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </div>

    </TCell>
  
  
  
  {/*  
                      <TCell>
                      <Typography>{value?.location? value?.location:"N/A"}</Typography>
                      </TCell> */}

                      {/* 
                      <TCell>
                      <Typography>{value?.phone? value?.phone:"N/A"}</Typography>
                      </TCell>


                      <TCell>
                   
                      <Typography>{value?.subscriptionLimit? value?.subscriptionLimit:"N/A"} /{calculateTotalCredits(value?.SubscriptionHistory)}
                       </Typography>
                      </TCell>

                      <TCell>
                      <Typography>{ value?.createdAt? value?.createdAt.split('T')[0]:"N/A" }</Typography>
                      </TCell> */}
                      
                      
                    </Trow>
                  ))}
                </TBody>
              </TableCom>
              </div>

              <Stack
                direction="row"
                spacing={2}
                justifyContent={"flex-end"}
                style={{
                  marginTop: "5px",
                  marginBottom: "3px",
                  marginRight: "5px",
                }}
              >
                <Typography style={{ paddingTop: "5px", fontSize: "16px" }}>
                  Total Page : {metaPage}
                </Typography>
                <Button
                  style={{ width: "35px" , }}
                  onClick={goToPreviousPage}
                  disabled={page === 1}
                  variant="outlined"
                >
                  Prev..
                  </Button>
                <Typography style={{ paddingTop: "5px", fontSize: "16px" }}>
                  {page}
                </Typography>
                
                  <Button
                  style={{ width: "35px" , }}
                  onClick={goToNextPage}
                  disabled={page === metaPage}
                
                  variant="outlined"
                >
                  Next
                  </Button>
              </Stack>
            
          </>
        )}
      </>
             

    </div>
  );
};

export default PhotographerList;