import React, { useState, useEffect, useRef, 
  useCallback 
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
// import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Tabs from "@mui/material/Tabs";
// import AddIcon from "@mui/icons-material/Add";
import Tab from "@mui/material/Tab";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
// import TextField from "@mui/material/TextField";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import {
  Alert,
  Avatar,
  // Backdrop,
  Card,
  CircularProgress,
  // InputLabel,
  // Pagination,
  Stack,
  // setRef,
  FormHelperText,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
} from "@mui/material";
// import useDrivePicker from "react-google-drive-picker";
import {
  // DownloadImage,
  deleteImageApi,
  // fileCheking,
  getAllImages,
  // faceDetectedImagesApi,
  uploadImage,
  // getProfileApi,
  // getDetectedHighImages,
  // getDetectedLowImages,
  // UploadDetedImages,
  GetUploadDectedImages,
  UploadProgress,
  MakeFavouritefrontApi,
  checkAlbumPermissionFront,
  sendRequestMail,
  // getRequestFromUser,
  // updateUploadPermission,
} from "../API/Photographer";
import { Grid } from "@mui/material";
import { useLocation,
  //  useNavigate 
  } from "react-router";
import "../css/Drive.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "../css/Gallary.css";
import {
  //  useDispatch,
   useSelector } from "react-redux";
// import { updateUserImage } from "../Store/action";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import albummm from "../../src/images/photoalbum-icon.svg";
import locaTIIIION from "../../src/images/location-2-icon.svg";
import timmmmm from "../../src/images/date-time-slot-2-icon.svg";
import gallery from "../../src/images/create-album-icon.svg";
import { CloseCircleTwoTone } from "@ant-design/icons";
import Avrcard from "../Screen/Component/Avrcard";
import AvatarLoader from "../Screen/Component/AvatarLoader";
import AvatarDetectLoader from "../Screen/Component/AvatarDetectLoader";
import { getaddedUser } from "../API/Auth";
import DeleteImages from "../Screen/deleteImage";
import ViewImages from "../Screen/viewImages";
import SubscriptionPay from "../Screen/payment";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { getFavoritesApi } from "../API/Photographer";

// import LazyLoadedImage from './component/lazyLoadImage';

export default function AllAlbumImages() {
  const [open, setOpen] = useState(false);
  // const navigator = useNavigate();
  // const [selectedFile, setSelectedFile] = useState();
  const [uploadPercentage, setuploadpercentage] = useState("");
  const [selectedAvatar, setSelectedAvatar] = useState();
  const [finalPage, setFinalPage] = useState("");
  const [selectedUrl, setSelectedUrl] = useState([]);
  const [deleteOpen, setdeleteOpen] = useState(false);
  const [deleteData, setdeleteData] = useState("");
  const [ownPhoto, setOwnPhoto] = useState([]);
  // const [myPhotos] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [uploadedCount, setUploadedCount] = useState(0);
  const [detectedCount, setDetectedCount] = useState(0);
  const [renderKey] = useState(0);
  const [errorMessage, setErrorMessage] = useState();
  // const [ownPhotoExist, setOwnPhotoExist] = useState("");
  const [status, setStatus] = useState();
  const [finalResponseData, setfinalResponseData] = useState("");
  // const [highImageData, setHighImagesData] = useState("");
  // const [isDetctedLoder, setisDetctedLoder] = useState(false);
  const [detectedImages, ] = useState([]);
  const [finaldetectedImages, setFinaldetectedImages] = useState([]);
  const [finalImagesLoader, setfinalImagesLoader] = useState(false);
  const location = useLocation();
  const fetchImagesRef = useRef(false);
  const dialogContentRef = useRef(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [favoritePhotos, setFavoritePhotos] = useState([]);
  const [favoritePhotoscount, setFavoritePhotoscount] = useState(0);
  const [openPermissionModal, setOpenPermissionModal] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [favoriteImages, setFavoriteImages] = useState([]);
  const [loadingFavorites, setLoadingFavorites] = useState(false);


  console.log("uploadedCount",uploadedCount)

  const profileData = useSelector((state) => state.user);

  console.log("profileData12jjjj335", profileData);
  // const uservId = profileData?.user?.UserId;
  const subOwnerID = profileData?.user?.data.data._id;


console.log(uploadPercentage)
console.log(uploadPercentage)


console.log("createrIdcreaterId",location?.state?.eventProps?.subOwnerID);


  const sendRequest = async () => {
    const data = {
      albumId: location?.state?.eventProps?._id,
      createrId: location?.state?.eventProps?.createrId,
      request: "Requested",
      email: location?.state?.eventProps?.createdByEmail,
    };

    try {
      const response = await sendRequestMail(data);
      fetchAlbumPermission();
      console.log("request response", response);
      setOpenPermissionModal(false);
      
      setSnackbarMessage("Request sent successfully!");
      setSnackbarOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };


const [subOwnerPermission, setSubOwnerPermission]=useState();
const [req, setReq]=useState(false);

  const fetchAlbumPermission = useCallback(async () => {
    try {
      const albumId = location?.state?.eventProps?._id;
      const subOwnerId = subOwnerID;

      console.log("albumId:", albumId);
      console.log("subOwnerId:", subOwnerId);

      // setloader(true);

      const response = await checkAlbumPermissionFront(albumId, subOwnerId);
     
      // setloader(false);
      console.log("jjjjggg:", location?.state?.eventProps?.createrId, subOwnerID);
      if(response?.response?.status === 404){
        if(location?.state?.eventProps?.createrId === subOwnerID){
          console.log("qq allow");
          setSubOwnerPermission(true);
        }else{

        console.log("qq Don't allow");
        setSubOwnerPermission(false);
      }
      }else {
        if(response?.data?.data?.request === "Accepted"){
          console.log("qq allow");
          setSubOwnerPermission(true)
        }else{

          
          console.log("qq Don't allow");
        setReq(true);
        setSubOwnerPermission(false);
        }
      }
    
    } catch (error) {
      // setloader(false);
      setSubOwnerPermission(false);
      console.error("Error fetching images:", error);
    }
  }, [location?.state?.eventProps, subOwnerID]);


  useEffect(() => {
    fetchAlbumPermission();
    // getRequestTOOwner();
  }, [fetchAlbumPermission]);

  const handleOpen = () => {
    console.log("kk",subOwnerPermission);
    
  if(subOwnerPermission ===true){
    setOpenPermissionModal(false);
    setOpen(true);
  }else{
    setOpen(false);
    setOpenPermissionModal(true);

  }
  
  };


  const handleFavoriteClick = async (photoId, isFavourite) => {
    try {
      // Optimistically update the UI
      if (favoritePhotos.includes(photoId)) {
        setFavoritePhotos(favoritePhotos.filter((id) => id !== photoId));
      } else {
        setFavoritePhotos([...favoritePhotos, photoId]);
      }

      // Make API call
      const response = await MakeFavouritefrontApi({
        id: photoId,
        favourite: !isFavourite,
        albumId: location?.state?.eventProps?._id
      });

      if (response?.status === 200) {
        // Update favorites count and list
        getFavoriteImages();
        
        // Update the favorite status in selectedUrl array
        setSelectedUrl(prevUrls => 
          prevUrls.map(url => 
            url._id === photoId 
              ? { ...url, Favorite: { ...url.Favorite, status: !isFavourite }}
              : url
          )
        );
      } else {
        // Revert UI change if API call fails
        if (favoritePhotos.includes(photoId)) {
          setFavoritePhotos(favoritePhotos.filter((id) => id !== photoId));
        } else {
          setFavoritePhotos([...favoritePhotos, photoId]);
        }
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
      // Revert UI change on error
      if (favoritePhotos.includes(photoId)) {
        setFavoritePhotos(favoritePhotos.filter((id) => id !== photoId));
      } else {
        setFavoritePhotos([...favoritePhotos, photoId]);
      }
    }
  };

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const toggleCheckboxes = () => {
    setShowCheckboxes((prevState) => !prevState);
  };

  const handleSelectImage = (imageId) => {
    setSelectedImages((prevSelectedImages) =>
      prevSelectedImages.includes(imageId)
        ? prevSelectedImages.filter((id) => id !== imageId)
        : [...prevSelectedImages, imageId]
    );
  };

  const isImageSelected = (imageId) => selectedImages.includes(imageId);

  const eventData = location.state?.eventProps;
  //console.log("eventDAtaaa", eventData);
  // const imageUrl = location.state?.imageUrl;
  const [uploadProgress, setUploadProgress] = useState(0);

  const [userData, setUserData] = useState({
    getUser: [],
    userProfile: {},
  });

  // const dispatch = useDispatch();
  // const eventTab = location?.state;
  // const eventTabs = navigator?.state?.eventTab;
  // state: states
  console.log("Receiveduserdata:", location.state);

  




 
  useEffect(() => {
    getAllAddedUser();
  }, []);
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("sm"));
  //console.log("eventData",eventData)
  //const handleOpen = () => setOpen(true);

  // const [value, setValue] = useState(0);
  const [values, setValues] = useState(0);
  // const [loader, setloader] = useState(false);

  const [loaderScroll, setloaderScroll] = useState(false);
  // const [loaderForModel, setloderForModel] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobileOrTablet ? 300 : 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };

  console.log(values, "valuesssss");
  console.log(detectedImages, "valuesdetectedImagesssss");
  console.log(ownPhoto, "ownPhoto");

  //----------------Google Drive picker------------------//

  // const [openPicker, authResponse] = useDrivePicker();
  // const [authToken, setAuthToken] = useState(authResponse?.access_token);
  // let authToken = authResponse?.access_token;
  // localStorage.setItem("ggToken", authResponse?.access_token);

  const subscriptionMessage = () => {};

  // useEffect(() => {
  //   authToken = authResponse?.access_token;
  // }, [authResponse]);

  // const handleOpenPicker = () => {
  //   const pickerCallback = (data) => {
  //     if (data.action === "cancel") {
  //     } else if (data.action === "picked") {
  //       handleDriveFileSelection(data); // Assuming data.docs contains selected files
  //     }
  //   };

  //   openPicker({
  //     clientId:
  //       "174146068432-ovvbm90df3r06nrq7osdq7v2akjnio9c.apps.googleusercontent.com",
  //     developerKey: "AIzaSyASL3yMev0JmvprcVv_VXnHFV2oy8k5MGE",
  //     viewId: "DOCS_IMAGES_AND_VIDEOS",
  //     showUploadView: false,
  //     showUploadFolders: false,
  //     supportDrives: true,
  //     multiselect: true,
  //     callbackFunction: pickerCallback,
  //   });
  // };

  // const handleDriveFileSelection = async (data) => {
  //   setErrorMessage("");

  //   if (!localStorage.getItem("ggToken")) {
  //     return;
  //   }

  //   const actualFiles = await Promise.all(
  //     data.docs.map(async (file) => {
  //       const response = await fetch(
  //         `https://www.googleapis.com/drive/v3/files/${file.id}?alt=media`,
  //         {
  //           headers: new Headers({
  //             Authorization: "Bearer " + localStorage.getItem("ggToken"),
  //           }),
  //         }
  //       );
  //       const blob = await response.blob();
  //       const newFile = new File([blob], file.name, { type: blob.type });
  //       return newFile;
  //     })
  //   );

  //   // setSelectedFile(actualFiles);
  //   localStorage.removeItem("ggToken");
  // };

  //------------------------Loading-----------------------------//

  const ITEMS_PER_PAGE = 40;

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState("");
  const [totalsPage, setTotalsPage] = useState("");
  const [pageTotal, setPageTotal] = useState("");
  //----------------------Image scrol---------------------//

  const getUserType = () => {
    return localStorage.getItem("userType") || "user";
  };

  const userType = getUserType();
  console.log("UserType:", userType);

  const getMyphotoExist = useCallback(async () => {
    try {
      const currentPages = 1;
      const status = "myPhotos";
      const response = await getAllImages(status, currentPages, eventData?.eventCode);
      console.log("response123456", response);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  }, [eventData?.eventCode]);

 // console.log("eventData12345", eventData);

  const getUploadDetectedImageInAUth = useCallback(async (datas) => {
    try {
      setfinalImagesLoader(true);
      const data = {
        userId: datas?._id,
        albumId: location?.state?.eventProps?._id,
        page: currentPage,
      };
      const response = await GetUploadDectedImages(data);
      setFinalPage(response?.data?.finalPage);
      setTotalsPage(response?.data?.totalPages);
      setFinaldetectedImages(response?.data?.data || []);
      setDetectedCount(response?.data?.totalImages || 0);
      setfinalImagesLoader(false);
    } catch (error) {
      console.log("error", error);
    }
  }, [location?.state?.eventProps?._id, currentPage]);

  const getImages = useCallback(async (data) => {
    console.log("GetImages");

    try {
      let currentPages = currentPage;
      if (data !== undefined) {
        currentPages = data;
      }

      // setloader(true);
      console.log("valuesvalues", values);

      const status = values === 0 ? "allPhotos" : "myPhotos";
      const response = await getAllImages(
        status,
        currentPages,
        eventData?.eventCode
      );
      // setloader(false);
      console.log("responnnseee12345", response);

      setfinalResponseData(response);

      if (
        response?.data?.Pages.tillImagesget === response?.data?.data?.totalCount
      ) {
        // setloader(false);
        setloaderScroll(false);
      }

      const newImages = response?.data?.data?.uploads;

      if (newImages?.length === 0) {
        // setloader(false);
      }

      setCurrentPage(response?.data?.Pages.currentPage);
      setTotalPage(response?.data?.Pages.totalPage);
      setPageTotal(response?.data?.Pages.totalPage);

      // Set counts based on the status
      if (status === "allPhotos") {
        setTotalCount(response?.data?.data?.totalCount || 0);
      } else if (status === "myPhotos") {
        setUploadedCount(response?.data?.data?.totalCount || 0);
      }

      const updatedImages = newImages?.map((image) => ({
        ...image,
        currentPage: response?.data?.data?.currentPage,
      }));

      if (status === "myPhotos") {
        setOwnPhoto((prevData) => {
          const uniqueNewImages = updatedImages?.filter(
            (newImage) =>
              !prevData?.some((prevItem) => prevItem?._id === newImage?._id)
          );
          return [...prevData, ...uniqueNewImages];
        });

        console.log("ownPhotos123", ownPhoto);
      }

      setSelectedUrl((prevData) => {
        const uniqueNewImages = updatedImages?.filter(
          (newImage) =>
            !prevData?.some((prevItem) => prevItem?._id === newImage?._id)
        );
        return [...prevData, ...uniqueNewImages];
      });
      console.log("selectedUrl1234", selectedUrl);
      setloaderScroll(false);
      // setloader(false);
    } catch (error) {
      console.error("Error fetching images:", error);
      // setloader(false);
    }
  // }, [values, myPhotos, deleteOpen, open, eventData?.eventCode]);
}, [values, eventData?.eventCode,currentPage,ownPhoto,selectedUrl]);


  // const debounce = (func, wait) => {
  //   let timeout;
  //   return function executedFunction(...args) {
  //     const later = () => {
  //       clearTimeout(timeout);
  //       func(...args);
  //     };
  //     clearTimeout(timeout);
  //     timeout = setTimeout(later, wait);
  //   };
  // };

  useEffect(() => {
    if (selectedAvatar) {
      console.log("selectedAvatarselectedAvatar", finalPage, currentPage);
      getUploadDetectedImageInAUth(selectedAvatar);
    }
  }, [currentPage, finalPage, getUploadDetectedImageInAUth, selectedAvatar]);

  // const handleScroll = useCallback(() => {
  //   if (
  //     finalResponseData?.data?.Pages.tillImagesget <
  //     finalResponseData?.data?.data?.totalCount
  //   ) {
  //     if (
  //       window.innerHeight + document.documentElement.scrollTop + 1 >=
  //       document.documentElement.scrollHeight
  //     ) {
  //       // Only update if not currently loading
  //       if (!loaderScroll) {
  //         if (finalPage) {
  //           if (currentPage < finalPage) {
  //             setCurrentPage((prevPage) => prevPage + 1);
  //             setloaderScroll(true);
  //             console.log("Current Page Updated:", currentPage);
  //           }
  //         } else {
  //           setCurrentPage((prevPage) => prevPage + 1);
  //           setloaderScroll(true);
  //           console.log("Current Page Updated:", currentPage);
  //         }

  //         // Load more data based on the new page number
  //       }
  //     }
  //   }
  // }, [finalResponseData, loaderScroll, currentPage]);

  // Debounced handleScroll
  // const debouncedHandleScroll = useCallback(debounce(handleScroll, 300), [
  //   handleScroll,
  // ]);

  // useEffect(() => {
  //   window.addEventListener("scroll", debouncedHandleScroll);
  //   return () => window.removeEventListener("scroll", debouncedHandleScroll);
  // }, [debouncedHandleScroll]);

  // const handleScroll = () => {

  //   if (finalResponseData?.data?.Pages.tillImagesget < finalResponseData?.data?.data?.totalCount) {
  //     if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
  //       setCurrentPage(prevPage => prevPage + 1);
  //       console.log("currentPage2", currentPage)
  //       // setLoad(true)
  //       setloaderScroll(true)
  //     }

  //   }

  // };

  // const debouncedHandleScroll = debounce(handleScroll, 300);

  // useEffect(() => {
  //   window.addEventListener('scroll', debouncedHandleScroll);
  //   return () => window.removeEventListener('scroll', debouncedHandleScroll);
  // }, [currentPage, totalPage, debouncedHandleScroll]);

  useEffect(() => {
    const setInitialFavorites = (images) => {
      const favorites = images?.filter(img => img.Favorite?.status)?.map(img => img._id) || [];
      setFavoritePhotos(favorites);
    };

    const getImages = async (data) => {
      try {
        let currentPages = currentPage;
        if (data !== undefined) {
          currentPages = data;
        }

        // setloader(true);
        const status = values === 0 ? "allPhotos" : "myPhotos";
        const response = await getAllImages(status, currentPages, eventData?.eventCode);
        // setloader(false);

        // Set initial favorites based on API response
        setInitialFavorites(response?.data?.data?.uploads || response?.data?.data);

        setfinalResponseData(response);

        if (
          response?.data?.Pages.tillImagesget === response?.data?.data?.totalCount
        ) {
          // setloader(false);
          setloaderScroll(false);
        }

        const newImages = response?.data?.data?.uploads;

        if (newImages?.length === 0) {
          // setloader(false);
        }

        setCurrentPage(response?.data?.Pages.currentPage);
        setTotalPage(response?.data?.Pages.totalPage);
        setPageTotal(response?.data?.Pages.totalPage);

        // Set counts based on the status
        if (status === "allPhotos") {
          setTotalCount(response?.data?.data?.totalCount || 0);
        } else if (status === "myPhotos") {
          setUploadedCount(response?.data?.data?.totalCount || 0);
        }

        const updatedImages = newImages?.map((image) => ({
          ...image,
          currentPage: response?.data?.data?.currentPage,
        }));

        if (status === "myPhotos") {
          setOwnPhoto((prevData) => {
            const uniqueNewImages = updatedImages?.filter(
              (newImage) =>
                !prevData?.some((prevItem) => prevItem?._id === newImage?._id)
            );
            return [...prevData, ...uniqueNewImages];
          });

          console.log("ownPhotos123", ownPhoto);
        }

        setSelectedUrl((prevData) => {
          const uniqueNewImages = updatedImages?.filter(
            (newImage) =>
              !prevData?.some((prevItem) => prevItem?._id === newImage?._id)
          );
          return [...prevData, ...uniqueNewImages];
        });
        console.log("selectedUrl1234", selectedUrl);
        setloaderScroll(false);
        // setloader(false);
      } catch (error) {
        console.error("Error fetching images:", error);
        // setloader(false);
      }
    };

    getImages();
  }, [currentPage,]);

  useEffect(() => {
    getMyphotoExist();
  }, [getMyphotoExist]);
  //-----------------------------------------------------------------------//

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  //   setCurrentPage("");
  // };

  const handleChanges = (event, newValue) => {
    setValues(newValue);
    setCurrentPage("");
  };

  const handleClose = () => {
    setOpen(false);
    // setSelectedFile("");
    setFileExist("");
    setErrorMessage("");
    setuploadpercentage("");
    setStatus("");
    // setloderForModel(false);
    // setFiles([])
    setTotalProgress(0);
  };

  console.log(finalResponseData, "finalResponseData");

  // const fileChange = (event) => {
  //   //  setStatus('')
  //   setErrorMessage('')
  //   const selectedFile = Array.from(event.target.files);
  //   console.log("selectedFiles", selectedFile);
  //   setSelectedFile(selectedFile);
  // };
  //-----------------------fileCheck API -------------------//
  const [fileExist, setFileExist] = useState([]);
  // const fileCheck = async (data, selectedFiles) => {
  //   try {
  //     const formData = new FormData();
  //     selectedFiles.forEach((file) => {
  //       formData.append("files", file);
  //     });
  //     const res = await fileCheking(data, eventData?.eventCode, formData);
  //     // setloderForModel(false);

  //     const fileExist = res.data.data;

  //     const newImageFileNames = fileExist
  //       ?.filter((item) => item.exists === "New image")
  //       .map((item) => item.fileName);

  //     const filteredSelectedFiles = selectedFiles?.filter((file) =>
  //       newImageFileNames?.includes(file.name)
  //     );

  //     // setSelectedFile(filteredSelectedFiles);

  //     setFileExist(res.data.data);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  //   const fileChange = (event) => {

  //     const selectedFiles = Array.from(event.target.files);
  //     setFileExist(selectedFiles);
      // setloderForModel(true)
  //     //  setStatus('')
  //     setErrorMessage('');

  // };

  // const fileChange = (event) => {
  //   const selectedFiles = Array.from(event.target.files);
  //   const allowedExtensions = /(\.jpg|\.jpeg)$/i;

  //   const filteredFiles = selectedFiles.filter((file) =>
  //     allowedExtensions.test(file.name)
  //   );

  //   if (filteredFiles.length > 0) {
  //     setFileExist(filteredFiles);
  //     setErrorMessage("");
  //   } else {
  //     setFileExist([]);
  //     setErrorMessage("Please select only JPG or JPEG files.");
  //   }
  //   event.target.value = "";
  // };

  console.log("fileExist", fileExist);
  // console.log("fileExistfileExistfileExist",fileExist);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/api.js";
    script.async = true;
    script.onload = () => {
      //console.log("Test", window.gapi); // Check if gapi is defined
    };
    document.body.appendChild(script);
    return () => document.body.removeChild(script);
  }, []);

  useEffect(() => {
    if (fetchImagesRef.current) {
      getImages();
      fetchImagesRef.current = false;
    }
  // }, [fetchImagesRef.current,getImages]);
  }, [getImages]);

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  function subscriptionStatus(data) {
    if (data === 200) {
      setStatus("");
      setErrorMessage("");
    }
  }

  //--------------------------Gallery View------------------//
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);

  const openGallery = (url, name, page, fileName) => {
    console.log("url", url, name, page, fileName);

    const data = {
      Url: url,
      filename: name,
      currentPage: page,
      totalPage: totalPage,
      originalName: fileName,
    };

    console.log("Data", data);
    setdeleteOpen(true);
    setdeleteData(data);
    //  navigator('/ViewImages', { state: { data } })
  };

  const viewGallerys = (url, name, page, fileName, item) => {
    const data = {
      Url: url,
      filename: name,
      currentPage: page,
      totalPage: totalPage,
      originalName: fileName,
      item: item,
    };

    console.log("Data", data);
    setdeleteOpen(true);
    setdeleteData(data);
    // navigator('/DeleteImages', { state: { data } })
  };
  const closeDeleteImage = (data) => {
    //  console.log(data)
    setdeleteOpen();
  };

  const closeGallery = () => {
    setSelectedImageIndex(null);
    setIsGalleryOpen(false);
  };

  const goToPreviousImage = () => {
    setSelectedImageIndex((prevIndex) => prevIndex - 1);
  };

  const goToNextImage = () => {
    setSelectedImageIndex((prevIndex) => prevIndex + 1);
  };

  // const handleAddNewUser = () => {
  //   navigator("/profile");
  // };
  const galleryView = () => {
    return (
      <Modal
        open={isGalleryOpen}
        onClose={closeGallery}
        sx={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "black",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <IconButton onClick={goToPreviousImage}>
            <ChevronLeftIcon />
          </IconButton>
          <img src={selectedImageIndex} alt="Selected" />
          <IconButton onClick={goToNextImage}>
            <ChevronRightIcon />
          </IconButton>
        </div>
      </Modal>
    );
  };

  //---------------------------Gallery End------------------//

  const [adduserLoad, setAddUserLoad] = useState(false);
  const getAllAddedUser = async () => {
    try {
      const response = await getaddedUser();
      setAddUserLoad(false);
      console.log("response123", response);
      setUserData({
        getUser: response?.data?.data?.AdditionalUsers || [],
        userProfile: response?.data?.data[0] || {},
      });
      setSelectedAvatar(response?.data?.data?.AdditionalUsers[0] || []);
    } catch (error) {
      console.log("response error", error);
    }
  };

  // const handleNavigate = (image) => {
  //   console.log("image", image);
  //   const userImage = image?.imageUrl;
  //   const userId = image?._id;
  //   const data = {
  //     userImage,
  //     userId,
  //   };
  //   dispatch(updateUserImage(data));
  //   // navigator("/", { state: { imageUrl: image } });
  // };

  // const bytesToMB = (bytes) => {
  //   if (bytes === 0) return "0 MB";
  //   const mb = bytes / (1024 * 1024);
  //   return `${mb.toFixed(2)} MB`;
  // };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 13,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      background: "linear-gradient(90deg, #e01cd5 0%, #1CB5E0 100%)",
    },
  }));

  const isMobile = useMediaQuery("(max-width:600px)");
  // const isTablet = useMediaQuery("(max-width:900px)");

  console.log("values", values);

  const [startDetect, setStartDetect] = useState(false);

  // const avatars = [
  //   {
  //     id: 1,
  //     username: "Username1",
  //     src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRG276J99WMZdlsHfn17ZukWcAHXgSATWaU_Q&s",
  //   },
  //   {
  //     id: 2,
  //     username: "Username2",
  //     src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRG276J99WMZdlsHfn17ZukWcAHXgSATWaU_Q&s",
  //   },
  //   {
  //     id: 3,
  //     username: "Username3",
  //     src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRG276J99WMZdlsHfn17ZukWcAHXgSATWaU_Q&s",
  //   },
  //   {
  //     id: 4,
  //     username: "Username4",
  //     src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRG276J99WMZdlsHfn17ZukWcAHXgSATWaU_Q&s",
  //   },
  // ];

  const handleAvatarSelect = (id) => {
    setSelectedAvatar(id);
    console.log("hhh", id);
  };

  const detectImages = (datas) => {
    setStartDetect(true);
    console.log("fffffffffffffffffffffff", datas);
    // const userImage = datas?.imageUrl;
    // const userId = datas?._id;

    // dispatch(updateUserImage(data))
    getUploadDetectedImageInAUth(datas);
  };

  const handleAvatarClick = (item) => {
    setDetectedCount("");
    setSelectedAvatar(item);
  };

  const search = () => {
    setFinaldetectedImages("");
    setCurrentPage(1);
    setFinalPage("");
    console.log("fffffffffffffffffffffff", selectedAvatar?.imageUrl);
    // const userImage = selectedAvatar?.imageUrl;
    // const userId = selectedAvatar?._id;

    // dispatch(updateUserImage(data))
    getUploadDetectedImageInAUth(selectedAvatar);
  };

  const [files, setFiles] = useState([]);
  // const [uploadProgress, setUploadProgress] = useState({});
  const [openModal, setOpenModal] = useState(true);
  const [totalProgress, setTotalProgress] = useState(0);

  const handleFileChange = (event) => {
    setFiles(Array.from(event.target.files));
    setOpen(true);
    setOpenModal(true);
   // setTotalProgress(0);
  };

  const handleUpload = async () => {

     if (dialogContentRef.current) {
      dialogContentRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (files.length === 0) {
      alert("Please select at least one image");
      return;
    }

    setOpen(true);
    const batches = [];
    for (let i = 0; i < files.length; i += 1) {
      batches.push(files.slice(i, i + 1));
    }

    let totalUploaded = 0;

    for (const batch of batches) {
      console.log("ffff",batch);
      
      await uploadBatch(batch);
      totalUploaded += batch.length;
      setTotalProgress((totalUploaded / files.length) * 100);
    }
    setOpenModal(false);
    getMyphotoExist();
  };

  const uploadBatch = async (batch) => {
    const formData = new FormData();
    batch.forEach((file) => formData.append("files", file));

    try {
      const userId = localStorage.getItem("userId");

      const data = {
        event: eventData?.eventCode,
        eventName: eventData?.name,
        eventType: eventData?.eventType,
        email: eventData?.createdByEmail,
        eventCreatedBy: eventData.createdBy,
        userId,
      };

      formData.append("data", JSON.stringify(data));
      const response = await uploadImage(formData, setUploadProgress);

      const uploadId = response?.data?.uploadId;
      await Promise.all(batch.map((file) => trackProgress(uploadId, file)));
    } catch (error) {
      console.error("Error during upload:", error);
    }
  };

  const trackProgress = async (uploadId, file) => {
    const checkProgress = async () => {
      try {
        const uploadResponse = await UploadProgress(uploadId);
        const currentProgress = uploadResponse?.data?.progress;
        
        setUploadProgress(prevProgress => ({
          ...prevProgress,
          [file.name]: currentProgress === -1 ? 100 : currentProgress,
        }));

        return currentProgress;
      } catch (error) {
        console.error(`Error during upload progress for ${file.name}:`, error);
        return 100; // Return 100 to break the loop on error
      }
    };

    let currentProgress = 0;
    while (currentProgress < 100) {
      currentProgress = await checkProgress();
      if (currentProgress === 100 || currentProgress === -1) break;
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleDeleteSelectedImages = async () => {
    try {
      const deletePromises = selectedImages.map(async (imageId) => {
        const data = { item: { _id: imageId } };
        return await deleteImageApi(data);
      });

      await Promise.all(deletePromises);

      setOwnPhoto((prevImages) =>
        prevImages.filter((image) => !selectedImages.includes(image._id))
      );

      setSelectedImages([]);
      getImages();

      setSuccessMessage("Successfully deleted selected images.");

      handleCloseConfirmDialog();

      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    } catch (error) {
      console.error("Error deleting images:", error);
    }
  };

  // Add this new function to fetch favorites
  const getFavoriteImages = useCallback(async () => {
    try {
      setLoadingFavorites(true);
      const albumId = location?.state?.eventProps?._id;
      
      if (!albumId) {
        console.error("Album ID is missing");
        return;
      }

      const response = await getFavoritesApi(albumId);
      
      if (response?.data?.data) {
        setFavoriteImages(response.data.data);
        setFavoritePhotoscount(response.data.data.length);
      }
    } catch (error) {
      console.error("Error fetching favorites:", error);
    } finally {
      setLoadingFavorites(false);
    }
  }, [location?.state?.eventProps?._id]);

  // Add useEffect to fetch favorites when tab changes to favorites
  useEffect(() => {
    console.log("Current tab value:", values); // Debug log
    if (values === 3) {
      console.log("Attempting to fetch favorites..."); // Debug log
      const albumId = location?.state?.eventProps?._id;
      console.log("Album ID:", albumId); // Debug log
      getFavoriteImages();
    }
  }, [values,getFavoriteImages,location?.state?.eventProps?._id]);

  // Update the Favorites tab panel
  const renderFavoritesTab = () => (
    <TabPanel value={values} index={3}>
      {loadingFavorites ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      ) : favoriteImages.length === 0 ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
            border: "2px solid #337AB7",
            borderRadius: "8px",
            padding: "20px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            width: "60%",
            margin: "0 auto",
          }}>
            <p style={{
              textAlign: "center",
              fontSize: "18px",
              color: "#333",
              margin: 0,
            }}>
              No favorite photos found. Mark photos as favorites to see them here.
            </p>
          </div>
        </div>
      ) : (
        <Box sx={{
          display: "grid",
          gridTemplateColumns: isMobileOrTablet ? "repeat(2, 1fr)" : "repeat(4, 1fr)",
          gap: "8px",
          padding: "16px",
        }}>
          {favoriteImages.map((item, index) => (
            <div className="pics" key={index}>
              <Box
                onClick={() => openGallery(
                  item?.Images?.highResolution,
                  item?.event,
                  item?.currentPage,
                  item?.fileName
                )}
                sx={{ position: "relative" }}
              >
                <img
                  alt={`Album  ${index + 1}`} 
                  // alt={`Album photo ${index + 1}`} 
                  
                  src={item?.Images?.lowResolution}
                  style={{
                    width: "100%",
                    height: "200px",
                    borderRadius: 8,
                    objectFit: "cover",
                  }}
                />
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleFavoriteClick(item._id, true);
                  }}
                  sx={{
                    position: "absolute",
                    top: 5,
                    right: 5,
                  }}
                >
                  <FavoriteIcon sx={{ color: "red" }} />
                </IconButton>
              </Box>
            </div>
          ))}
        </Box>
      )}
    </TabPanel>
  );

  // Add this new useEffect to fetch the initial uploaded count
  useEffect(() => {
    const getInitialUploadCount = async () => {
      try {
        const status = "myPhotos";
        const response = await getAllImages(status, 1, eventData?.eventCode);
        if (response?.data?.data?.totalCount !== undefined) {
          setUploadedCount(response?.data?.data?.totalCount);
        }
      } catch (error) {
        console.error("Error fetching initial upload count:", error);
      }
    };

    // if (eventData?.eventCode) {
    //   getInitialUploadCount();
    // }
  }, [eventData?.eventCode]); // Added dependency

  // Add useEffect to fetch favorites count on initial load
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const albumId = location?.state?.eventProps?._id;
        if (albumId) {
          const response = await getFavoritesApi(albumId);
          if (response?.data?.data) {
            setFavoritePhotoscount(response.data.data.length);
            // Also set the initial favorite photos array
            const favorites = response.data.data.map(item => item._id);
            setFavoritePhotos(favorites);
          }
        }
      } catch (error) {
        console.error("Error fetching initial favorites:", error);
      }
    };

    if (location?.state?.eventProps?._id) {
      fetchInitialData();
    }
  }, [location?.state?.eventProps?._id]); // Added dependency

  return (
    <div key={renderKey}>
      <div
        style={{
          zIndex: 10,
          overflowX: "hidden",
        }}
      >
        {deleteOpen && values === 1 && (
          <DeleteImages
            deleteData={deleteData}
            eventData={eventData}
            closeDeleteImage={closeDeleteImage}
          />
        )}

        {deleteOpen && values === 0 && (
          <ViewImages
            deleteData={deleteData}
            eventData={eventData}
            closeDeleteImage={closeDeleteImage}
          />
        )}
      </div>

      {galleryView()}

      <div>
        <Box
          style={{
            padding: isMobile ? 5 : 10,
            maxWidth: "100vw",
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            backgroundColor: "#F4FAFF",
            marginTop: isMobile ? 20 : 70,
            overflowX: "hidden",
          }}
        >
          <Stack direction="row" alignItems="center" spacing={isMobile ? 1 : 2}>
            <img
              src={albummm}
              alt=""
              style={{ width: 18, height: 18 }}
            />
            <Typography
              style={{
                fontSize: isMobile ? 14 : 16,
                color: "#616161",
                whiteSpace: "nowrap",
              }}
            >
              Album Details
            </Typography>
          </Stack>

          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: isMobile ? "flex-start" : "center",
              flexDirection: isMobile ? "column" : "row",
              marginTop: isMobile ? 10 : 0,
            }}
          >
            <Stack
              direction={isMobile ? "column" : "row"}
              alignItems="center"
              spacing={isMobile ? 1 : 2}
            >
              <Typography
                style={{
                  fontSize: isMobile ? 16 : 18,
                  fontWeight: "bold",
                  color: "#1565C0",
                  whiteSpace: "nowrap",
                }}
              >
                {eventData?.name} | {eventData?.venueAlias}
              </Typography>
              <Typography
                style={{
                  fontSize: isMobile ? 12 : 14,
                  color: "#616161",
                  whiteSpace: "nowrap",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={locaTIIIION}
                  alt="Location Icon"
                  style={{ width: 20, height: 20, marginRight: 8 }}
                />
                {eventData?.area}
              </Typography>
              <Typography
                style={{
                  fontSize: isMobile ? 12 : 14,
                  color: "#616161",
                  whiteSpace: "nowrap",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={timmmmm}
                  alt="Time Icon"
                  style={{ width: 20, height: 20, marginRight: 8 }}
                />
                {eventData?.eventDate &&
                  new Date(eventData?.eventDate).toLocaleDateString(
                    "en-GB"
                  )}{" "}
                | {eventData?.slot}
              </Typography>
            </Stack>

            <Box style={{ marginTop: isMobile ? 10 : 0 }}>
              <Stack>
                <Button
                  disableElevation
                  onClick={handleOpen}
                  //onClick={sendRequest}
                  variant="contained"
                  style={{
                    height: 40,
                    textTransform: "none",
                    backgroundColor: "white",
                    color: "#1E88E5",
                    display: "flex",
                    alignItems: "center",
                    marginTop: isMobile ? 10 : -25,
                    marginRight: isMobile ? 0 : 30,
                  }}
                  size="large"
                >
                  <img
                    src={gallery}
                    alt="Gallery Icon"
                    style={{ width: 18, height: 18, marginRight: 8 }}
                  />
                  Upload Images
                </Button>
              </Stack>

              <Modal
                open={openPermissionModal}
                onClose={() => setOpenPermissionModal(false)}
              >
                <Box sx={{ padding: 3, textAlign: "center" ,
                justifyContent:'center',
                  backgroundColor: "white",
                  color: "#1E88E5",
                 }}>
                  <Typography variant="h6">
                    You do not have permission to upload photos to this album.
                  </Typography>
                  <Typography>
                    Please request permission from the album owner.
                  </Typography>
                  { req ? <p>Pending</p>:
                  <Stack
                    direction="row"
                    justifyContent="center"
                    spacing={2}
                    mt={3}
                  >
                   
                    <Button variant="contained" onClick={sendRequest}>
                      Send Request
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => setOpenPermissionModal(false)}
                    >
                      Cancel
                    </Button>
                  </Stack>
}
                </Box>
              </Modal>
            </Box>
          </Box>
        </Box>

        {userData?.getUser && startDetect && isMobileOrTablet && (
          <Box
            display="flex"
            alignItems="center"
            p={1}
            bgcolor="#F4FAFF"
            borderRadius="8px"
            sx={{ margin: "5px" }}
          >
            <Typography
              s
              sx={{
                textTransform: "none",
                color: "#0f62fe",
                fontWeight: "bold",
                marginRight: "16px",
              }}
            >
              Change User
            </Typography>
            <Box display="flex" alignItems="center">
              {userData?.getUser?.map((item, index) => (
                <Grid item key={item._id}>
                  <Box
                    onClick={() => handleAvatarClick(item)}
                    sx={{
                      display: "inline-flex",
                      flexDirection: "column",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Avatar
                      src={item.imageUrl}
                      alt={item.name}
                      sx={{
                        width: 26,
                        height: 26,
                        marginTop: "2px",
                        // marginBottom: 1,
                        borderWidth: "4px",
                        borderStyle: "solid",
                        borderColor: "transparent",
                        background:
                          selectedAvatar._id === item._id
                            ? `radial-gradient(circle, #297EFF, #00C3FF)`
                            : "none",
                      }}
                    />
                  </Box>
                </Grid>
              ))}
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={search}
              disableElevation
              // startIcon={<SearchIcon />}
              sx={{
                textTransform: "none",
                marginLeft: "16px",
                height: "25px",
              }}
            >
              Search Photos
            </Button>
          </Box>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: isMobileOrTablet ? "column" : "row",
          }}
        >
          <div>
            <Tabs
              value={values}
              onChange={handleChanges}
              aria-label="basic tabs example"
              orientation={isMobileOrTablet ? "vertical" : "horizontal"} // Adjust orientation based on screen size
              sx={{
                display: "flex",
                flexDirection: isMobileOrTablet ? "column" : "row", // Column layout on mobile
                alignItems: isMobileOrTablet ? "flex-start" : "center", // Align items properly
              }}
            >
              <Tab
                key="all-photos"
                label={
                  <span>
                    All photos{" "}
                    <span
                      style={{
                        backgroundColor: values === 0 ? "#1266F1" : "",
                        color: values === 0 ? "white" : "gray",
                        borderRadius: "5px",
                        padding: "2px 6px",
                      }}
                    >
                      { totalCount || 0}
                    </span>
                  </span>
                }
                sx={{ textTransform: "none", fontSize: 18 }}
              />
              <Tab
                key="uploaded-photos"
                label={
                  <span>
                    Photos uploaded by me{" "}
                    <span
                      style={{
                        backgroundColor: values === 1 ? "#1266F1" : "",
                        color: values === 1 ? "white" : "gray",
                        borderRadius: "5px",
                        padding: "2px 6px",
                      }}
                    >
                      {uploadedCount || 0}
                    </span>
                  </span>
                }
                sx={{ textTransform: "none", fontSize: 18 }}
              />
              <Tab
                label={`${selectedAvatar?.name} detected photos (${
                  detectedCount || 0
                })`}
                sx={{ textTransform: "none", fontSize: 18 }}
              />
              <Tab
                label={
                  <span>
                    Favorites{" "}
                    <span
                      style={{
                        backgroundColor: values === 3 ? "#1266F1" : "",
                        color: values === 3 ? "white" : "gray",
                        borderRadius: "5px",
                        padding: "2px 6px",
                      }}
                    >
                      {favoritePhotoscount || 0}
                    </span>
                  </span>
                }
                sx={{ textTransform: "none", fontSize: 18 }}
              />
            </Tabs>

            <div
              style={{
                display: "flex",
                flexDirection: isMobileOrTablet ? "column" : "row",
              }}
            >
              {userData?.getUser && startDetect && !isMobileOrTablet && (
                <Box
                  display="flex"
                  alignItems="center"
                  p={1}
                  bgcolor="#F4FAFF"
                  borderRadius="8px"
                  sx={{
                    margin: "5px",
                    flexDirection: isMobileOrTablet ? "column" : "row",
                  }}
                >
                  <Typography
                    sx={{
                      textTransform: "none",
                      color: "#0f62fe",
                      fontWeight: "bold",
                      marginRight: "16px",
                    }}
                  >
                    Change User
                  </Typography>
                  <Box display="flex" alignItems="center">
                    {userData?.getUser?.map((item, index) => (
                      <Grid item key={item._id}>
                        <Box
                          onClick={() => handleAvatarClick(item)}
                          sx={{
                            display: "inline-flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          <Avatar
                            src={item.imageUrl}
                            alt={item.name}
                            sx={{
                              width: 26,
                              height: 26,
                              marginTop: "2px",
                              borderWidth: "4px",
                              borderStyle: "solid",
                              borderColor: "transparent",
                              background:
                                selectedAvatar._id === item._id
                                  ? `radial-gradient(circle, #297EFF, #00C3FF)`
                                  : "none",
                            }}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={search}
                    disableElevation
                    sx={{
                      textTransform: "none",
                      marginLeft: "16px",
                      height: "25px",
                      marginTop: isMobileOrTablet ? "10px" : "0", // Add margin for mobile view
                    }}
                  >
                    Search Photos
                  </Button>
                </Box>
              )}
            </div>
          </div>
        </div>
      </div>

      <Box style={{ zIndex: 0 }}>
        <>
          <TabPanel value={values} index={0}>
            {selectedUrl?.length === 0 ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                    border: "2px solid #337AB7",
                    borderRadius: "8px",
                    padding: "20px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    width: "60%",
                    margin: "0 auto",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "18px",
                      color: "#333",
                      margin: 0,
                    }}
                  >
                    No photos were found please upload new photos in the album.
                  </p>
                </div>
              </div>
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "100%",
                    minHeight: "100vh",
                    gap: 0.05,
                  }}
                >
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: isMobileOrTablet
                        ? "repeat(2, 1fr)"
                        : "repeat(4, 1fr)",
                      gap: "7px",
                    }}
                  >
                    {selectedUrl
                      ?.slice(
                        (currentPage - 1) * ITEMS_PER_PAGE,
                        currentPage * ITEMS_PER_PAGE
                      )
                      ?.map((item, index) => {
                        console.log("Rendering item:", item);
                        console.log("Item favorite status:", item.Favorite?.status);
                        console.log("Is in favoritePhotos:", favoritePhotos.includes(item._id));
                        
                        return (
                          <div className="pics" key={index}>
                            <Box
                              key={index}
                              // className="pics"
                              // sx={{
                              //   breakInside: "avoid",
                              // }}
                              onClick={() =>
                                openGallery(
                                  item?.Images?.highResolution,
                                  item?.event,
                                  item?.currentPage,
                                  item?.fileName
                                )
                              }
                            >
                              <div
                                style={{
                                  position: "relative",
                                  width: "100%",
                                  height: "auto",
                                }}
                              >
                                <span
                                  style={{
                                    position: "absolute",
                                    top: 5,
                                    left: 5,
                                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                                    color: "white",
                                    padding: "2px 5px",
                                    borderRadius: "3px",
                                  }}
                                >
                                  {index + 1 + (currentPage - 1) * ITEMS_PER_PAGE}
                                </span>
                                <img
                                  alt={`Album  ${index + 1}`}
                                  src={item?.Images?.lowResolution}
                                  style={{
                                    width: "100%",
                                    height: "200px",
                                    borderRadius: 8,
                                    objectFit: "cover",
                                  }}
                                />
                                <IconButton
                                  style={{
                                    position: "absolute",
                                    top: 5,
                                    right: 5,
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleFavoriteClick(item._id, favoritePhotos.includes(item._id));
                                  }}
                                >
                                  {favoritePhotos.includes(item._id) ? (
                                    <FavoriteIcon sx={{ color: "red" }} />
                                  ) : (
                                    <FavoriteBorderIcon sx={{ color: "white" }} />
                                  )}
                                </IconButton>
                              </div>
                            </Box>
                          </div>
                        );
                      })}
                  </Box>
                </Box>
                {/* {selectedUrl.length > 0 && ( // Check if there are photos to paginate */}
                {/* <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
                <Pagination
                  count={totalPage}
                  page={currentPage}
                  onChange={(event, value) => setCurrentPage(value)}
                  variant="outlined"
                  shape="rounded"
                />
                <Box>
            <Button onClick={handlePreviousPage} disabled={currentPage === 1}>
                Previous
            </Button>
            <span>Page {currentPage} of {totalPage}</span>
            <Button onClick={handleNextPage} disabled={currentPage === totalPage}>
                Next
            </Button>
        </Box>
              </Box> */}

                {loaderScroll && (
                  <div
                    style={{
                      zIndex: 9999,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100vh",
                    }}
                  >
                    <CircularProgress color="inherit" />
                  </div>
                )}
              </>
            )}

            <Box
              sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}
            >
              <Box>
                <Button
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </Button>
                <span>
                  {" "}
                  Page {currentPage} of {pageTotal}{" "}
                </span>
                <Button
                  onClick={handleNextPage}
                  disabled={currentPage === pageTotal}
                >
                  Next
                </Button>
              </Box>
            </Box>
          </TabPanel>

          <TabPanel value={values} index={2}>
            {
              <>
                {adduserLoad ? (
                  <AvatarLoader />
                ) : (
                  <>
                    {startDetect ? (
                      <>
                        {finalImagesLoader ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {/* <CircularProgress color="inherit" /> */}
                            <AvatarDetectLoader
                              userName={selectedAvatar.name}
                              image={selectedAvatar.imageUrl}
                            />
                          </div>
                        ) : finaldetectedImages?.length === 0 ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              justifyItems: "center",
                              alignContent: "center",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "60%",
                                minHeight: "100px",
                                alignItems: "center",
                                backgroundColor: "#F0F4FF",
                                backgroundborder: "2px solid #337AB7",
                                borderborderRadius: "8px",
                                cornerspadding: "20px",
                                contentboxShadow:
                                  "0 4px 8px rgba(0, 0, 0, 0.1)",
                                shadowwidth: "60%",
                                widthmargin: "0 auto",
                                margin: "40px",
                                padding: "10px",
                              }}
                            >
                              <p
                                style={{
                                  textAlign: "center",
                                  fontSize: "18px",
                                  color: "#333",
                                  margin: 0,
                                }}
                              >
                                No matching photos were found for this user.
                                Please select another user or add a new user
                                whose face you want to search in the album.
                              </p>
                            </div>
                          </div>
                        ) : (
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center", // Center horizontally
                                justifyContent: "flex-start", // Align to the top vertically
                                width: "100%", // Full width
                                minHeight: "100vh", // Full height of the viewport
                                gap: 0.05,
                              }}
                            >
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: isMobileOrTablet
                                    ? "repeat(2, 1fr)"
                                    : "repeat(4, 1fr)",
                                  gap: "7px",
                                }}
                              >
                                {finaldetectedImages?.map((item, index) => (
                                  <div className="pics" key={index}>
                                    <Box
                                      onClick={() =>
                                        openGallery(
                                          item.Images.highResolution,
                                          item?.event,
                                          item?.currentPage,
                                          item?.fileName
                                        )
                                      }
                                    >
                                      <div
                                        style={{
                                          position: "relative",
                                          width: "100%",
                                          height: "auto",
                                        }}
                                      >
                                        {" "}
                                        <span
                                          style={{
                                            position: "absolute",
                                            top: 5,
                                            left: 5,
                                            backgroundColor:
                                              "rgba(0, 0, 0, 0.5)",
                                            color: "white",
                                            padding: "2px 5px",
                                            borderRadius: "3px",
                                          }}
                                        >
                                          {index + 1}
                                        </span>
                                        <img
                                        alt={`Album  ${index + 1}`} 
                                          src={item.Images.lowResolution}
                                          style={{
                                            width: "100%",
                                            height: "200px",
                                            objectFit: "cover", // Maintain aspect ratio and cover the area
                                            borderRadius: 8,
                                          }}
                                        />
                                      </div>
                                    </Box>
                                  </div>
                                ))}
                              </div>
                              {finalPage !== "" ? (
                                <>
                                  {finalPage <= currentPage ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        width: "100%",
                                        minHeight: "100px",
                                        alignItems: "center",
                                        backgroundColor: "#F0F4FF",
                                        backgroundborder: "2px solid #337AB7",
                                        borderborderRadius: "8px",
                                        cornerspadding: "20px",
                                        contentboxShadow:
                                          "0 4px 8px rgba(0, 0, 0, 0.1)",
                                        shadowwidth: "60%",
                                        widthmargin: "0 auto",
                                        margin: "40px",
                                        padding: "10px",
                                      }}
                                    >
                                      <p
                                        style={{
                                          textAlign: "center",
                                          fontSize: "18px",
                                          color: "#333",
                                          margin: 0,
                                        }}
                                      >
                                        Successfully retrieved all detected
                                        photos in this album
                                      </p>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        width: "100%",
                                        minHeight: "100px",
                                        alignItems: "center",
                                        backgroundColor: "#F0F4FF",
                                        backgroundborder: "2px solid #337AB7",
                                        borderborderRadius: "8px",
                                        cornerspadding: "20px",
                                        contentboxShadow:
                                          "0 4px 8px rgba(0, 0, 0, 0.1)",
                                        shadowwidth: "60%",
                                        widthmargin: "0 auto",
                                        margin: "40px",
                                        padding: "10px",
                                      }}
                                    >
                                      <p
                                        style={{
                                          textAlign: "center",
                                          fontSize: "18px",
                                          color: "#333",
                                          margin: 0,
                                        }}
                                      >
                                        Go to the next page to view more photos
                                      </p>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      width: "100%",
                                      minHeight: "100px",
                                      alignItems: "center",
                                      backgroundColor: "#F0F4FF",
                                      backgroundborder: "2px solid #337AB7",
                                      borderborderRadius: "8px",
                                      cornerspadding: "20px",
                                      contentboxShadow:
                                        "0 4px 8px rgba(0, 0, 0, 0.1)",
                                      shadowwidth: "60%",
                                      widthmargin: "0 auto",
                                      margin: "40px",
                                      padding: "10px",
                                    }}
                                  >
                                    <p
                                      style={{
                                        textAlign: "center",
                                        fontSize: "18px",
                                        color: "#333",
                                        margin: 0,
                                      }}
                                    >
                                      Go to the next page to view more photos
                                    </p>
                                  </div>
                                </>
                              )}
                            </Box>
                            {/* Loader conditionally rendered */}
                            {loaderScroll && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <CircularProgress color="inherit" />
                              </div>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <Avrcard
                        avatars={userData}
                        selectedAvatar={selectedAvatar}
                        onAvatarSelect={handleAvatarSelect}
                        searchDetaect={detectImages}
                      />
                    )}
                  </>
                )}
              </>
            }
            <Box
              sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}
            >
              {/* <Pagination
                  count={totalPage}
                  page={currentPage}
                  onChange={(event, value) => setCurrentPage(value)}
                  variant="outlined"
                  shape="rounded"
                /> */}
              <Box>
                <Button
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </Button>
                <span>
                  {" "}
                  Page {currentPage} of {totalsPage}{" "}
                </span>
                <Button
                  onClick={handleNextPage}
                  disabled={currentPage === totalsPage}
                >
                  Next
                </Button>
              </Box>
            </Box>
          </TabPanel>

          {/* Additional tab panel for photographer */}
          {
            <TabPanel value={values} index={1}>
              {/* Show the success message at the center of the screen */}

              {successMessage && (
                <div
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "#4caf50",
                    color: "white",
                    padding: "15px 30px",
                    borderRadius: "5px",
                    textAlign: "center",
                    zIndex: 1000, // Ensure the message appears above all other content
                  }}
                >
                  {successMessage}
                </div>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "15px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={toggleCheckboxes}
                >
                  {showCheckboxes ? "Cancel Selection" : "Select Images"}
                </Button>
              </div>

              {ownPhoto?.length === 0 ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "200px",
                      border: "2px solid #337AB7",
                      borderRadius: "8px",
                      padding: "20px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "60%",
                      margin: "0 auto",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "18px",
                        color: "#333",
                        margin: 0,
                      }}
                    >
                      No photos were found, please upload new photos in the
                      album.
                    </p>
                  </div>
                </div>
              ) : (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      width: "100%",
                      minHeight: "100vh",
                      gap: 0.05,
                    }}
                  >
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: isMobileOrTablet
                          ? "repeat(2, 1fr)"
                          : "repeat(4, 1fr)",
                        gap: "7px",
                      }}
                    >
                      {ownPhoto
                        ?.slice(
                          (currentPage - 1) * ITEMS_PER_PAGE,
                          currentPage * ITEMS_PER_PAGE
                        )
                        ?.map((item, index) => (
                          <div className="pics" key={index}>
                            <Box
                              sx={{ position: "relative", width: "100%" }}
                              onClick={() =>
                                !showCheckboxes &&
                                viewGallerys(
                                  item?.Images?.lowResolution,
                                  item?.event,
                                  item?.currentPage,
                                  item?.fileName,
                                  item
                                )
                              }
                            >
                              {showCheckboxes && (
                                <input
                                  type="checkbox"
                                  checked={isImageSelected(item._id)}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    handleSelectImage(item._id);
                                  }}
                                  style={{
                                    position: "absolute",
                                    top: 5,
                                    right: 5,
                                    zIndex: 2,
                                  }}
                                />
                              )}

                              <div
                                style={{
                                  position: "relative",
                                  width: "100%",
                                  height: "auto",
                                }}
                              >
                                <span
                                  style={{
                                    position: "absolute",
                                    top: 5,
                                    left: 5,
                                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                                    color: "white",
                                    padding: "2px 5px",
                                    borderRadius: "3px",
                                    zIndex: 1,
                                  }}
                                >
                                  {index +
                                    1 +
                                    (currentPage - 1) * ITEMS_PER_PAGE}
                                </span>
                                <img
                                  src={item?.Images?.lowResolution}
                                  // alt={`Album photo ${index + 1}`}
                                  alt={`Album  ${index + 1}`} 
                                  style={{
                                    width: "100%",
                                    height: "200px",
                                    objectFit: "cover",
                                    borderRadius: 8,
                                  }}
                                />
                              </div>
                            </Box>
                          </div>
                        ))}
                    </div>
                  </Box>

                  <>
                    {/* Button to open the confirmation dialog */}

                    {showCheckboxes && selectedImages.length > 0 && (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleOpenConfirmDialog}
                        style={{ marginTop: 20 }}
                      >
                        Delete Selected Images
                      </Button>
                    )}

                    {/* Confirmation dialog */}
                    <Dialog
                      open={openConfirmDialog}
                      onClose={handleCloseConfirmDialog}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Delete Confirmation"}
                      </DialogTitle>
                      <DialogContent>
                        Are you sure you want to delete the selected images?
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={handleCloseConfirmDialog}
                          color="primary"
                        >
                          No
                        </Button>
                        <Button
                          onClick={handleDeleteSelectedImages}
                          color="primary"
                          autoFocus
                        >
                          Yes
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 2,
                    }}
                  >
                    <Box>
                      <Button
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                      >
                        Previous
                      </Button>
                      <span>
                        {" "}
                        Page {currentPage} of {totalPage}{" "}
                      </span>
                      <Button
                        onClick={handleNextPage}
                        disabled={currentPage === totalPage}
                      >
                        Next
                      </Button>
                    </Box>
                  </Box>

                  {loaderScroll && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                      }}
                    >
                      <CircularProgress color="inherit" />
                    </div>
                  )}
                </>
              )}
            </TabPanel>
          }
          {renderFavoritesTab()}
        </>
      </Box>

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ borderRadius: 5, zIndex: 500 }}
      >
        <Box sx={{ width: "100%", ...style }}>
          <Stack
            style={{
              display: "flex",
              position: "absolute",
              right: 10,
              top: 10,
            }}
          >
            <Box onClick={handleClose} sx={{ cursor: "pointer" }}>
              <CloseCircleTwoTone />
            </Box>
          </Stack>

          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            {status === 401 ? (
              <SubscriptionPay
                subscriptionStatus={subscriptionStatus}
                subscriptionMessage={subscriptionMessage}
              />
            ) : null}
          </Box>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {/* <CircularProgress color="inherit"  />  */}
          </div>

          {errorMessage ? (
            <Alert severity="error">
              <Typography>{errorMessage}</Typography>
            </Alert>
          ) : null}

          <div style={{ display: "flex", justifyContent: "center" }}></div>

          {files?.length > 0 && openModal ? (
            <>
              <Dialog
                open={open}
                onClose={() => setOpen(false)}
                maxWidth="md"
                fullWidth
              >
                <DialogTitle>
                  <Typography variant="h6">Upload Photos</Typography>
                  <IconButton
                    aria-label="close"
                    onClick={() => setOpen(false)}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseCircleTwoTone />
                  </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                    ref={dialogContentRef}
                  >
                    <Typography variant="body1">{`${files.length} Photos Selected`}</Typography>
                    <Typography variant="body2" align="right">
                      {`${(
                        files.reduce((acc, file) => acc + file.size, 0) /
                        1024 /
                        1024
                      ).toFixed(2)} MB`}
                    </Typography>
                  </Box>
                  <BorderLinearProgress
                    variant="determinate"
                    value={totalProgress}
                    sx={{ mb: 2 }}
                  />
                  {files.map((file, index) => (
                    <Card
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        p: 1,
                        mb: 1,
                        borderRadius: 2,
                      }}
                      elevation={0}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Box sx={{ flexGrow: 1 }}>
                          <Typography variant="body1" fontWeight="bold">
                            {file.name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {(file.size / 1024 / 1024).toFixed(2)} MB
                          </Typography>
                        </Box>

                        <Typography
                          variant="body2"
                          sx={{
                            width: "100%",
                            mt: 1,
                            textAlign: "right",
                            color:
                              uploadProgress[file.name] === 100
                                ? "green"
                                : uploadProgress[file.name] > 0
                                ? "orange"
                                : "textSecondary",
                          }}
                        >
                          {uploadProgress[file.name] === 100
                            ? "UPLOADED"
                            : uploadProgress[file.name] > 0
                            ? "UPLOADING..."
                            : ""}
                        </Typography>
                      </Box>
                      <Box sx={{ width: "100%", mt: 2 }}>
                        <BorderLinearProgress
                          variant="determinate"
                          value={uploadProgress[file.name] || 0}
                          sx={{
                            height: 10,
                            borderRadius: 2,
                            backgroundColor: "#e0e0e0",
                          }}
                        />
                      </Box>
                    </Card>
                  ))}
                </DialogContent>
                <DialogActions>
                  {totalProgress > 0 ? (
                    <Button
                      onClick={() => {}}
                      variant="contained"
                      color="primary"
                      sx={{ marginLeft: 2 }}
                    >
                      Uploading..
                    </Button>
                  ) : (
                    <Button
                      onClick={handleUpload}
                      variant="contained"
                      color="primary"
                      sx={{ marginLeft: 2 }}
                    >
                      Upload Photos
                    </Button>
                  )}
                  <Button
                    onClick={() => setOpenModal(false)}
                    variant="outlined"
                    color="secondary"
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          ) : (
            <>
              <div style={{ textAlign: "center", marginTop: 10 }}>
                <label
                  htmlFor="file-input-computer"
                  style={{
                    backgroundColor: "#eaf0fa",
                    color: "#1976D2",
                    padding: "10px 15px",
                    borderRadius: "5px",
                    cursor: "pointer",
                    display: "inline-block",
                    width: "94.5%",
                    border: "1px solid #007bff",
                  }}
                >
                  {totalProgress === 100
                    ? "Upload More Photos"
                    : "Upload Your Photos"}
                </label>
                <FormHelperText>
                  Only formatted JPG and JPEG images
                </FormHelperText>
                <input
                  id="file-input-computer"
                  type="file"
                  onChange={handleFileChange}
                  multiple
                  accept=".jpg, .jpeg"
                  style={{ display: "none" }}
                />
              </div>
            </>
          )}

          {totalProgress === 100 && (
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: isMobile ? "flex-start" : "center",
                justifyContent: "space-between",
              }}
            >
              <p>
                {files.length} photos uploaded successfully: {totalProgress}%
                completed
              </p>
              <button
                onClick={handleClose}
                style={{
                  backgroundColor: "#eaf0fa",
                  color: "#1976D2",
                  padding: "10px 15px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  display: "inline-block",
                  width: isMobile ? "100%" : "auto",
                  border: "1px solid #007bff",
                }}
              >
                {"Done"}
              </button>
            </div>
          )}
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
