// import React, { useEffect, useMemo, useState } from "react";
// import Login1 from "./Login1";
// import Login2 from "./Login2";
// import Login4 from "./Login4";
// import LoginStep from "./LoginStep"; 
// import {
//   Alert,
//   Box,
//   Button,
//   Card,
//   CircularProgress,
//   Grid,
//   Stack,
//   Typography,
// } from "@mui/material";
// import { getSocialProfileApi, otpSend, Register } from "../../API/Auth";
// import { useNavigate } from "react-router";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";
// import { useDispatch } from "react-redux";
// import { addProfileRequest, addRegisterData, updateProfileRequest } from "../../Store/action";
// import bgimage from "../../images/divine-pic-login.svg";
// import logoImage from "../../images/FINDMYPIK.png";

// import { uploadAndCheckFace } from "../../API/Photographer";
// import { server } from "../../API/server";

// function Login() {
//   const [currentStep, setCurrentStep] = useState(0); 
//   const [errorMessage, setErrorMessage] = useState("");
//   const [userEmail, setUserEmail] = useState("");
//   const [userData, setUserData] = useState("");
//   const [successMessage, setSuccessMessage] = useState("");
//   const [isLoading, setIsLoading] = useState(false);
//   const [otp, setOtp] = useState("");
//   const [url, setUrl] = useState("");
//   const [name, setName] = useState('');

//   const theme = useTheme();
//   const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("700"));
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   useEffect(() => {
//     const handleKeyDown = (event) => {
//       if (event.key === "Enter") {
//         nextStep();
//       }
//     };

//     document.addEventListener("keydown", handleKeyDown);

//     return () => {
//       document.removeEventListener("keydown", handleKeyDown);
//     };
//   }, [currentStep]);

//   let query = new URLSearchParams(window.location.search);
//   console.log('return', query.get('success'),query.get('addr'), query.get('url'),query.get('call') );

//   const handleSocialProfile = async (newValue) => {
//     try {
//       const response = await getSocialProfileApi(newValue);
//       console.log('response social', response);
//       if (response.status === 200) {
//         if (response?.data?.userType === null) {
//           setCurrentStep(3);
//         } else {
//           localStorage.clear();
//           localStorage.setItem("accessToken", response?.data?.accessToken);
//           localStorage.setItem("userId", response?.data?.data?._id);
//           localStorage.setItem("email", response?.data?.data?.email);
//           localStorage.setItem("profileImage", response?.data.data?.imageUrl);
//           const photoGrpherExist = response?.data?.IsExist;
//           const datas = { email: response?.data?.data?.email, photoGrpherExist };
//           dispatch(addProfileRequest(datas));
//           setErrorMessage("Logged in Successfully");

//           setTimeout(() => {
//             setErrorMessage('');
//             navigate('/home');
//           }, 1000);
//         }
//       } else {
//         setCurrentStep(3);
//       }
//     } catch (err) {
//       console.error(err);
//       setErrorMessage(err.message);
//     }
//   };

//   useMemo(() => {
//     if (query.get('success')) {
//       setUserEmail(query.get('addr'));

//       const fileUrl = query.get('url');
//       setUrl(fileUrl)

//       const name = query.get('call');
//       console.log('Extracted name:', name);
//       setName(name)

//       if (fileUrl) {
//         fetch(fileUrl)
//           .then(response => response.blob())
//           .then(blob => {
//             const file = new File([blob], 'userprofile.jpeg', { type: blob.type });

//             setUserData(prevData => ({
//               ...prevData,
//               name: name,
//               file: file,
//             }));
//           })
//           .catch(err => {
//             console.error("Error fetching the file:", err);
//           });
//       } else {
//         setUserData({
//           name: name,
//           phone: '',
//           file: null,
//         });
//         console.log('return setpper changed');
//       }
//       handleSocialProfile(query.get('addr'))
//     }
//   }, []);

//   const Login1Data = (data) => {
//     setErrorMessage("");
//     setUserEmail(data);
//   };

//   const Login2Data = (data) => {
//     setErrorMessage("");
//     setOtp(data);
//   };

//   const Login4Data = (data) => {
//     setErrorMessage("");
//     setSuccessMessage("");
//     setUserData(data);
//   };

//   const EmailSubmit = async () => {
//     const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     if (!userEmail) {
//       setErrorMessage("Email is required");
//     } else if (!emailFormat.test(userEmail)) {
//       setErrorMessage("Invalid Email format");
//     } else {
//       try {
//         const data = { email: userEmail };
//         const response = await otpSend(data);
//         if (response.status === 201) {
//           setErrorMessage("");
//           setCurrentStep(currentStep + 1);
//         }
//       } catch (err) {
//         console.error(err);
//         setErrorMessage(err.message);
//       }
//     }
//   };

//   const otpSubmit = async () => {
//     if (!otp || otp.length !== 6) {
//       setErrorMessage("Please enter a valid 6-digit OTP.");
//       return;
//     }
//     setCurrentStep(currentStep + 1);
//   };

//   const submitProfile = async () => {
//     setIsLoading(true);
//     try {
//       if (!userData?.file) {
//         setErrorMessage(
//           "Please upload your face photo only. This will be used for detection in photo list."
//         );
//         setIsLoading(false);
//         return;
//       }

//       const formData = new FormData();
//       formData.append("files", userData?.file);
//       const uploadResponse = await uploadAndCheckFace(formData);
//       if (
//         uploadResponse.data.result.status
//       ) {
//         setErrorMessage(
//           "Multiple faces detected. Please upload a photo with only your face for accurate detection."
//         );
//         setIsLoading(false);
//         return;
//       }

//       const userDetail = {
//         name: userData.name,
//         email: userEmail,
//         phone: userData.phone,
//         userType: "photographer",
//       };
//       console.log('user Details', userDetail, userData.file);

//       const registrationFormData = new FormData();
//       registrationFormData.append("userData", JSON.stringify(userDetail));
//       registrationFormData.append("file", userData.file);

//       const result = await Register(registrationFormData);

//       if (result.status === 200) {
//         const userType = result?.data?.userType;
//         const UserImage = result?.data.data?.imageUrl;
//         const UserId = result?.data.data?.AdditionalUsers[0];
//         const datas = { data: result?.data, userType, UserImage, UserId };
//         console.log('registration data', datas);

//         dispatch(addRegisterData(datas));
//         // const datass = { email: result?.data.data.email, photoGrpherExist:false };
        

//       // dispatch(addProfileRequest(datass));
//         // dispatch(updateProfileRequest());
//         localStorage.setItem("accessToken", result?.data?.accessToken);
//         localStorage.setItem("userType", userType);
//         localStorage.setItem("userId", result?.data.data?._id);
//         localStorage.setItem("email", result?.data.data?.email);
//         localStorage.setItem("profileImage", result?.data.data?.imageUrl);

//         setSuccessMessage("Profile Completed Successfully");
//         setTimeout(() => {
//           setSuccessMessage("");
//           navigate("/home");
//         }, 2000);
//       } else {
//         setErrorMessage(result?.response?.data?.error || "Registration failed");
//       }
//     } catch (err) {
//       setErrorMessage(err.message);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const nextStep = () => {
//     if (currentStep === 0) {
//       setCurrentStep(1);
//     } else if (currentStep === 1) {
//       EmailSubmit();
//     } else if (currentStep === 2) {
//       otpSubmit();
//     } else {
//       submitProfile();
//     }
//   };

//   const prevStep = () => {
//     setCurrentStep((prev) => Math.max(prev - 1, 0));
//     setErrorMessage("");
//   };

//   const handleGoogleClick = async () => {
//     window.open(`${server}/divine/Auth/google`, "_blank")

//     const loginType = "google";
//     localStorage.setItem("loginType", loginType);
//   };

//   const handleGoogleSuccess = (response) => {
//     console.log("Google login successful:", response);
//   };

//   const onGoogleFailure = (error) => {
//     console.error("Google login failed:", error);
//     if (error.error === 'popup_closed_by_user') {
//       setErrorMessage("Login popup was closed before completion. Please try again.");
//     } else {
//       setErrorMessage("Google login failed. Please try again.");
//     }
//   };

//   const renderStep = () => {
//     switch (currentStep) {
//       case 0:
//         return (
//           <LoginStep
//             onEmailClick={nextStep}
//             onGoogleClick={handleGoogleClick}
//           />
//         );
//       case 1:
//         return (
//           <>
//             <Login1
//               nextStep={nextStep}
//               Login1Data={Login1Data}
//               errorMessage={errorMessage}
//             />
//             <Button
//               type="submit"
//               onClick={nextStep}
//               fullWidth
//               variant="contained"
//               sx={{
//                 marginTop: "16px",
//                 borderColor: "#DB4437",
//                 color: "#DB4437",
//                 padding: "12px 20px",
//                 borderRadius: "8px",
//                 backgroundColor: "#fff",
//                 boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
//                 transition: "transform 0.3s ease, box-shadow 0.3s ease",
//                 '&:hover': {
//                   transform: "translateY(-3px)",
//                   boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.2)",
//                   color: "#fff",
//                 },
//               }}
//               disabled={isLoading}
//             >
//               {isLoading ? <CircularProgress size={30} /> : "Continue"}
//             </Button>
//           </>
//         );
//       case 2:
//         return (
//           <>
//             <Login2
//               moveNextStep={nextStep}
//               prevStep={prevStep}
//               Login2Data={Login2Data}
//               errorMessage={errorMessage}
//               userEmail={userEmail}
//             />
//             <Button
//               type="submit"
//               onClick={nextStep}
//               fullWidth
//               variant="contained"
//               sx={{
//                 marginTop: "16px",
//                 borderColor: "#DB4437",
//                 color: "#DB4437",
//                 padding: "12px 20px",
//                 borderRadius: "8px",
//                 backgroundColor: "#fff",
//                 boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
//                 transition: "transform 0.3s ease, box-shadow 0.3s ease",
//                 '&:hover': {
//                   transform: "translateY(-3px)",
//                   boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.2)",
//                   color: "#fff",
//                 },
//               }}
//               disabled={isLoading}
//             >
//               {isLoading ? <CircularProgress size={30} /> : "Continue"}
//             </Button>

//             <Button
//               type="submit"
//               onClick={""}
//               fullWidth
//               variant="contained"
//               sx={{
//                 marginTop: "16px",
//                 borderColor: "#DB4437",
//                 color: "#DB4437",
//                 padding: "12px 20px",
//                 borderRadius: "8px",
//                 backgroundColor: "#fff",
//                 boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
//                 transition: "transform 0.3s ease, box-shadow 0.3s ease",
//                 '&:hover': {
//                   transform: "translateY(-3px)",
//                   boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.2)",
//                   color: "#fff",
//                 },
//               }}
//               disabled={isLoading}
//             >
//               {isLoading ? <CircularProgress size={30} /> : "Resend otp"}
//             </Button>
//           </>
//         );
//       case 3:
//         return (
//           <>
//             <Login4
//               prevStep={prevStep}
//               nextStep={nextStep}
//               Login4Data={Login4Data}
//               errorMessage={errorMessage}
//               url={url}
//               name={name}
//             />
//             <Button
//               type="submit"
//               onClick={nextStep}
//               fullWidth
//               variant="contained"
//               sx={{
//                 marginTop: "16px",
//                 borderColor: "#DB4437",
//                 color: "#DB4437",
//                 padding: "12px 20px",
//                 borderRadius: "8px",
//                 backgroundColor: "#fff",
//                 boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
//                 transition: "transform 0.3s ease, box-shadow 0.3s ease",
//                 '&:hover': {
//                   transform: "translateY(-3px)",
//                   boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.2)",
//                   color: "#fff",
//                 },
//               }}
//               disabled={isLoading}
//             >
//               {isLoading ? <CircularProgress size={30} /> : "Continue"}
//             </Button>
//           </>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
// <div
//   style={{
//     width: "100vw",
//     height: "100vh",
//     backgroundImage: `url(${bgimage})`,
//     backgroundSize: "cover",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     fontFamily: "Poppins, sans-serif",
//     overflow: "hidden",
//   }}
// >
//   <Card
//     style={{
//       margin: "15px",
//       width: 400,
//       height: 520,
//       padding: 50,
//       boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//       fontFamily: "Poppins, sans-serif",
//       borderRadius: "20px",
//       position: "relative",
//     }}
//   >
//     <Grid>
//       <Box
//         sx={{
//           display: "flex",
//           alignContent: "center",
//           justifyContent: "center",
//           position: "absolute",
//           top: "3%",
//           right: isMobileOrTablet ? "55px" : "122px",
//         }}
//       >
//         <Typography
//           variant="h6"
//           component="div"
//           sx={{
//             color: "#297EFF",
//             marginRight: "5px",
//             marginBottom: "20px",
//           }}
//         ></Typography>
//       </Box>
//     </Grid>

//     <Stack direction={"column"} spacing={1} alignItems="center">
//       <img 
//         src={logoImage} 
//         alt="Logo" 
//         style={{ width: 100, height: 20 }} 
//       />
//     </Stack>

//     {successMessage && <Alert severity="success">{successMessage}</Alert>}

//     {renderStep()}
//   </Card>
// </div>


//   );
// }

// export default Login;

import React, { useEffect, useMemo, useState } from "react";
import Login1 from "./Login1";
import Login2 from "./Login2";
import Login4 from "./Login4";
import LoginStep from "./LoginStep";
import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { getSocialProfileApi, otpSend, Register } from "../../API/Auth";
import { useNavigate } from "react-router";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { addProfileRequest, addRegisterData,} from "../../Store/action";
import bgimage from "../../images/divine-pic-login.svg";
import logoImage from "../../images/FINDMYPIK.png";
import { useCallback } from "react";

import { uploadAndCheckFace } from "../../API/Photographer";
import { server } from "../../API/server";

function Login() {
  const [currentStep, setCurrentStep] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userData, setUserData] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [url, setUrl] = useState("");
  const [name, setName] = useState("");

  const [isOtpResending, setIsOtpResending] = useState(false); // For OTP resend
  const [resendTimer, setResendTimer] = useState(30); // Resend OTP timer
  const [canResend, setCanResend] = useState(false); // To control when OTP can be resent

  // const theme = useTheme();
  // const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("700"));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Timer for enabling resend OTP button
  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setCanResend(true); // Allow OTP resend after timer reaches 0
    }
  }, [resendTimer]);

  // Resend OTP function
  const handleResendOtp = async () => {
    try {
      setIsOtpResending(true);
      setCanResend(false);
      setResendTimer(30); // Reset the timer

      const data = { email: userEmail };
      const response = await otpSend(data);
      if (response.status === 201) {
        setErrorMessage("");
        setSuccessMessage("OTP has been resent successfully.");
      } else {
        setErrorMessage("Failed to resend OTP. Please try again.");
      }
    } catch (err) {
      setErrorMessage("Error occurred while resending OTP: " + err.message);
    } finally {
      setIsOtpResending(false);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        nextStep();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentStep]);

  let query = new URLSearchParams(window.location.search);

  const handleSocialProfile = useCallback(async (newValue) => {
    try {
      const response = await getSocialProfileApi(newValue);
      if (response.status === 200) {
        if (response?.data?.userType === null) {
          setCurrentStep(3);
        } else {
          localStorage.clear();
          localStorage.setItem("accessToken", response?.data?.accessToken);
          localStorage.setItem("userId", response?.data?.data?._id);
          localStorage.setItem("email", response?.data?.data?.email);
          localStorage.setItem("profileImage", response?.data.data?.imageUrl);
          const photoGrpherExist = response?.data?.IsExist;
          const datas = { email: response?.data?.data?.email, photoGrpherExist };
          dispatch(addProfileRequest(datas));
          setErrorMessage("Logged in Successfully");

          setTimeout(() => {
            setErrorMessage("");
            navigate("/home");
          }, 1000);
        }
      } else {
        setCurrentStep(3);
      }
    } catch (err) {
      setErrorMessage(err.message);
    }
  }, [dispatch, navigate]);

  useMemo(() => {
    if (query.get("success")) {
      setUserEmail(query.get("addr"));

      const fileUrl = query.get("url");
      setUrl(fileUrl);

      const name = query.get("call");
      setName(name);

      if (fileUrl) {
        fetch(fileUrl)
          .then((response) => response.blob())
          .then((blob) => {
            const file = new File([blob], "userprofile.jpeg", { type: blob.type });

            setUserData((prevData) => ({
              ...prevData,
              name: name,
              file: file,
            }));
          })
          .catch((err) => {
            console.error("Error fetching the file:", err);
          });
      } else {
        setUserData({
          name: name,
          phone: "",
          file: null,
        });
      }
      handleSocialProfile(query.get("addr"));
    }
  }, [handleSocialProfile, query]);

  const Login1Data = (data) => {
    setErrorMessage("");
    setUserEmail(data);
  };

  const Login2Data = (data) => {
    setErrorMessage("");
    setOtp(data);
  };

  const Login4Data = (data) => {
    setErrorMessage("");
    setSuccessMessage("");
    setUserData(data);
  };

  const EmailSubmit = async () => {
    const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!userEmail) {
      setErrorMessage("Email is required");
    } else if (!emailFormat.test(userEmail)) {
      setErrorMessage("Invalid Email format");
    } else {
      try {
        const data = { email: userEmail };
        const response = await otpSend(data);
        if (response.status === 201) {
          setErrorMessage("");
          setCurrentStep(currentStep + 1);
          setCanResend(false); // Disable resend initially
          setResendTimer(30); // Start resend timer
        }
      } catch (err) {
        setErrorMessage(err.message);
      }
    }
  };

  const otpSubmit = async () => {
    if (!otp || otp.length !== 6) {
      setErrorMessage("Please enter a valid 6-digit OTP.");
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  const submitProfile = async () => {
    setIsLoading(true);
    try {
      if (!userData?.file) {
        setErrorMessage("Please upload your face photo only.");
        setIsLoading(false);
        return;
      }

      const formData = new FormData();
      formData.append("files", userData?.file);
      const uploadResponse = await uploadAndCheckFace(formData);
      if (uploadResponse.data.result.status) {
        setErrorMessage("Multiple faces detected. Please upload a single face photo.");
        setIsLoading(false);
        return;
      }

      const userDetail = {
        name: userData.name,
        email: userEmail,
        phone: userData.phone,
        userType: "photographer",
      };

      const registrationFormData = new FormData();
      registrationFormData.append("userData", JSON.stringify(userDetail));
      registrationFormData.append("file", userData.file);

      const result = await Register(registrationFormData);

      if (result.status === 200) {
        const userType = result?.data?.userType;
        const UserImage = result?.data.data?.imageUrl;
        const UserId = result?.data.data?.AdditionalUsers[0];
        const datas = { data: result?.data, userType, UserImage, UserId };

        dispatch(addRegisterData(datas));
        localStorage.setItem("accessToken", result?.data?.accessToken);
        localStorage.setItem("userType", userType);
        localStorage.setItem("userId", result?.data.data?._id);
        localStorage.setItem("email", result?.data.data?.email);
        localStorage.setItem("profileImage", result?.data.data?.imageUrl);

        setSuccessMessage("Profile Completed Successfully");
        setTimeout(() => {
          setSuccessMessage("");
          navigate("/home");
        }, 2000);
      } else {
        setErrorMessage(result?.response?.data?.error || "Registration failed");
      }
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const nextStep = () => {
    if (currentStep === 0) {
      setCurrentStep(1);
    } else if (currentStep === 1) {
      EmailSubmit();
    } else if (currentStep === 2) {
      otpSubmit();
    } else {
      submitProfile();
    }
  };

  const prevStep = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 0));
    setErrorMessage("");
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <LoginStep
            onEmailClick={nextStep}
            onGoogleClick={() => window.open(`${server}/divine/Auth/google`, "_blank")}
          />
        );
      case 1:
        return (
          <>
            <Login1
              nextStep={nextStep}
              Login1Data={Login1Data}
              errorMessage={errorMessage}
            />
            <Button
              type="submit"
              onClick={nextStep}
              fullWidth
              variant="contained"
              sx={buttonStyle}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={30} /> : "Continue"}
            </Button>
          </>
        );
      case 2:
        return (
          <>
            <Login2
              moveNextStep={nextStep}
              prevStep={prevStep}
              Login2Data={Login2Data}
              errorMessage={errorMessage}
              userEmail={userEmail}
            />
            <Button
              type="submit"
              onClick={nextStep}
              fullWidth
              variant="contained"
              sx={buttonStyle}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={30} /> : "Continue"}
            </Button>

            <Button
              type="submit"
              onClick={handleResendOtp}
              fullWidth
              variant="contained"
              sx={buttonStyle}
              disabled={!canResend || isOtpResending}
            >
              {isOtpResending ? <CircularProgress size={30} /> : `Resend OTP (${resendTimer})`}
            </Button>
          </>
        );
      case 3:
        return (
          <>
            <Login4
              prevStep={prevStep}
              nextStep={nextStep}
              Login4Data={Login4Data}
              errorMessage={errorMessage}
              url={url}
              name={name}
            />
            <Button
              type="submit"
              onClick={nextStep}
              fullWidth
              variant="contained"
              sx={buttonStyle}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={30} /> : "Continue"}
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div style={pageStyle}>
      <Card style={cardStyle}>
        <Grid>
          <Box sx={{ display: "flex", alignContent: "center", justifyContent: "center" }}>
            <Typography
              variant="h6"
              component="div"
              sx={{ color: "#297EFF", marginRight: "5px", marginBottom: "20px" }}
            ></Typography>
          </Box>
        </Grid>

        <Stack direction={"column"} spacing={1} alignItems="center">
          <img src={logoImage} alt="Logo" style={{ width: 100, height: 20 }} />
        </Stack>

        {successMessage && <Alert severity="success">{successMessage}</Alert>}

        {renderStep()}
      </Card>
    </div>
  );
}

// Styles
const buttonStyle = {
  marginTop: "16px",
  borderColor: "#DB4437",
  color: "#DB4437",
  padding: "12px 20px",
  borderRadius: "8px",
  backgroundColor: "#fff",
  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    transform: "translateY(-3px)",
    boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.2)",
    color: "#fff",
  },
};

const pageStyle = {
  width: "100vw",
  height: "100vh",
  backgroundImage: `url(${bgimage})`,
  backgroundSize: "cover",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "Poppins, sans-serif",
  overflow: "hidden",
};

const cardStyle = {
  margin: "15px",
  width: 400,
  height: 520,
  padding: 50,
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  fontFamily: "Poppins, sans-serif",
  borderRadius: "20px",
  position: "relative",
};

export default Login;
