import React, { useEffect, useState, useCallback } from "react";
import {
  Typography,
  TableCell,
  CircularProgress,
  useTheme, useMediaQuery, Stack,
  Modal,
  Box,
  Button


} from "@mui/material";
import {  Group } from "@mui/icons-material";

import myImage from "../../../images/nodata.png"

import {
  TableCom,
  THead,
  TCell,
  TBody,
  Trow,
} from "../../Component/table"
import {  getAllEvenetApi } from "../../../API/Admin";
import Menus from "../../Component/menu";
import { SearchField } from "../../Component/SerachField";
import { useNavigate } from "react-router";
import { deleteMyEvent } from "../../../API/Photographer";

const AllAlbums = () => {
  const [photographerData, setPhotographerData] = useState([]);
  const [metaPage, setmetaPage] = useState(1);
  // const [profile,setProfile] = useState('');
  const [searchData,setSearchData] = useState('');
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('sm'));
  const [page, setPage] = useState(1);
  const [skeliton, setskeliton] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [photographerId, setphotographerId] = useState('');
  const navigate=useNavigate()

  const getPotgrapherData = useCallback(async () => {
    const data={
         page ,
         searchData
    }
    setskeliton(true)   
    console.log("Test1")
    try {
      const response= await getAllEvenetApi(data)
      setPhotographerData(response?.data?.data)
      setmetaPage(response?.data?.pagination?.totalPage)
     console.log("response",response)
       setskeliton(false)
       console.log("Test2")
    } catch (error) {
      console.log("error", error);
    }
  }, [page, searchData]);

  const useDebounce= (inputValue,delay)=>{

    const [debounceValue,setDebouncedValue]=useState(inputValue)

    useEffect(()=>{
     const storeValue=setTimeout(()=>{
        setDebouncedValue(inputValue)
     },delay)

     return()=>{
         clearTimeout(storeValue)
     }

    },[inputValue,delay])

    return debounceValue

  }
const finalSearchValue=useDebounce(searchData,1000)

  useEffect(() => {
    getPotgrapherData()
  }, [page, finalSearchValue, getPotgrapherData]);

  const goToPreviousPage = () => {
    setPage(page - 1);
  };

  const goToNextPage = async () => {
    
    try {
      const nextPage = page + 1;
      setPage(nextPage);
    } catch (error) {
      //console.log("error", error);
    }
  };

  
  const headers = [
    "Action",
    "Album Name",
    "Album Type",
    "Uploaded Images",
    "Created By",
    "Created At",
    
      
  ];

  
  const deletePhotographerApi = async() => {
    console.log("Test")
      try {
        const response =await deleteMyEvent(photographerId)
        console.log("response",response) 
        if(response?.status===200){
          handleCloseDelete()
          getPotgrapherData()
        }
    
        
      } catch (error) {
        console.log("error",error)
      }
      console.log("Test12112")

  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
 };

//  const handleOpenDelete = () => {
//     setOpenDelete(true);
//  };

  const deletePhotographer=(data)=>{
    console.log(data?._id)
    setphotographerId(data)
    setOpenDelete(true);
    // handleOpenDelete()

  }


  // const albumCreated=(data)=>{
  //   console.log("data",data)
  //   const id=data._id
  //   navigate("/photographerDetailes",{state:{id}})
    

  // }
  const menu = [
    // { name: "Employee List", icon: <Group/>, onClick: employeeList },
    // { name: 'Copy Referral Link', icon: <ContentCopyIcon />, onClick: handleCopylink },
    // { name: "Album created List", icon: <Group/>,  onClick:albumCreated},
    { name: 'Delete ', icon: <Group/>,onClick:deletePhotographer},
    
  
  ]



  const styled = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
    paddingBottom:5
 };

 const searchProps=(data)=>{
  console.log("data",data)
  setSearchData(data)
}

const createAlbum=()=>{
  navigate("/home")
  // publicGroup
}

  return (
    <div  style={{marginTop:10,marginInline: isMobileOrTablet ?20:5}}>
      
      <>
        {skeliton ? (
        //   <Backdrop
        //     sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        //     open={skeliton} // Use skeliton instead of open
        //     onClick={handleClose}
        //   >
        //     <CircularProgress color="inherit" />
        //   </Backdrop>

        <div style={{display:'flex',justifyContent:"center"}}>
            <CircularProgress color="inherit" />
        </div>
        ) : photographerData?.length === 0|| photographerData===undefined ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
               <img src={myImage} alt="No data available" />
          </div>
        ) : (
          <>
          <div style={{display:"flex",justifyContent:"space-between"}}>
          
            <Button variant="outlined" onClick={createAlbum}>
              <Typography style={{textTransform:"none"}}>Create Album</Typography>
            </Button>
            <Stack width={350} >
                <SearchField searchProps={searchProps} placeholder="Search Album "/>
            </Stack>

          </div>
          
            
              <div style={{marginTop:10}}>

        <Modal
          open={openDelete}
          onClose={handleCloseDelete}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styled}>
            <Typography>Are you sure you want to delete this Album?</Typography>
            <div style={{position:'absolute',right:20}}>
            <Button onClick={handleCloseDelete}>No</Button>
            <Button onClick={deletePhotographerApi}>Yes</Button>
            </div>
           
          </Box>
        </Modal>
            
              <TableCom>
                <THead>

                  {headers?.map((header, index) => (
                    <TableCell
                      key={index}
                      style={{
                        borderRight: "1px solid white",
                        paddingRight: "10px",
                        fontWeight: "40%",
                        fontSize: "100%",
                        color: "aliceblue",
                        backgroundColor: "#0C2142"
                      }}
                    >
                      {header}
                    </TableCell>

                  ))}
                </THead>

             
                <TBody>
                  {photographerData?.map((value, index) => (

                    <Trow key={index} >
                      
                        <TCell>

                           
                        <Menus
                          menu={menu}
                          userdata={value}
                          types={"skill"}
                          // setProfile={setProfile}
                        />
                        </TCell>



                      <TCell>
                       <Typography>{value.name ? value.name:"N/A"}</Typography>
          
                      </TCell>
                     
                      <TCell>
                      <Typography>{value.eventType? value.eventType:"N/A"}</Typography>
                      </TCell>

                      <TCell>
                      <Typography>{value.totalUploadImages? value.totalUploadImages:"N/A"}</Typography>
                      </TCell>
                      
                     
                      <TCell>
                      <Typography>{value.createdBy? value.createdBy:"N/A"}</Typography>
                      </TCell>

                     
                      <TCell>
                      <Typography>{ value.date? value.date.split('T')[0]:"N/A" }</Typography>
                      </TCell>
                      
                      
                    </Trow>
                  ))}
                </TBody>
              </TableCom>
              </div>

      
               <Stack
                direction="row"
                spacing={2}
                justifyContent={"flex-end"}
                style={{
                  marginTop: "10px",
                  marginBottom: "3px",
                  marginRight: "5px",
                }}
              >
                <Typography style={{ paddingTop: "5px", fontSize: "16px" }}>
                  Total Page : {metaPage}
                </Typography>
                <Button
                  style={{ width: "35px" , }}
                  onClick={goToPreviousPage}
                  disabled={page === 1}
                  variant="outlined"
                >
                  Prev..
                  </Button>
                <Typography style={{ paddingTop: "5px", fontSize: "16px" }}>
                  {page}
                </Typography>
                
                  <Button
                  style={{ width: "35px" , }}
                  onClick={goToNextPage}
                  disabled={page === metaPage}
                
                  variant="outlined"
                >
                  Next    
                  </Button>
              </Stack>
          </>
        )}
      </>

    </div>
  );
};

export default AllAlbums;