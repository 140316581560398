// import React, { useState, useEffect } from 'react';
// import { Alert, Avatar, Card, InputLabel, TextField, Typography } from '@mui/material';
// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import Modal from '@mui/material/Modal';
// import { Clear } from '@mui/icons-material';
// import { addUser, getaddedUser } from '../../../API/Auth';
// import { useNavigate } from 'react-router';

// const UsersMultipers = () => {
//     const [open, setOpen] = useState(false);
//     const [errorMessage, setErrorMessage] = useState({
//         message: "",
//         status: false
//     });
//     const navigate = useNavigate();
//     const [data, setData] = useState({
//         file: "",
//         name: "",
//         getUser: [],
//         userProfile: ""
//     });

//     const handleOpen = () => setOpen(true);
//     const handleClose = () => {
//         setOpen(false);
//         setErrorMessage({ message: "", status: false });
//     }

//     const style = {
//         position: 'absolute',
//         top: '50%',
//         left: '50%',
//         transform: 'translate(-50%, -50%)',
//         width: 400,
//         bgcolor: 'background.paper',
//         boxShadow: 24,
//         p: 4,
//     };

//     const handleFileChange = (event) => {
//         setErrorMessage({ message: "", status: false });
//         setData((prev) => ({ ...prev, file: event.target.files[0] }));
//     }

//     const handleSubmit = async () => {
//         console.log("data123", data);
//         if (!data.file) {
//             setErrorMessage({ message: "User image is required", status: false });
//         } else if (!data.name) {
//             setErrorMessage({ message: "User name is required", status: false });
//         } else {
//             try {
//                 const formData = new FormData();
//                 const id = localStorage.getItem("userId");
//                 const datas = {
//                     name: data.name,
//                     userID: id
//                 };
//                 if (data.file) {
//                     console.log("data", data.file);
//                     const files = data?.file;
//                     formData.append("file", files, files.name);
//                 }
//                 formData.append("userData", JSON.stringify(datas));
//                 const response = await addUser(formData);
//                 console.log("response", response);
//                 if (response?.status === 201) {
//                     getAllAddedUser();
//                     setErrorMessage({ message: response?.data?.Message, status: true });
//                     setTimeout(() => {
//                         setData((prev) => ({ ...prev, name: "", file: "" }));
//                         setErrorMessage("");
//                         handleClose();
//                     }, 2000);
//                 }
//             } catch (error) {
//                 console.log("error", error);
//             }
//         }
//     }

//     useEffect(() => {
//         getAllAddedUser();
//     }, []);

//     const getAllAddedUser = async () => {
//         try {
//             const response = await getaddedUser();
//             console.log("response", response);
//             setData((prev) => ({ ...prev, getUser: response?.data?.data?.AddtionalUsers }));
//             setData((prev) => ({ ...prev, userProfile: response?.data?.data[0] }));
//         } catch (error) {
//             console.log("response", error);
//         }
//     }

//     const handleNavigate = (image) => {
//         navigate("/", { state: { imageUrl: image } });
//     }

//     return (
//         <div>
//             <div>
//                 <div style={{ display: 'flex', justifyContent: "space-between", marginTop: "10px" }}>
//                     <Typography>Users</Typography>
//                     <Button onClick={handleOpen} variant='outlined' style={{ width: '20%' }}>Add User</Button>
//                 </div>
//                 <Card elevation={1} style={{ height: 65, backgroundColor: "#DCDCDC", marginTop: 5, padding: 3, width: "30%", cursor: "pointer" }} onClick={() => handleNavigate(data?.userProfile?.imageUrl)} >
//                     <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
//                         <div style={{ display: 'flex', flexDirection: 'column' }}>
//                             <Typography style={{ fontWeight: "bold" }}>{data?.userProfile?.name}</Typography>
//                             <Typography style={{ fontWeight: "bold" }}>Email</Typography>
//                             <Typography style={{ fontWeight: "bold" }}>Email</Typography>
//                         </div>
//                         <Avatar src={data?.userProfile?.imageUrl} style={{ width: 50, height: 50 }} />
//                     </div>
//                 </Card>
//                 {
//                     data?.getUser?.map((item, index) => (
//                         <Card key={index} elevation={1} style={{ height: 65, backgroundColor: "#DCDCDC", marginTop: 5, padding: 3, width: "30%", cursor: "pointer" }} onClick={() => handleNavigate(item.imageUrl)} >
//                             <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
//                                 <div style={{ display: 'flex', flexDirection: 'column' }}>
//                                     <Typography style={{ fontWeight: "bold" }}>name{item.name}</Typography>
//                                     {/* <Typography style={{ fontWeight: "bold" }}>Email</Typography> */}
//                                 </div>
//                                 <Avatar src={item.imageUrl} style={{ width: 50, height: 50 }} />
//                             </div>
//                         </Card>
//                     ))
//                 }
//             </div>

//             <div>
//                 <Modal
//                     open={open}
//                     aria-labelledby="modal-modal-title"
//                     aria-describedby="modal-modal-description"
//                 >
//                     <Box sx={style} >
//                         <Clear style={{ display: "flex", position: 'absolute', right: 5, top: 5 }} onClick={handleClose} />
//                         {
//                             errorMessage.message && <Alert severity={errorMessage.status ? "success" : "error"}>{errorMessage.message}</Alert>
//                         }
//                         <Typography id="modal-modal-title" variant="h6" component="h2">
//                             Create User
//                         </Typography>
//                         <InputLabel style={{ marginTop: "5px" }}>Select User Image</InputLabel>
//                         <TextField type='file' fullWidth style={{ marginTop: "5px" }} onChange={handleFileChange} />
//                         <InputLabel style={{ marginTop: "10px" }}>User Name</InputLabel>
//                         <TextField placeholder='Enter User Name' fullWidth style={{ marginTop: "5px" }} onChange={(e) => (setData((prev) => ({ ...prev, name: e.target.value })), setErrorMessage({ message: "", status: false }))} />
//                         <InputLabel style={{ marginTop: "10px" }}>Email</InputLabel>
//                         <TextField placeholder='Enter Email' fullWidth style={{ marginTop: "5px" }} onChange={(e) => (setData((prev) => ({ ...prev, email: e.target.value })), setErrorMessage({ message: "", status: false }))} />
//                         <Button fullWidth variant='contained' style={{ marginTop: "10px" }} onClick={handleSubmit}>Submit</Button>
//                     </Box>
//                 </Modal>
//             </div>
//         </div>
//     )
// }

// export default UsersMultipers;
import React, { useState, useEffect } from "react";
import {
    Alert,
    Avatar,
    // Card,
    // IconButton,
    InputLabel,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Clear } from "@mui/icons-material";
import { addUser, getaddedUser } from "../../../API/Auth";
import { useNavigate } from "react-router";
import { uploadAndCheckFace } from "../../../API/Photographer";
// import DeleteIcon from "@mui/icons-material/Delete";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const UsersMultipers = () => {
    const [open, setOpen] = useState(false);
    const [editData,] = useState(null);
    const [successMessage, setSuccessMessage] = useState({
        message: "",
        status: false,
    });

    const [errorMessage, setErrorMessage] = useState({
        message: "",
        status: false,
    });
    const navigate = useNavigate();
    const [data, setData] = useState({
        file: "",
        name: "",
        getUser: [],
        userProfile: "",
        email: "",
    });

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setErrorMessage({ message: "", status: false });
    };

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
    };

    const handleFileChange = (event) => {
        setErrorMessage({ message: "", status: false });
        setData((prev) => ({ ...prev, file: event.target.files[0] }));
    };

    const handleSubmit = async () => {
        console.log("data123", data);
        if (!data.file) {
            setErrorMessage({ message: "User image is required", status: false });
            return;
        } else if (!data.name) {
            setErrorMessage({ message: "User name is required", status: false });
            return;
        } else if (!emailRegex.test(data?.email)) {
            setErrorMessage({ message: "Invalid email format", status: false });
            return;
        } else {
            try {
                const id = localStorage.getItem("userId");
                const datas = {
                    name: data.name,
                    email: data.email,
                    userID: id,
                };

                // Create FormData for face check
                const faceCheckFormData = new FormData();
                faceCheckFormData.append("files", data.file);
                faceCheckFormData.append("userData", JSON.stringify(datas));

                // Perform face check
                const uploadResponse = await uploadAndCheckFace(faceCheckFormData);
                console.log("Upload response:", uploadResponse);
                if (uploadResponse.data.result === true) {
                    setErrorMessage({
                        message:
                            "Are you Gurudev Sri Sri Ravisankar? Please upload your face photo for accurate detection.",
                        status: false,
                    });
                    return;
                }

                // If face check passes, create new FormData for user addition
                const addUserFormData = new FormData();
                addUserFormData.append("file", data.file);
                addUserFormData.append("userData", JSON.stringify(datas));

                // Add user
                const response = await addUser(addUserFormData);
                console.log("Response from server:", response);

                if (response?.status === 201) {
                    getAllAddedUser();
                    setErrorMessage({ message: response?.data?.Message, status: true });
                    setTimeout(() => {
                        setData((prev) => ({ ...prev, name: "", file: "", email: "" }));
                        setErrorMessage({ message: "", status: false });
                        handleClose();
                    }, 2000);
                } else {
                    setErrorMessage({
                        message: "Email already exists. Please enter another email.",
                        status: false,
                    });
                }
            } catch (error) {
                console.error("Error in handleSubmit:", error);

                if (error.response) {
                    console.log("Error response:", error.response);
                    if (
                        error.response.status === 400 &&
                        error.response.data.error === "Email already exists"
                    ) {
                        setErrorMessage({
                            message: "Email already exists. Please try another email.",
                            status: false,
                        });
                    } else {
                        setErrorMessage({
                            message: `Server error: ${error.response.data.error || "Unknown error"
                                }`,
                            status: false,
                        });
                    }
                } else if (error.request) {
                    console.log("Error request:", error.request);
                    setErrorMessage({
                        message: "No response received from server. Please try again.",
                        status: false,
                    });
                } else {
                    console.log("Error message:", error.message);
                    setErrorMessage({
                        message: `Error: ${error.message}`,
                        status: false,
                    });
                }
            }
        }
    };

    useEffect(() => {
        getAllAddedUser();
    }, []);

    const getAllAddedUser = async () => {
        try {
            const response = await getaddedUser();
            console.log("response", response);
            const userData = response?.data?.data;
            setData((prev) => ({
                ...prev,
                getUser: userData?.AdditionalUsers,
                userProfile: userData?.AdditionalUsers[0],
            }));
        } catch (error) {
            console.log("response", error);
        }
    };

    const handleNavigate = (image) => {
        navigate("/", { state: { imageUrl: image } });
    };

    return (
        // <div>
        //     <div>
        //         <div style={{ display: 'flex', justifyContent: "space-between", marginTop: "90px" }}>
        //             <Typography>Users</Typography>
        //             <Button onClick={handleOpen} variant='outlined' style={{ width: '20%' }}>Add User</Button>
        //         </div>

        //         <Card elevation={1} style={{ height: 65, backgroundColor: "#DCDCDC", marginTop: 5, padding: 3, width: "30%", cursor: "pointer" }} onClick={() => handleNavigate(data?.userProfile?.imageUrl)} >
        //             <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
        //                 <div style={{ display: 'flex', flexDirection: 'column' }}>
        //                     <Typography style={{ fontWeight: "bold" }}>{data?.userProfile?.name}</Typography>
        //                     <Typography style={{ fontWeight: "bold" }}>{data?.userProfile?.email}</Typography>
        //                 </div>
        //                 <Avatar src={data?.userProfile?.imageUrl} style={{ width: 50, height: 50 }} />
        //             </div>
        //         </Card>
        //         {
        //             data?.getUser?.map((item, index) => (
        //                 <Card key={index} elevation={1} style={{ height: 65, backgroundColor: "#DCDCDC", marginTop: 5, padding: 3, width: "30%", cursor: "pointer" }} onClick={() => handleNavigate(item.imageUrl)} >
        //                     <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
        //                         <div style={{ display: 'flex', flexDirection: 'column' }}>
        //                             <Typography style={{ fontWeight: "bold" }}>Name:  {item.name}</Typography>
        //                             <Typography style={{ fontWeight: "bold" }}>Emfghail: {item.email}</Typography>
        //                         </div>
        //                         <Avatar src={item.imageUrl} style={{ width: 50, height: 50 }} />
        //                     </div>
        //                 </Card>
        //             ))
        //         }
        //     </div>

        //     <div>
        //         <Modal
        //             open={open}
        //             aria-labelledby="modal-modal-title"
        //             aria-describedby="modal-modal-description"
        //         >
        //             <Box sx={style} >
        //                 <Clear style={{ display: "flex", position: 'absolute', right: 5, top: 5 }} onClick={handleClose} />
        //                 {errorMessage.message && (
        //                     <Alert severity={errorMessage.status ? "success" : "error"} style={{ marginBottom: 10 }}>
        //                         {errorMessage.message}
        //                     </Alert>
        //                 )}
        //                 <Typography id="modal-modal-title" variant="h6" component="h2">
        //                     Create User
        //                 </Typography>
        //                 <InputLabel style={{ marginTop: "5px" }}>Select User Image</InputLabel>
        //                 <TextField type='file' fullWidth style={{ marginTop: "5px" }} onChange={handleFileChange} />
        //                 <InputLabel style={{ marginTop: "10px" }}>User Name</InputLabel>
        //                 <TextField placeholder='Enter User Name' fullWidth style={{ marginTop: "5px" }} onChange={(e) => (setData((prev) => ({ ...prev, name: e.target.value })), setErrorMessage({ message: "", status: false }))} />
        //                 <InputLabel style={{ marginTop: "10px" }}>Email</InputLabel>
        //                 <TextField placeholder='Enter Email' fullWidth style={{ marginTop: "5px" }} onChange={(e) => (setData((prev) => ({ ...prev, email: e.target.value })), setErrorMessage({ message: "", status: false }))} />
        //                 <Button fullWidth variant='contained' style={{ marginTop: "10px" }} onClick={handleSubmit}>Submit</Button>
        //             </Box>
        //         </Modal>
        //     </div>
        // </div>
        <Box
            marginTop={5}
            display="flex"
            height="calc(100vh - 95px)"
            bgcolor="#FAFDFF"
        >
            <Box flex={1} p={4} bgcolor="#fff" position="relative">
                <Box position="absolute" top={80} right={"60%"}>
                    <Button
                        style={{
                            backgroundColor: "#3974CB",
                            color: "#fff",
                            width: "98px",
                            height: "44px",
                            borderRadius: "5px",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '8px',
                            textTransform: 'none'
                        }}
                        onClick={() => handleOpen()}
                    >
                        <Typography fontSize={"16px"}>
                            Add User
                        </Typography>
                    </Button>
                </Box>

                {data?.getUser?.map((item, index) => (
                    <Box
                        key={index}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={4}
                    >
                        <Box display="flex" alignItems="center">
                            <Box
                                sx={{
                                    display: "flex",
                                    fontFamily: "Poppins, Arial, sans-serif",
                                    width: 100,
                                    height: 100,
                                    borderRadius: "50%",
                                    backgroundColor: "#EAF3FF",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    p: "px",
                                    m: 2,
                                    position: "relative",
                                    cursor: "pointer",
                                }}
                                onClick={() => handleNavigate(item)}
                            >
                                <Avatar src={item.imageUrl} sx={{ width: 80, height: 80 }} />
                            </Box>
                            <Stack direction={"row"} display={"flex"}>
                                <Box
                                    height={""}
                                    width={"auto"}
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                >
                                    <Box
                                        mr={2}
                                        style={{
                                            width: "390px",
                                            height: "auto",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => handleNavigate(item)}
                                    >
                                        <Stack
                                            style={{
                                                fontSize: "16px",
                                                color: "black",
                                                // backgroundColor: "#F3F8FF",
                                                display: "flex",
                                                fontFamily: "sans-serif",
                                                paddingLeft: "10px",
                                                borderRadius: "5px",
                                                width: "100%",
                                                height: "100%",
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontSize: "16px",
                                                    color: "black",
                                                    marginTop: "5px",
                                                }}
                                            >
                                                {item.name}
                                            </Typography>
                                            <Typography
                                                style={{
                                                    fontSize: "16px",
                                                    color: "black",
                                                    marginTop: "5px",
                                                    marginBottom: "5px",
                                                }}
                                            >
                                                {item.email}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                    <Stack direction={"row"} gap={2}>
                                        {/* <button style={{
                                        fontSize: "16px",
                                        color: "gray",
                                        background: "none",
                                        border: "none",
                                        cursor: "pointer",
                                        marginLeft: "10px"
                                    }} 
                                    onClick={() => editOpen(item)}>
                                        Edit
                                    </button> */}
                                        <button
                                            style={{
                                                fontSize: "16px",
                                                background: "none",
                                                border: "none",
                                                cursor: "pointer",
                                                padding: "10px",
                                                margin: "5px",
                                            }}
                                        >
                                            {/* <IconButton
                                                // onClick={() => deleteOpen(item)}
                                                style={{
                                                    color: "#d53232",
                                                    background: "none",
                                                    border: "none",
                                                    cursor: "pointer",
                                                    padding: "10px",
                                                    margin: "5px",
                                                }}
                                            >
                                                <DeleteIcon fontSize="large" color="primary" />
                                            </IconButton> */}
                                            <Modal
                                                // open={opens}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                            >
                                                <Box
                                                    sx={{ position: "relative", ...style }}
                                                    direction="row"
                                                >
                                                    {errorMessage.message && (
                                                        <Alert
                                                            severity={
                                                                errorMessage.status ? "success" : "error"
                                                            }
                                                            onClose={() =>
                                                                setErrorMessage({ message: "", status: false })
                                                            }
                                                            style={{ marginBottom: 10 }}
                                                        >
                                                            {errorMessage.message}
                                                        </Alert>
                                                    )}
                                                    {successMessage.message && (
                                                        <Alert
                                                            severity="success"
                                                            onClose={() =>
                                                                setSuccessMessage({
                                                                    message: "",
                                                                    status: false,
                                                                })
                                                            }
                                                            style={{ marginBottom: 10 }}
                                                        >
                                                            {successMessage.message}
                                                        </Alert>
                                                    )}
                                                    <Clear
                                                        style={{
                                                            display: "flex",
                                                            position: "absolute",
                                                            right: 5,
                                                            top: 5,
                                                        }}
                                                        onClick={handleClose}
                                                    />
                                                    <Stack direction={"column"}>
                                                        <Typography textAlign={"center"}>
                                                            Are you sure you want to delete this user?
                                                            {/* {    && <CircularProgress />} */}
                                                        </Typography>
                                                        <Stack
                                                            direction={"row"}
                                                            gap={2}
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            <Button
                                                                variant="contained"
                                                                style={{ marginTop: "10px" }}
                                                            // onClick={() => handleDeleteUser()}
                                                            >
                                                                Yes
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                style={{ marginTop: "10px" }}
                                                                onClick={handleClose}
                                                            >
                                                                No
                                                            </Button>
                                                        </Stack>
                                                    </Stack>
                                                </Box>
                                            </Modal>
                                        </button>
                                    </Stack>
                                </Box>
                            </Stack>
                        </Box>
                    </Box>
                ))}
            </Box>

            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropProps={{ onClick: handleClose }}
            >
                <Box sx={style}>
                    <Clear
                        style={{ display: "flex", position: "absolute", right: 5, top: 5 }}
                        onClick={handleClose}
                    />
                    {errorMessage.message && (
                        <Alert
                            severity={errorMessage.status ? "success" : "error"}
                            onClose={() => setErrorMessage({ message: "", status: false })}
                            style={{ marginBottom: 10 }}
                        >
                            {errorMessage.message}
                        </Alert>
                    )}
                    {successMessage.message && (
                        <Alert
                            severity="success"
                            onClose={() => setSuccessMessage({ message: "", status: false })}
                            style={{ marginBottom: 10 }}
                        >
                            {successMessage.message}
                        </Alert>
                    )}
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {editData ? "Edit User" : "Create User"}
                    </Typography>
                    <InputLabel style={{ marginTop: "5px" }}>
                        Select User Image
                    </InputLabel>
                    <TextField
                        type="file"
                        fullWidth
                        style={{ marginTop: "5px" }}
                        onChange={handleFileChange}
                    />
                    <InputLabel style={{ marginTop: "10px" }}>User Name</InputLabel>
                    <TextField
                        placeholder="Enter User Name"
                        fullWidth
                        style={{ marginTop: "5px" }}
                        value={data.name}
                        onChange={(e) =>
                            setData(
                                (prev) => ({ ...prev, name: e.target.value }),
                                setErrorMessage({ message: "", status: false })
                            )
                        }
                    />
                    <InputLabel style={{ marginTop: "10px" }}>Email</InputLabel>
                    <TextField
                        placeholder="Enter Email"
                        fullWidth
                        style={{ marginTop: "5px" }}
                        value={data.email}
                        onChange={(e) =>
                            setData(
                                (prev) => ({ ...prev, email: e.target.value }),
                                setErrorMessage({ message: "", status: false })
                            )
                        }
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        style={{ marginTop: "10px" }}
                        onClick={handleSubmit}
                    >
                        {editData ? "Update" : "Submit"}
                    </Button>
                </Box>
            </Modal>
        </Box>
    );
};

export default UsersMultipers;
