import {
  Alert,
  Avatar,
  CircularProgress,
  FormHelperText,
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Clear } from "@mui/icons-material";
import {
  addUser,
  getaddedUser,
  deleteUser,
  updateUserProfile,
} from "../../../API/Auth"; // Import updateUser and deleteUser API functions
// import { useNavigate } from "react-router";
import { uploadAndCheckFace } from "../../../API/Photographer";
import { useSelector } from "react-redux";
// import { updateUserImage } from "../../../Store/action";
import DeleteIcon from "@mui/icons-material/Delete";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export default function AddUser() {
  // const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [opens, setOpens] = useState(false);
  const [editData, setEditData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState({
    message: "",
    status: false,
  });

  const [errorMessage, setErrorMessage] = useState({
    message: "",
    status: false,
  });
  // const dispatch = useDispatch();
  const profileData = useSelector((state) => state.user.user);

  const mainUser = profileData?.UserId;
  console.log("Profiledata12xcamainUsermainUser", mainUser);
  const [data, setData] = useState({
    file: "",
    name: "",
    getUser: [],
    userProfile: "",
    email: "",
  });
  const [deleteUserId, setDeleteUserId] = useState("");
  console.log("profileDataprofileDatadata.getUser, profileDatadata.getUser, profileData", data.getUser, profileData);
  const deleteOpen = (item) => {
    //console.log("Item", item);
    setDeleteUserId(item._id);
    setOpens(true);
  };

 //console.log(deleteUserId, "deleteUserId");
  // const deleteClose = () => setOpens(false);
  const handleOpen = () => {
    setOpen(true);
  };
  // const editOpen = (item) => {
  //   if (item) {
  //     setData((prev) => ({
  //       ...prev,
  //       name: item.name,
  //       file: item.imageUrl,
  //       email: item.email,
  //     }));
  //     setEditData(item);
  //   } else {
  //     setEditData(null);
  //     setData({
  //       file: "",
  //       name: "",
  //       email: "",
  //     });
  //   }
  //   setOpen(true);
  // };

  // const handleAddUser = () => {
  //   setEditData(null);
  //   setData({
  //     file: "",
  //     name: "",
  //     email: "",
  //   });
  //   setOpen(true);
  // };

  const handleClose = () => {
    setEditData(null);
    setOpen(false);
    setOpens(false);
    setErrorMessage({ message: "", status: false });
  };

  const style = (isMobile, isTablet) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "75%" : isTablet ? "70%" : 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  });

  const handleFileChange = (event) => {
    setErrorMessage({ message: "", status: false });
    setData((prev) => ({ ...prev, file: event.target.files[0] }));
  };

  const handleDeleteUser = async () => {
    console.log("Test");
    setLoading(true);
    try {
      const response = await deleteUser(deleteUserId, profileData?.data?._id);
      //console.log("Response from deleteUser:", response);

      if (response.status === 200) {
        getAllAddedUser();
        setDeleteUserId("");
        setSuccessMessage({
          message: "User deleted successfully.",
          status: true,
        });
        setTimeout(() => {
          handleClose();
        }, 1000);
      }
    } catch (error) {
      console.error("Error in handleDeleteUser:", error);
      setErrorMessage({ message: `Error: ${error.message}`, status: false });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      // Validate inputs
      if (!data.file && !editData) {
        setErrorMessage({ message: "User image is required", status: false });
        return;
      } else if (!data.name) {
        setErrorMessage({ message: "User name is required", status: false });
        return;
      } else if (!emailRegex.test(data?.email)) {
        setErrorMessage({ message: "Invalid email format", status: false });
        return;
      } else if (data.getUser.length >= 5 && !editData) {
        setErrorMessage({
          message:
            "Limit reached! Max 5 users allowed. Please delete an existing user to add a new one.",
          status: false,
        });
        return;
      }

      // Validate file format
      const allowedTypes = ["image/jpeg", "image/png"];
      if (!allowedTypes.includes(data.file.type)) {
        setErrorMessage({
          message: "Invalid file format. Only JPEG and PNG are allowed.",
          status: false,
        });
        return;
      }

      //console.log("Validation passed");

      // Set loading state
      setLoading(true);

      // Prepare user data
      const id = localStorage.getItem("userId");
      const datas = {
        name: data.name,
        email: data.email,
        userID: id,
      };

      //console.log("User data:", datas);

      // Face check upload
      const faceCheckFormData = new FormData();
      faceCheckFormData.append("files", data.file);
      faceCheckFormData.append("userData", JSON.stringify(datas));

      const uploadResponse = await uploadAndCheckFace(faceCheckFormData);
      console.log("Face check response:", uploadResponse);
      if (uploadResponse.data.result.status === true) {
        setErrorMessage({ message: uploadResponse.data.result.message });
        setLoading(false);
        return;
      }

      // Final upload
      const formData = new FormData();
      formData.append("file", data.file);
      formData.append("userData", JSON.stringify(datas));

      console.log("Form data prepared");

      let response;
      if (editData) {
        response = await updateUserProfile(editData._id, formData);
      } else {
        response = await addUser(formData);
      }

      //console.log("Final upload response:", response);

      // Handle responses
      if (response?.status === 201 || response?.status === 200) {
        getAllAddedUser();
        setSuccessMessage({ message: "Updated Successfully", status: true });
        setTimeout(() => {
          setData((prev) => ({ ...prev, name: "", file: "", email: "" }));
          setSuccessMessage({ message: "Updated Successfully", status: false });
          handleClose();
          setLoading(false);
        }, 3000);
      } else if (response?.status === 409) {
        setErrorMessage({
          message: "Email already exists. Please enter another email.",
          status: false,
        });
        setLoading(false);
      } else {
        setErrorMessage({
          message: "Failed to add/update user. Please try again.",
          status: false,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle errors
      if (error.response) {
        setErrorMessage({
          message: `Server error: ${
            error.response.data.error || "Unknown error"
          }`,
          status: false,
        });
      } else if (error.request) {
        setErrorMessage({
          message: "No response received from server. Please try again.",
          status: false,
        });
      } else {
        setErrorMessage({ message: `Error: ${error.message}`, status: false });
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllAddedUser();
  }, []);

  const getAllAddedUser = async () => {
    try {
      const response = await getaddedUser();
      setData((prev) => ({
        ...prev,
        getUser: response?.data?.data?.AdditionalUsers,
      }));
      setData((prev) => ({ ...prev, userProfile: response?.data?.data[0] }));
    } catch (error) {
      console.log("response", error);
    }
  };

  // const handleNavigate = (image) => {
  //   const userImage = image?.imageUrl;
  //   const userId = image?._id;
  //   const data = {
  //     userImage,
  //     userId,
  // //   };
  //   dispatch(updateUserImage(data));
  //   navigate("/publicGroup", { state: { imageUrl: image } });
  // };

  useEffect(() => {
    let errorTimer, successTimer;

    if (errorMessage.message) {
      errorTimer = setTimeout(() => {
        setErrorMessage({ message: "", status: false });
      }, 2000); // 2000 milliseconds = 2 seconds
    }

    if (successMessage.message) {
      successTimer = setTimeout(() => {
        setSuccessMessage({ message: "", status: false });
      }, 2000); // 2000 milliseconds = 2 seconds
    }

    return () => {
      if (errorTimer) clearTimeout(errorTimer);
      if (successTimer) clearTimeout(successTimer);
    };
  }, [errorMessage, successMessage, setErrorMessage, setSuccessMessage]);

  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:900px)");

  return (
    <Box
      display="flex"
      height="calc(100vh-55px)"
      bgcolor="#FAFDFF"
      flexDirection={isMobile ? "column" : "row"}
    >
      <Box
        style={{ marginTop: "-40px" }}
        flex={1}
        bgcolor="#fff"
        position="relative"
      >
        <Stack spacing={2} alignItems="flex-start">
          <Stack direction="column" alignItems="flex-start">
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "600",
                color: "#3974CB",

                mt: 2,
              }}
            >
          Additional Searchable User
            </Typography>

            <Stack
              direction={isMobile ? "column" : "row"}
              alignItems="center"
              spacing={2}
              mt={1}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "16px",
                  textAlign: "left",
                }}
              >
                Add User, Our AI face detection technology makes it easy to find
                photos of yourself or others. Browse the albums to locate the
                event you attended, and let AI identify your images.
              </Typography>

              <Button
                style={{
                  backgroundColor: "#3974CB",
                  color: "#fff",
                  // width: "140px",
                  // height: "44px",
                  width: isMobile ? "140px" : isTablet ? "180px" : "180px",
                  height: isMobile ? "44px" : isTablet ? "54px" : "44px",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "8px",
                  textTransform: "none",
                  marginBottom: isMobile ? "10px" : "20px",
                }}
                onClick={handleOpen}
              >
                <Typography fontSize={"16px"}>Add User</Typography>
              </Button>
            </Stack>
          </Stack>
        </Stack>

        {data?.getUser?.map((item, index) =>
        item?._id !== mainUser && 
        (
          
          <Box
            key={index}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={4}
            flexDirection={isMobile ? "column" : "row"}
          >
            <Box
              display="flex"
              alignItems="center"
              flexDirection={isMobile ? "column" : "row"}
            >
              <Box
                sx={{
                  display: "flex",
                  fontFamily: "Poppins, Arial, sans-serif",
                  width: 100,
                  height: 100,
                  borderRadius: "50%",
                  backgroundColor: "#EAF3FF",
                  justifyContent: "center",
                  alignItems: "center",
                  p: "px",
                  m: 2,
                  position: "relative",
                  cursor: "pointer",
                }}
                // onClick={() => handleNavigate(item)}
              >
                <Avatar src={item.imageUrl} sx={{ width: 100, height: 100 }} />
              </Box>
              <Stack direction={isMobile ? "column" : "row"} display={"flex"}>
                <Box
                  height={""}
                  width={"auto"}
                  display="flex"
                  flexDirection={isMobile ? "column" : "row"}
                  alignItems="center"
                >
                  <Box
                    mr={2}
                    style={{
                      width: isMobile ? "100%" : "390px",
                      height: "auto",
                      cursor: "pointer",
                    }}
                    // onClick={() => handleNavigate(item)}
                  >
                    <Stack
                      style={{
                        fontSize: "16px",
                        color: "black",
                        display: "flex",
                        fontFamily: "sans-serif",
                        paddingLeft: "10px",
                        borderRadius: "5px",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "16px",
                          color: "black",
                          marginTop: "5px",
                        }}
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "16px",
                          color: "black",
                          marginTop: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        {item.email}
                      </Typography>
                    </Stack>
                  </Box>
                  <Stack direction={"row"} gap={2}>
                    <button
                      style={{
                        fontSize: "16px",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        padding: "10px",
                        margin: "5px",
                      }}
                    >
                      <IconButton
                        onClick={() => deleteOpen(item)}
                        style={{
                          color: "#d53232",
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                          padding: "10px",
                          margin: "5px",
                        }}
                      >
                        <DeleteIcon fontSize="large" color="primary" />
                      </IconButton>
                      <Modal
                        open={opens}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box
                          sx={{
                            position: "relative",
                            ...style(isMobile, isTablet),
                          }}
                          direction="row"
                        >
                          {errorMessage.message && (
                            <Alert
                              severity={
                                errorMessage.status ? "success" : "error"
                              }
                              onClose={() =>
                                setErrorMessage({ message: "", status: false })
                              }
                              style={{ marginBottom: 10 }}
                            >
                              {errorMessage.message}
                            </Alert>
                          )}
                          {successMessage.message && (
                            <Alert
                              severity="success"
                              onClose={() =>
                                setSuccessMessage({
                                  message: "",
                                  status: false,
                                })
                              }
                              style={{ marginBottom: 10 }}
                            >
                              {successMessage.message}
                            </Alert>
                          )}
                          <Clear
                            style={{
                              display: "flex",
                              position: "absolute",
                              right: 5,
                              top: 5,
                            }}
                            onClick={handleClose}
                          />
                          <Stack direction={"column"}>
                            <Typography textAlign={"center"}>
                              Are you sure you want to delete this user?
                            </Typography>
                            <Stack
                              direction={"row"}
                              gap={2}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                variant="contained"
                                style={{ marginTop: "10px" }}
                                onClick={() => handleDeleteUser()}
                              >
                                Yes
                              </Button>
                              <Button
                                variant="contained"
                                style={{ marginTop: "10px" }}
                                onClick={handleClose}
                              >
                                No
                              </Button>
                            </Stack>
                          </Stack>
                        </Box>
                      </Modal>
                    </button>
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Box>
          
        ))}
      </Box>

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{ onClick: handleClose }}
      >
        <Box sx={style(isMobile, isTablet)}>
          <Clear
            style={{
              display: "flex",
              position: "absolute",
              right: 20,
              top: 15,
            }}
            onClick={handleClose}
          />
          {errorMessage.message && (
            <Alert
              severity={errorMessage.status ? "success" : "error"}
              onClose={() => setErrorMessage({ message: "", status: false })}
              style={{ marginBottom: 10 }}
            >
              {errorMessage.message}
            </Alert>
          )}
          {successMessage.message && (
            <Alert
              severity="success"
              onClose={() => setSuccessMessage({ message: "", status: false })}
              style={{ marginBottom: 10 }}
            >
              {successMessage.message}
            </Alert>
          )}
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {editData ? "Edit User" : "Create User"}
          </Typography>
          <Box>
            <InputLabel style={{ marginTop: "5px" }}>
              Select User Image
            </InputLabel>
            <TextField
              type="file"
              fullWidth
              style={{ marginTop: "5px" }}
              onChange={handleFileChange}
            />
            <Box display="flex" justifyContent="space-between" mt={1}>
              <FormHelperText>
                Only formatted PNG and JPEG images
              </FormHelperText>
              <FormHelperText>Maximum size: 5MB</FormHelperText>
            </Box>
            <InputLabel style={{ marginTop: "10px" }}>User Name</InputLabel>
          </Box>

          <TextField
            placeholder="Enter User Name"
            fullWidth
            style={{ marginTop: "5px" }}
            value={data.name}
            onChange={(e) =>
              setData(
                (prev) => ({ ...prev, name: e.target.value }),
                setErrorMessage({ message: "", status: false })
              )
            }
          />
          <InputLabel style={{ marginTop: "10px" }}>Email</InputLabel>
          <TextField
            placeholder="Enter Email"
            fullWidth
            style={{ marginTop: "5px" }}
            value={data.email}
            onChange={(e) =>
              setData(
                (prev) => ({ ...prev, email: e.target.value }),
                setErrorMessage({ message: "", status: false })
              )
            }
          />

          <Button
            fullWidth
            variant="contained"
            style={{ marginTop: "10px" }}
            onClick={handleSubmit}
          >
            {loading ? (
              <CircularProgress size={24} style={{ color: "white" }} />
            ) : editData ? (
              "Update"
            ) : (
              "Submit"
            )}
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}
